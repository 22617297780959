import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../api';
import { Dict } from '../../api/models';
import { ApproveActionButton } from '../../components/actions/action-buttons';
import Checkbox from '../../components/actions/action-buttons/CheckBox';
import { Button } from '../../components/button';
import {
  CrudListFilterField,
  DateDataField,
  LinkField,
  TextDataField,
  ActionsDataField,
} from '../../components/data-fields';
import NumberDataField from '../../components/data-fields/NumberDataField';
import { DateInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import Modal from '../../components/modal';
import { TextView } from '../../components/view-fields';
import CrudListFilters from '../../layout/crud/components/CrudListFilters';
import CrudListSearch from '../../layout/crud/components/CrudListSearch';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { capitalizeFirstLetter } from '../../utils/text-utils';
import { metaData } from './metaData';

enum PayoutStatus {
  Requested = 'requested',
  Processed = 'processed',
  Rejected = 'rejected',
  Pending = 'pending',
}
const ListView: React.FunctionComponent = () => {
  const filters: React.ReactNode[] = [
    <CrudListFilterField field="status" label="Status">
      <SelectInput
        data={[
          {
            name: 'Requested',
            value: 'requested',
          },
          {
            name: 'Processed',
            value: 'processed',
          },
          {
            name: 'Rejected',
            value: 'rejected',
          },
          {
            name: 'Pending',
            value: 'pending',
          },
        ]}
        optionLabel="name"
        optionValue="value"
      />
    </CrudListFilterField>,

    <CrudListFilterField field="paymentMode" label="Payment Type">
      <SelectInput
        data={[
          {
            name: 'Bank',
            value: 'bank',
          },
          {
            name: 'Paypal',
            value: 'paypal',
          },
        ]}
        optionLabel="name"
        optionValue="value"
      />
    </CrudListFilterField>,

    <CrudListFilterField
      field="dateFrom"
      label="From Date"
      relatedFieldValueProps={{
        maxDate: (fields: Dict) => fields.dateTo || new Date(),
      }}
    >
      <DateInput></DateInput>
    </CrudListFilterField>,

    <CrudListFilterField
      field="dateTo"
      label="To Date"
      relatedFieldValueProps={{
        minDate: (fields: Dict) => fields.dateFrom,
        maxDate: (fields: Dict) => new Date(),
      }}
    >
      <DateInput></DateInput>
    </CrudListFilterField>,
  ];

  const queryParams = {
    timeZone: moment.tz.guess(),
  };
  const [checkedPayoutList, setCheckedPayoutList] = useState<any>([]);
  const [payoutStatus, setPayoutStatus] = useState<PayoutStatus>(
    PayoutStatus.Requested
  );
  const [allLoadedPayouts, setAllLoadedPayouts] = useState<any>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const addToApprovedList = (id: string, resource: string) => {
    if (checkedPayoutList?.includes(id)) {
      setCheckedPayoutList([
        ...checkedPayoutList.filter((existingId: string) => existingId != id),
      ]);
    } else {
      setCheckedPayoutList([...checkedPayoutList, id]);
    }
  };

  const addAllToApprovedList = () => {
    if (allChecked) {
      setCheckedPayoutList([]);
    } else {
      const payoutIds = allLoadedPayouts.map((payout: any) => {
        return payout?.requestedData.map(
          (reqPayout: { _id: any }) => reqPayout._id
        );
      });
      if (payoutIds.length) {
        setCheckedPayoutList(payoutIds[0]);
      }
    }
    setAllChecked(!allChecked);
  };

  const downloadPayouts = async (payoutsIdList?: []) => {
    const response = await api.crud.download(
      {
        resource: metaData?.resource,
      },
      payoutsIdList,
      'xlsx/download',
      'Payouts.xlsx'
    );
  };

  const { data, loading, loaded } = useDataLoader(() =>
    api.crud.get({
      resource: metaData?.resource,
      resourceId: 'payouts-header',
    })
  );

  const bulkApprovePayouts = async (payoutsIdList: []) => {
    let response;
    if (!checkedPayoutList.length) {
      response = await api.crud.get({
        resource: metaData?.resource,
        resourceId: 'approve-all-payouts',
      });
    } else {
      await downloadPayouts(payoutsIdList);
      response = await api.crud.postList(
        {
          resource: metaData?.resource,
        },
        payoutsIdList,
        'approve-multiple-payouts'
      );
    }
    if (response?.status === 201 || 200) {
      toastr.success('Success', 'Payouts Approved');
      window.location.reload();
    }
  };

  const renderCheckbox = () => {
    return (
      <Checkbox
        addToApprovedList={addAllToApprovedList}
        selectedValues={checkedPayoutList}
        allLoadedPayoutsLength={allLoadedPayouts.length}
        allCheckBox={allChecked}
        payoutsTab={payoutStatus}
      ></Checkbox>
    );
  };

  const [modalOpen, setModalOpen] = useState(false);

  const renderModal = () => {
    return (
      <Modal width="500" isOpen={modalOpen}>
        <div className="container">
          <div className="row">
            <div className="col">
              <p
                className="h6"
                style={{
                  textAlign: 'center',
                  padding: '30px 0px',
                }}
              >
                Are you sure you want to approve all the selected payouts ?
              </p>
            </div>
          </div>
          <div className="row gutter-8 justify-content-center">
            <div className="col-auto">
              <Button
                size="sm"
                onClick={async () => {
                  await bulkApprovePayouts(checkedPayoutList);
                  setModalOpen(false);
                }}
                // disabled={update}
              >
                Approve
              </Button>
            </div>
            <div className="col-auto">
              <Button
                size="sm"
                className="Button__outline-primary"
                onClick={() => setModalOpen(false)}
                // disabled={update}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const getAllPayoutIds = (ids: string[]) => {
    setAllLoadedPayouts(ids);
  };

  const payoutUtilitySection = () => {
    return (
      <div className=" mb-4">
        <div className="row gutter-8">
          <div className="col-sm-3 col-6">
            <div
              className="p-3 rounded"
              style={{ backgroundColor: 'rgba(225, 0, 0, 0.15)' }}
            >
              <p className="mb-1 font-small text-gray-800">Total Requested</p>
              {!loading && loaded && (
                <h4 className="mb-0 text-primary">
                  $ {data.data.totalAmountForRequestedPayouts.toFixed(2)}
                </h4>
              )}
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div
              className="p-3 rounded"
              style={{ backgroundColor: 'rgba(225, 0, 0, 0.15)' }}
            >
              <p className="mb-1 font-small text-gray-800">Total Approved</p>
              {!loading && loaded && (
                <h4 className="mb-0 text-primary">
                  $ {data.data.totalAmoutForProcessedPayouts.toFixed(2)}
                </h4>
              )}
            </div>
          </div>
          <div className="col-auto ml-auto mt-sm-0 mt-3">
            {!!checkedPayoutList.length && (
              <button
                // onClick={() => bulkApprovePayouts(checkedPayoutList)}
                onClick={() => setModalOpen(true)}
                className="btn btn-primary mr-3"
              >
                {'Approve Selected'}
              </button>
            )}

            <button
              className="btn btn-primary export-btn"
              onClick={() => downloadPayouts()}
            >
              <i className="icon-download mr-2"></i>
              Export
            </button>
          </div>
        </div>
        <nav className="tabs-header myaccount d-lg-flex justify-content-lg-between align-items-center border-bottom mt-3 mt-lg-4">
          <div
            className="nav nav-tabs border-bottom"
            id="nav-tab"
            role="tablist"
          >
            <a
              className={
                payoutStatus === PayoutStatus.Requested
                  ? 'nav-item nav-link active cursor-pointer font-large'
                  : 'nav-item nav-link cursor-pointer font-large'
              }
              id="nav-home-tab"
              data-toggle="tab"
              onClick={() => setPayoutStatus(PayoutStatus.Requested)}
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Requested{' '}
            </a>
            <a
              className={
                payoutStatus === PayoutStatus.Processed
                  ? 'nav-item nav-link active cursor-pointer font-large'
                  : 'nav-item nav-link cursor-pointer font-large'
              }
              id="nav-profile-tab"
              data-toggle="tab"
              onClick={() => setPayoutStatus(PayoutStatus.Processed)}
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Processed{' '}
            </a>
          </div>
        </nav>
      </div>
    );
  };

  return (
    <PlainLayout>
      {renderModal()}
      <CrudListView
        metaData={metaData}
        actions={[
          <CrudListSearch />,
          <CrudListFilters queryFilters>{filters}</CrudListFilters>,
        ]}
        queryParams={queryParams}
        utility={payoutUtilitySection()}
        getAllPayoutIds={getAllPayoutIds}
        payoutFilter={
          payoutStatus === PayoutStatus.Processed
            ? PayoutStatus.Processed
            : PayoutStatus.Requested
        }
      >
        <Checkbox
          addToApprovedList={addToApprovedList}
          selectedValues={checkedPayoutList}
          checkbox={renderCheckbox()}
          allCheckBox={false}
          payoutsTab={payoutStatus}
        ></Checkbox>
        <LinkField source="payoutNumber" label="Invoice">
          <TextView />
        </LinkField>
        <TextDataField
          source="totalPayoutAmount"
          label="Amount ($)"
        ></TextDataField>
        <NumberDataField
          source="totalCommission"
          label="Commission ($)"
        ></NumberDataField>
        <TextDataField source="requestedUser.name" label="User"></TextDataField>
        <TextDataField
          source="status"
          label="Status"
          formatContent={(val: string) => capitalizeFirstLetter(val)}
        ></TextDataField>

        <TextDataField
          source="payoutPaymentDetails.paymentMode"
          label="Payment Type"
          formatContent={(val: string) => capitalizeFirstLetter(val)}
        ></TextDataField>

        <DateDataField
          source="createdAt"
          label="Created At"
          dateFormat="DD-MMM-YYYY hh:mm A"
        />
        <ActionsDataField actionsColumn>
          <ApproveActionButton
            show={(data) => {
              if (data?.record?.status === PayoutStatus.Requested) {
                return true;
              } else {
                return false;
              }
            }}
          ></ApproveActionButton>
        </ActionsDataField>
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
