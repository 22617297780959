import React from 'react';
import { Link } from 'react-router-dom';

interface DropDownItemProps {
  link?: string;
  onClick?: () => void;
  hidden?: boolean;
}

const DropDownItem: React.SFC<DropDownItemProps> = (props) => {
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (props.onClick) {
      props.onClick();
    }
    return true;
  };
  const style: React.CSSProperties | undefined = props.hidden
    ? { display: 'none' }
    : undefined;
  if (props.link) {
    return (
      <li className="dropdown__menu-item" style={style}>
        <Link className="dropdown__item" to={props.link}>
          {props.children}
        </Link>
      </li>
    );
  }
  return (
    <li className="dropdown__menu-item" style={style}>
      <button className="dropdown__item" type="button" onClick={onClick}>
        {props.children}
      </button>
    </li>
  );
};

export default DropDownItem;
