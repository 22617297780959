import React from 'react';

import { AvatarView } from '../avatar';
import Popup from '../popup';

import './Header.scss';
import { AuthUser } from '../../api/models';
import LogoutButton from '../logout-button';
import ChangePasswordButton from '../change-password';

interface HeaderUserProps {
  user: AuthUser;
}

const HeaderUser: React.SFC<HeaderUserProps> = ({ user }) => {
  const avatar = user.avatar ? user.avatar : undefined;
  return (
    <div className="Header__option-item Header__user-block">
      <button id="user" className="Header__user">
        <span className="Header__user-image">
          <AvatarView src={avatar} name="user" />
        </span>
        <span className="Header__user-name">{user.name}</span>
        <i className="dropdown__arrow icon-chevron-down" />
      </button>

      <Popup target="user" cuePos="pos1" right="12">
        <li className="Header__user-item">
          <LogoutButton className="Header__user-link" noIcon />
        </li>
        <li className="Header__user-item">
          <ChangePasswordButton className="Header__user-link" noIcon />
        </li>
      </Popup>
    </div>
  );
};

export default HeaderUser;
