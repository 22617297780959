import React, { useState, useContext } from 'react';
import { DataRecordContext, MetaDataContext } from '../../contexts';
import { resolveFieldValue } from '../../components/utils';
import { DataTableFieldProps } from '../../components/data-table';

const StatusView: React.SFC<DataTableFieldProps> = (props) => {
  const metaData = useContext(MetaDataContext);
  const recordDetails = useContext(DataRecordContext);
  const value = resolveFieldValue(recordDetails, props, metaData);

  return (
    <>
      <span
        className={`status ${
          value === 'custom-plan-generated' ? 'completed' : 'pending'
        }`}
      >
        {value === 'custom-plan-generated' ? 'Generated' : 'Pending'}
      </span>
    </>
  );
};

export default StatusView;
