import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Logo.scss';
import logoImage from './logo-red.svg';
import logoImageWhite from './logo-white.svg';

interface LogoProps {
  size?: 'small' | 'large';
  white?: boolean;
}

const Logo: React.SFC<LogoProps> = (props) => {
  return (
    <Link to="/" className="Logo__link">
      <img
        className={`Logo Logo--${props.size}`}
        src={props.white ? logoImageWhite : logoImage}
        alt="ISPG"
        title="ISPG"
      />
    </Link>
  );
};

Logo.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
};

Logo.defaultProps = {
  size: 'small',
};

export default Logo;
