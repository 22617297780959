import React from 'react';
import { roundToTwoDecimalPoints } from '../../utils/number-utils';

interface NumberViewProps {
  value?: number;
  bold?: boolean;
  formatNumber?: (value: number) => string;
  nullValue?: string;
  round?: boolean;
  roundToDecimal?: number;
}

const NumberView: React.SFC<NumberViewProps> = (props) => {
  const formatText = (value?: number): string => {
    if (!value) {
      return props.nullValue || '0';
    }

    let v = value;

    if (props.round) {
      v = Math.round(v);
    } else if (props.roundToDecimal && typeof value === 'number') {
      v = roundToTwoDecimalPoints(v);
    }
    if (props.formatNumber) {
      return props.formatNumber(value);
    }
    return v.toString();
  };

  const textValue = formatText(props.value);
  const classes = ['text-right'];
  if (props.bold) {
    classes.push('DataTable__item--bold');
  }
  return <span className={classes.join(' ')}>{textValue}</span>;
};

export default NumberView;
