import React, { useState, useContext } from 'react';
import { Button } from '../../button';
import api from '../../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import Modal from '../../modal';
import { MetaDataContext, DataRecordContext } from '../../../contexts';
import { DataTableFieldProps } from '../../data-table';
import { resolveFieldValue } from '../../utils';

const StatusActionButton: React.SFC<DataTableFieldProps> = (props) => {
  const metaData = useContext(MetaDataContext);
  const recordDetails = useContext(DataRecordContext);
  const value = resolveFieldValue(recordDetails, props, metaData);

  const [modalOpen, setModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const history = useHistory();

  const changeStatus = async () => {
    if (update) {
      return;
    }
    setUpdate(true);
    const data = { status: value ? 'inactive' : 'active' };
    const type = 'modify-status';
    try {
      if (metaData?.resource && recordDetails?.recordId) {
        await api.crud.update(
          {
            resource: metaData?.resource,
            resourceId: recordDetails?.recordId,
          },
          data,
          type
        );
      }
      setUpdate(false);
      window.location.reload();
    } catch (e) {
      toastr.error('Error', 'Unable to update the status');
      setUpdate(false);
    }
  };

  const renderModal = () => {
    return (
      <Modal width="500" isOpen={modalOpen}>
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="h6"
                style={{
                  textAlign: 'center',
                  padding: '30px 0px',
                }}
              >
                Are you sure you want to update the status ?
              </p>
            </div>
          </div>
          <div className="row gutter-8 justify-content-center">
            <div className="col-auto">
                <Button
                  size="sm"
                  onClick={() => {
                    changeStatus();
                  }}
                  disabled={update}
                >
                  Update
                </Button>
              </div>
            <div className="col-auto">
              <Button
                size="sm"
                className="Button__outline-primary"
                onClick={() => setModalOpen(false)}
                disabled={update}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  return (
    <>
      {renderModal && renderModal()}
      <span
        onClick={() => setModalOpen(true)}
        
        className={`status ${
          value ? 'active' : 'inactive'
        }`}
      >
        {value ? 'Active' : 'Inactive'}
      </span>
    </>
  );
};

export default StatusActionButton;
