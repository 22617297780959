import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { TextInput, PasswordInput } from '../../components/form-group';
import AuthCanvas from './AuthCanvas';
import { useDispatch, useSelector } from 'react-redux';
import { actions as authUserActions } from '../../store/reducers/authUser';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../routes/route-keys';
import ClickLink from '../../components/click-link';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../components/button';
import { State } from '../../store/interfaces';

interface LoginFormProps {
  email: string;
  password: string;
}

const Login: React.SFC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = useSelector((store: State) => store.authUser);

  const { handleSubmit, errors, control } = useForm<LoginFormProps>({
    shouldFocusError: true,
  });

  const login = (data: { email: string; password: string }) => {
    setErrorMessage('');
    return dispatch(
      authUserActions.login(
        data,
        () => {
          toastr.success('Success', 'Logged In');
          history.push(RouteKeys.Dashboard);
        },
        (message) => {
          setErrorMessage(message || '');
        }
      )
    );
  };
  return (
    <AuthCanvas head="Login to your account">
      <form onSubmit={handleSubmit(login)}>
        <Controller
          control={control}
          name="email"
          rules={{ required: 'Email is Required' }}
          defaultValue={email}
          render={({ onChange, value, name }) => (
            <TextInput
              placeholder="Email"
              error={errors.email?.message}
              onChange={onChange}
              name={name}
              default={value}
            />
          )}
        ></Controller>

        <Controller
          control={control}
          name="password"
          rules={{ required: 'Password is Required' }}
          render={({ onChange, value, name }) => (
            <PasswordInput
              gap="sm"
              placeholder="Password"
              error={errors.password?.message}
              onChange={onChange}
              name={name}
              default={value}
            />
          )}
        ></Controller>
        <div className="mb-2 mt-2">
          {errorMessage && (
            <span className="message_validation">{errorMessage}</span>
          )}
        </div>

        <div className="mb-3 text-right">
          <ClickLink link="/auth/forgot-password" className="link__forgot">
            Forgot Password?
          </ClickLink>
        </div>
        <div className="button-outer text-right">
          <Button type="submit">Login</Button>
        </div>
      </form>
    </AuthCanvas>
  );
};

export default Login;
