import React from 'react';
import Checkbox from '../form-group/checkbox';
import { ApiPermissions } from '../../api/models';

interface PermissionCheckboxProps {
  onSelection?: (permission: string, selected: boolean) => void;
  permission: ApiPermissions;
  disabled?: boolean;
}

const PermissionCheckbox: React.SFC<PermissionCheckboxProps> = (props) => {
  const { onSelection, permission } = props;
  if (!permission) {
    return <></>;
  }
  return (
    <Checkbox
      gap="sm"
      id={permission.permission}
      selected={permission.selected}
      label={permission.name}
      labelClassName="font-medium"
      onChange={(val) => {
        if (onSelection) {
          onSelection(permission.permission, val);
        }
      }}
      disabled={props.disabled}
    />
  );
};

export default PermissionCheckbox;
