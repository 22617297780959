import React, { useState } from 'react';
import { classNames } from 'react-select/src/utils';
import { MyProductType } from '../../DetailsView';
import classnames from 'classnames';

export interface Count {
  myAdsCount?: number;
  purchaseCount?: number;
  subscriptionCount?: number;
  productTypeChange: (value: MyProductType) => void;
}

const TabView: React.FunctionComponent<Count> = (props) => {
  const [productType, setProductType] = useState<MyProductType>(
    MyProductType.MyAds
  );
  const onTabClick = (type: MyProductType) => {
    setProductType(type);
    props.productTypeChange(type);
  };
  const getClassName = (tab: MyProductType) => {
    const className = classnames({
      'nav-item nav-link cursor-pointer': true,
      active: productType === tab,
    });
    return className;
  };
  return (
    <div className="row mx-lg-0">
      <nav className="tabs-header font-weight-bold  px-lg-0 px-3 w-100 myaccount">
        <div
          className="nav nav-tabs text-uppercase border-bottom-0 overflow-x-auto overflow-y-hidden text-nowrap flex-nowrap"
          id="nav-tab"
          role="tablist"
        >
          <a
            className={getClassName(MyProductType.MyAds)}
            onClick={() => onTabClick(MyProductType.MyAds)}
          >
            Ads ({props.myAdsCount})
          </a>
          <a
            className={getClassName(MyProductType.Purchase)}
            onClick={() => onTabClick(MyProductType.Purchase)}
          >
            Purchase ({props.purchaseCount})
          </a>
          <a
            className={getClassName(MyProductType.Subscripton)}
            onClick={() => onTabClick(MyProductType.Subscripton)}
          >
            Subscription ({props.subscriptionCount})
          </a>
          <a
            className={getClassName(MyProductType.Payments)}
            onClick={() => onTabClick(MyProductType.Payments)}
          >
            Payments
          </a>
          {/* <a className="nav-item nav-link cursor-pointer">Abuse Reports (3)</a> */}
          <a
            className={getClassName(MyProductType.Profile)}
            onClick={() => onTabClick(MyProductType.Profile)}
          >
            Profile
          </a>
        </div>
      </nav>
    </div>
  );
};
export default TabView;
