import React from 'react';
import { DataTableFieldProps } from '../data-table';
import { capitalizeFirstLetter } from '../../utils/text-utils';
import TextDataField from './TextDataField';

interface ArrayDataFieldProps extends DataTableFieldProps<string> {
  bold?: boolean;
  nullValue?: string;
  property?: string;
  separationChar?: string;
}

const ArrayDataField: React.SFC<ArrayDataFieldProps> = (props) => {
  const { property, separationChar = ' | ' } = props;
  const formatContent = (value: any) => {
    if (!value) {
      return value;
    }

    if (Array.isArray(value)) {

      if (property) {

        value = value
          .filter((item) => item && item[property])
          .map((a: any) => a[property]);
      } else {
        value = value.map((a: any) => a.toString());
      }
      return value.map(capitalizeFirstLetter).join(separationChar);
    }
  };

  return (
    <TextDataField {...props} formatContent={formatContent}></TextDataField>
  );
};

export default ArrayDataField;
