import React from 'react';
import { CreateActionButton } from '../../components/actions/action-buttons';
import { LinkField, TextDataField } from '../../components/data-fields';
import ImageDataField from '../../components/data-fields/ImageDataField';
import { TextView } from '../../components/view-fields';
import CrudListSearch from '../../layout/crud/components/CrudListSearch';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { metaData } from './metaData';

const ListView: React.FunctionComponent = () => {
  return (
    <PlainLayout>
      <CrudListView
        metaData={metaData}
        actions={[<CrudListSearch />, <CreateActionButton />]}
      >
        <LinkField source="title" label="Title">
          <TextView />
        </LinkField>
        <ImageDataField source="image" label="Image"></ImageDataField>
        <TextDataField
          source="highlighted"
          label="Highlight"
          formatContent={(val: boolean) => (val ? 'Yes' : '-')}
          nullValue="-"
        ></TextDataField>
        <TextDataField source="description" label="Description"></TextDataField>
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
