import { Dispatch } from 'redux';

export const ACTIONS_SIDE_MENU_TOGGLE = 'UI/SIDE_MENU_TOGGLE';

export interface SideMenuToggleAction {
  type: typeof ACTIONS_SIDE_MENU_TOGGLE;
  data: boolean;
}

type Actions = SideMenuToggleAction;

export interface UIDataState {
  sideMenuToggleStatus?: boolean;
}

export default (state: UIDataState = {}, action: Actions): UIDataState => {
  switch (action.type) {
    case ACTIONS_SIDE_MENU_TOGGLE:
      if (action.data) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }

      return { ...state, sideMenuToggleStatus: action.data };

    default:
      return state;
  }
};

export const actions = {
  toggleSideMenu: (toggle: boolean) => async (dispatch: Dispatch<Actions>) => {
    await dispatch({ type: ACTIONS_SIDE_MENU_TOGGLE, data: toggle });
  },
};
