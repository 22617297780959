import React from 'react';
import PermissionSelectionView from './PermissionSelectionView';
import useEntities from '../../hooks/useEntities';

interface PermissionSelectionProps {
  onChange?: (permission: string[]) => void;
  selected: string[];
  heading?: React.ReactNode;
  disabled?: boolean;
  onChangePermission?: (permission: string[]) => void;
}

const PermissionSelection: React.SFC<PermissionSelectionProps> = (props) => {
  const { onChange, heading, selected } = props;

  const entities = useEntities();

  const onPermissionSelection = (
    permission: string | string[],
    isSelected: boolean
  ) => {
    let selectedItems = selected || [];
    if (Array.isArray(permission)) {
      // tslint:disable-next-line: prefer-conditional-expression
      if (isSelected) {
        selectedItems = selectedItems.concat(permission);
      } else {
        selectedItems = selectedItems.filter((p) => !permission.includes(p));
      }
    } else if (isSelected) {
      selectedItems.push(permission);
    } else {
      selectedItems = selectedItems.filter((p) => p !== permission);
    }
    if (onChange) {
      onChange(selectedItems);
    }
  };

  return (
    <PermissionSelectionView
      selectedPermissions={selected}
      permissions={entities.apiPermissions}
      onPermissionSelection={onPermissionSelection}
      heading={heading}
      disabled={props.disabled}
    />
  );
};

export default PermissionSelection;
