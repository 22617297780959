import React, { useEffect } from 'react';
import { Attribute } from '../../api/models/attribute';
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import AttributeInputController from '../../components/attributes/AttributeInputController';
import { Button } from '../../components/button';
import { SelectPhoneInput, TextInput } from '../../components/form-group';
import TextArea from '../../components/form-group/TextArea';
import SelectInput from '../../components/form-group/SelectInput';
import validator from 'validator';
import { MediaFile } from '../../api/models';
import SwitchInput from '../../components/form-group/switch-input';
import FileUploadController from '../../components/banner-upload/FileUploadController';

enum BannerType {
  Top = 'top',
  Bottom = 'bottom',
}

interface CreateBannerFormProps {
  image: MediaFile;
  bannerType: BannerType;
  isActive?: boolean;
  title?: string;
  subTitle?: string;
  description?: string;
}

const CreateBannerForm: React.FunctionComponent<{
  onSubmit: (data: CreateBannerFormProps) => void;
  default?: CreateBannerFormProps;
  errorMessage?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const { handleSubmit, errors, control, watch, setValue } = useForm<
    CreateBannerFormProps
  >({
    shouldFocusError: true,
    shouldUnregister: false,
    defaultValues: props.default,
  });

  useEffect(() => {
    setValue('bannerType', props.default?.bannerType);
    // setValue('isActive', props.default?.isActive);
    setValue('isActive', true);
    setValue('title', props.default?.title);
    setValue('subTitle', props.default?.subTitle);
    setValue('image', props.default?.image);
    setValue('description', props.default?.description);
  }, [props.default, setValue]);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <div className="row">
        <div className="col-xl-5 col-sm-6 col-12">
          <FileUploadController
            value={props.default?.image}
            control={control}
            name="image"
            setValue={setValue}
            showIcon={true}
            error={(errors.image as FieldError)?.message}
          ></FileUploadController>
        </div>
        {/* <div className="col-xl-5 col-sm-6 col-12">
          <div className="textField">
            <label className="d-block font-xsmall">&nbsp;</label>
            <Controller
              control={control}
              name="isActive"
              defaultValue={props.default?.isActive}
              render={({ onChange, value, name }) => (
                <SwitchInput
                  label="Is Active"
                  placeholder="Is Active"
                  error={errors.isActive?.message}
                  onChange={onChange}
                  name={name}
                  default={value}
                />
              )}
            ></Controller>
          </div>
        </div> */}

        <div className="col-xl-5 col-sm-6 col-12">
          <div className="textField">
            <Controller
              control={control}
              name="bannerType"
              defaultValue={props.default?.bannerType}
              render={({ onChange, value, name }) => (
                <SelectInput
                  label="Banner Type"
                  placeholder=""
                  data={[
                    {
                      name: 'Top',
                      value: 'top',
                    },
                    {
                      name: 'Bottom',
                      value: 'bottom',
                    },
                  ]}
                  error={errors.bannerType?.message}
                  onChange={onChange}
                  name={name}
                  optionLabel="name"
                  optionValue="value"
                  default={value}
                />
              )}
            ></Controller>
          </div>
          <div className="mb-2 mt-2">
            {props.errorMessage && (
              <span className="message_validation">{props.errorMessage}</span>
            )}
          </div>
        </div>
        <div className="col-xl-5 col-sm-6 col-12">
          <div className="textField">
            <Controller
              control={control}
              name="title"
              defaultValue={props.default?.title}
              render={({ onChange, value, name }) => (
                <TextInput
                  label="Title"
                  placeholder=""
                  error={errors.title?.message}
                  onChange={onChange}
                  name={name}
                  default={value}
                />
              )}
            ></Controller>
          </div>
        </div>
        <div className="col-xl-5 col-sm-6 col-12">
          <div className="textField">
            <Controller
              control={control}
              name="subTitle"
              defaultValue={props.default?.subTitle}
              render={({ onChange, value, name }) => (
                <TextInput
                  label="Sub Title"
                  placeholder=""
                  error={errors.subTitle?.message}
                  onChange={onChange}
                  name={name}
                  default={value}
                />
              )}
            ></Controller>
          </div>
        </div>
        <div className="col-xl-5 col-sm-6 col-12">
          <div className="textField">
            <Controller
              control={control}
              name="description"
              // rules={{ required: 'title is Required' }}
              defaultValue={props.default?.description}
              render={({ onChange, value, name }) => (
                <TextInput
                  label="Description"
                  placeholder=""
                  error={errors.description?.message}
                  onChange={onChange}
                  name={name}
                  default={value}
                />
              )}
            ></Controller>
          </div>
        </div>
        <div className="col-xl-5 col-sm-6 col-12">
          <div className="button-outer pt-3">
            <Button type="submit" noTransform disabled={props.isSubmitting}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateBannerForm;
