import React, { useEffect, useState } from 'react';
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { Attribute } from '../../api/models/attribute';
import { Category } from '../../api/models/category';
import { Filters } from '../../api/models/filters';
import ClickAction from '../../components/actions/ClickAction';
import AttributeInputController from '../../components/attributes/AttributeInputController';
import FilterInputController from '../../components/attributes/FilterInputController';
import { Button } from '../../components/button';
import AddAttributeForm from '../../components/custom-forms/AddAttributeForm';
import AddFilterForm from '../../components/custom-forms/AddFilterForm';
import { ActionsDataField, TextDataField } from '../../components/data-fields';
import { DataTable } from '../../components/data-table';
import { TextInput } from '../../components/form-group';
import SwitchInput from '../../components/form-group/switch-input';
import TextArea from '../../components/form-group/TextArea';
import Modal from '../../components/modal';
import FileUploadController from '../../components/upload/FileUploadController';

const CreateCategoryForm: React.FunctionComponent<{
  onSubmit: (data: Category) => void;
  default?: Category;
  errorMessage?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const [editAttributeModal, setEditAttributeModal] = useState<{
    open?: boolean;
    data?: Attribute;
    index?: number;
  }>({});

  const [editFilterModal, setEditFilterModal] = useState<{
    open?: boolean;
    data?: Filters;
    index?: number;
  }>({});
  const { handleSubmit, errors, control, watch, setValue } = useForm<Category>({
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: props.default,
  });

  const onSubmit = (data: Category) => {
    props.onSubmit({
      ...data,
      properties: (attributes as Attribute[]) || [],
      filters: (filters as Filters[]) || [],
    });
  };

  const {
    fields: attributes,
    append: addAttribute,
    remove: removeAttriute,
  } = useFieldArray<Attribute>({
    control,
    name: 'properties',
  });

  const {
    fields: filters,
    append: addFilter,
    remove: removeFilter,
  } = useFieldArray<Filters>({
    control,
    name: 'filters',
  });

  useEffect(() => {
    setValue('title', props.default?.title);
    setValue('description', props.default?.description);
    setValue('properties', props.default?.properties);
    setValue('filters', props.default?.filters);
  }, [props.default, setValue]);

  const updateAttribute = (index: number, data: Attribute) => {
    const properties = watch('properties');
    data.inherited = true;
    properties[index] = data;
    setValue(`properties`, [...properties]);
  };

  const updateFilter = (index: number, data: Filters) => {
    const fl = watch('filters');
    filters[index] = data;
    setValue(`filters`, [...fl]);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="title"
                rules={{ required: 'Title is Required' }}
                defaultValue={props.default?.title}
                render={({ onChange, value, name }) => (
                  <TextInput
                    label="Title"
                    placeholder=""
                    error={errors.title?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <label className="d-block font-xsmall">&nbsp;</label>
              <Controller
                control={control}
                name="highlighted"
                defaultValue={props.default?.highlighted}
                render={({ onChange, value, name }) => (
                  <SwitchInput
                    label="Highlight"
                    placeholder="Highlight"
                    error={errors.highlighted?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="col-xl-5 col-sm-6 col-12 mb-3">
            <FileUploadController
              value={props.default?.image}
              control={control}
              title="Icon"
              name="image"
              setValue={setValue}
              error={(errors.image as FieldError)?.message}
            ></FileUploadController>
          </div>

          <div className="col-xl-5 col-sm-6 col-12 mb-3">
            <FileUploadController
              value={props.default?.bannerImage}
              title="Banner Image"
              control={control}
              name="bannerImage"
              setValue={setValue}
              error={(errors.image as FieldError)?.message}
            ></FileUploadController>
          </div>
          <div className="col-xl-5 col-sm-6 col-12 mb-3">
            <FileUploadController
              value={props.default?.mobileBannerImage}
              title="Mobile Banner Image"
              control={control}
              name="mobileBannerImage"
              setValue={setValue}
              error={(errors.image as FieldError)?.message}
            ></FileUploadController>
          </div>
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="description"
                defaultValue={props.default?.description}
                render={({ onChange, value, name }) => (
                  <TextArea
                    label="Description"
                    placeholder=""
                    error={errors.description?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                    rows={4}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="col-xl-5 col-sm-6 col-12">
            <Controller
              control={control}
              name="commission"
              rules={{ required: 'Payout Percentage is Required' }}
              defaultValue={0}
              render={({ onChange, value, name }) => (
                <TextInput
                  label="Payout Percentage"
                  type="number"
                  placeholder="Percentage value"
                  //error={errors.title?.message}
                  onChange={onChange}
                  name={name}
                  default={value}
                />
              )}
            ></Controller>
          </div>

          <div className="col-12">
            <div className="my-5">
              <h6 className="mb-3">Attributes</h6>
              <DataTable list={attributes} recordIdKey="key">
                <TextDataField label="Label" source="label"></TextDataField>
                <TextDataField label="Key" source="key"></TextDataField>
                <TextDataField label="Type" source="type"></TextDataField>
                <TextDataField
                  label="Required"
                  source="required"
                  nullValue="No"
                  formatContent={(val) => (val ? 'Yes' : 'No')}
                ></TextDataField>
                <ActionsDataField actionsColumn label="Actions">
                  <ClickAction
                    onClick={(index: number, data: Attribute) =>
                      setEditAttributeModal({ open: true, index, data })
                    }
                  >
                    Edit
                  </ClickAction>
                  <ClickAction
                    onClick={(index: number, data: Attribute) => {
                      removeAttriute(index);
                      const filterIndex = filters.findIndex(
                        (d) => d.key === data.key
                      );
                      if (filterIndex !== -1) {
                        removeFilter(filterIndex);
                      }
                    }}
                  >
                    Delete
                  </ClickAction>
                </ActionsDataField>
              </DataTable>
              <div className="mt-4">
                <Button
                  btnLink
                  size="sm"
                  className="text-red"
                  onClick={() => setEditAttributeModal({ open: true })}
                >
                  + Add New Attribute
                </Button>
              </div>
            </div>
          </div>
          {watch('properties')?.length > 0 && (
            <div className="col-xl-5 col-sm-6 col-12">
              <div className="textField">
                <h6>Filters</h6>
                <DataTable list={filters} recordIdKey="key">
                  <TextDataField label="Name" source="name"></TextDataField>
                  <TextDataField label="Key" source="key"></TextDataField>
                  <TextDataField label="Type" source="type"></TextDataField>
                  <ActionsDataField actionsColumn label="Actions">
                    <ClickAction
                      onClick={(index: number, data: Filters) =>
                        setEditFilterModal({ open: true, index, data })
                      }
                    >
                      Edit
                    </ClickAction>
                    <ClickAction
                      onClick={(index: number) => {
                        removeFilter(index);
                      }}
                    >
                      Delete
                    </ClickAction>
                  </ActionsDataField>
                </DataTable>

                <Button
                  btnLink
                  size="sm"
                  className="text-red"
                  onClick={() => setEditFilterModal({ open: true })}
                >
                  + Add New Filter
                </Button>
              </div>
            </div>
          )}
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="mb-2 mt-2">
              {props.errorMessage && (
                <span className="message_validation">{props.errorMessage}</span>
              )}
            </div>

            <div className="button-outer pt-3">
              <Button type="submit" noTransform disabled={props.isSubmitting}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
      {editAttributeModal.open && (
        <Modal
          width="798"
          isOpen={editAttributeModal.open}
          onClose={() => {
            setEditAttributeModal({});
            return true;
          }}
        >
          <AddAttributeForm
            key={editAttributeModal.data?.key || 'create'}
            attribute={editAttributeModal.data}
            onSubmit={(d: Attribute) => {
              if (editAttributeModal.data) {
                updateAttribute(editAttributeModal.index!, d);
              } else {
                addAttribute(d);
              }
              setEditAttributeModal({});
            }}
            onCancel={() => setEditAttributeModal({})}
          ></AddAttributeForm>
        </Modal>
      )}
      {editFilterModal.open && (
        <Modal
          width="798"
          isOpen={editFilterModal.open}
          onClose={() => {
            setEditAttributeModal({});
            return true;
          }}
        >
          <AddFilterForm
            key={editFilterModal.data?.key || 'create'}
            filter={editFilterModal.data}
            onSubmit={(d: Filters) => {
              if (editFilterModal.data) {
                updateFilter(editFilterModal.index!, d);
              } else {
                addFilter(d);
              }
              setEditFilterModal({});
            }}
            properties={watch('properties')?.map((pr: Attribute) => ({
              name: pr.label,
              value: pr.key,
            }))}
            onCancel={() => setEditFilterModal({})}
          ></AddFilterForm>
        </Modal>
      )}
    </>
  );
};

export default CreateCategoryForm;
