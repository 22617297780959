import React from 'react';
import PlainLayout from '../../../layout/PlainLayout';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';
import { ContainerLoader } from '../../../components/loader';
import { metaData } from './metaData';
import ClickLink from '../../../components/click-link';
import CompanyDetailsForm from './CompanyDetailsForm';
import { RouteKeys } from '../../routes/route-keys';

const EditView: React.FunctionComponent = (props) => {
  const history = useHistory();
  const { data, loading, loaded } = useDataLoader(() =>
    api.crud.getWithOutId({
      resource: metaData.resource,
    })
  );
  const { performAction, isPerforming } = usePerformAction(
    (d: any) =>
      data?._id
        ? api.crud.update(
            {
              resource: metaData.resource,
              resourceId: data._id,
            },
            d
          )
        : api.crud.create(
            {
              resource: metaData.resource,
            },
            d
          ),
    () => {
      toastr.success('Success', 'Details updated successfully');
    },
    () => {
      toastr.error('Error', 'Unable to update Details');
    }
  );

  return (
    <PlainLayout>
      <div className="row mb-4">
        <div className="col">
          <h4 className="font-weight-bold">Update Company Details</h4>
        </div>
        {/* <div className="col-auto">
            <ClickLink link={`${metaData.route}`}>
              <i className="icon-chevron-left mr-2 font-large"></i> Back
            </ClickLink>
          </div> */}
      </div>
      {(!loaded || loading) && <ContainerLoader height={500}></ContainerLoader>}

      {loaded && !loading && (
        <CompanyDetailsForm
          onSubmit={(d) => performAction(d)}
          isSubmitting={isPerforming}
          default={data}
        />
      )}
    </PlainLayout>
  );
};

export default EditView;
