import { useSelector, useDispatch } from 'react-redux';
import { State } from '../store/interfaces';
import {
  EntitiesState,
  actions as entitiesAction,
} from '../store/reducers/entity';

const useEntities = (): EntitiesState => {
  const entities = useSelector((state: State) => state.entityState);
  const dispatch = useDispatch();

  if (!entities.isLoaded) {
    dispatch(entitiesAction.loadEntities());
  }

  return entities;
};

export default useEntities;
