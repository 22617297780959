import React from 'react';
import ClickLink from '../../components/click-link';
import PlainLayout from '../../layout/PlainLayout';
import CreateCategoryForm from './CreateCategoryForm';
import { metaData } from './metaData';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';

const EditView: React.FunctionComponent = (props) => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data, loading, loaded } = useDataLoader(() =>
    api.crud.get({
      resource: metaData.resource,
      resourceId: params.id,
    })
  );

  const { performAction, isPerforming } = usePerformAction(
    (d: any) =>
      api.crud.update(
        {
          resource: metaData.resource,
          resourceId: params.id,
        },
        d
      ),
    () => {
      toastr.success('Success', 'Category updated successfully');
      history.push(metaData.route || '/');
    },
    () => {
      toastr.error('Error', 'Unable to update category');
    }
  );

  return (
    <PlainLayout>
      
      <div className="row mb-4">
          <div className="col">
            <h4 className="font-weight-bold">Update Category</h4>
          </div>
          <div className="col-auto">
            <ClickLink link={`${metaData.route}`}>
              <i className="icon-chevron-left mr-2 font-large"></i> Back to list
            </ClickLink>
          </div>
        </div>
        {(!loaded || loading) && (
          <ContainerLoader height={500}></ContainerLoader>
        )}
        {data?.data && (
          <CreateCategoryForm
            onSubmit={(d) => performAction(d)}
            isSubmitting={isPerforming}
            default={data.data}
          />
        )}
    </PlainLayout>
  );
};

export default EditView;
