import { MediaFile } from './media';
import { ActiveSubscription, Subscription } from './subscription';

export enum AccountType {
  Individual = 'Individual',
  Business = 'Business',
}

export enum OnBoardStatus {
  SignedUpWithPhone = 'SignedUpWithPhone',
  BasicInfoAdded = 'BasicInfoAdded',
  Completed = 'Completed',
}

export interface BusinessDetails {
  name: string;
  email: string;
  registrationNumber: string;
  phone: string;
  address: string;
  city: string;
  state: string;
}

export interface BankDetails {
  bankName: string;
  accountNumber: string;
  bankCode: string;
  branch?: string;
  isDefault?: boolean;
}

export interface User {
  _id: string;
  name?: string;
  email?: string;
  phone?: string;
  isAdmin?: boolean;
  emailVerified?: boolean;
  phoneVerified?: boolean;
  dob?: Date;
  role?: string;
  avatar?: MediaFile;
  username?: string;
  isActive?: boolean;
  isBlocked?: boolean;
  failedLoginAttemptCount?: number;
  isArchived?: boolean;
  isProfileSet?: boolean;
  accountType: AccountType;
  business: BusinessDetails;
  subscriptionDetails?: Subscription;
  followedCount?: number;
  followingCount?: number;
  reportedCount?: number;
  allowNotification?: boolean;
  subscriptions: ActiveSubscription[];
  paypalReciever?: string;
  bankDetails?: BankDetails[];
  interestedCategories?: string[];
  gender?: string;
}

export interface CreatedBy {
  name: string;
  avatar: MediaFile;
  id: string;
  email?: string;
  phone?: string;
}
export interface CompanyDetails {
  _id?: string;
  companyName: string;
  address: string;
  city: string;
  state: string;
  zip?: string;
  phone: string;
  country: string;
  email: string;
}
