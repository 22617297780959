import format from 'date-fns/format';
import React from 'react';
import { User } from '../../../api/models';
const ProfileDetails: React.FunctionComponent<{ userDetails?: User }> = (
  props
) => {
  return (
    <div
      className="tab-pane active"
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
    >
      <div className="bg-white shadow-sm p-4 rounded">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12 mb-2">
            <div className="form-group mb-4">
              <label className="mb-1 font-xsmall text-gray-800">Name</label>
              <input
                type="text"
                className="form-control form-control-lg font-small bg-white rounded-0 border-top-0 border-left-0 border-right-0 px-0"
                value={props.userDetails?.name}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 mb-2">
            <div className="form-group mb-4">
              <label className="mb-1 font-xsmall text-gray-800">
                Gender , DOB
              </label>
              <div className="form-control form-control-lg font-small bg-white rounded-0 border-top-0 border-left-0 border-right-0 px-0">
                {props.userDetails?.gender
                  ? props.userDetails?.gender?.charAt(0).toUpperCase() +
                    props.userDetails?.gender?.slice(1)
                  : ''}
                {', '}
             
              <span>
                {format(new Date(props.userDetails?.dob!), 'dd MMM yyyy')}
              </span>
              </div>
            </div>
          </div>
          <div className="w-100"></div>
          <div className="col-md-4 col-sm-6 col-12 mb-2">
            <div className="form-group mb-4">
              <label className="mb-1 font-xsmall text-gray-800">
                Email Address
              </label>
              <input
                type="text"
                className="form-control form-control-lg font-small bg-white rounded-0 border-top-0 border-left-0 border-right-0 px-0"
                value={props.userDetails?.email}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-12 mb-2">
            <div className="form-group mb-4">
              <label className="mb-1 font-xsmall text-gray-800">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control form-control-lg font-small bg-white rounded-0 border-top-0 border-left-0 border-right-0 px-0"
                value={props.userDetails?.phone}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileDetails;
