import React, { useEffect } from 'react';
import { Attribute } from '../../api/models/attribute';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import AttributeInputController from '../../components/attributes/AttributeInputController';
import { Button } from '../../components/button';
import { SelectPhoneInput, TextInput } from '../../components/form-group';
import TextArea from '../../components/form-group/TextArea';
import SelectInput from '../../components/form-group/SelectInput';
import SelectMultipleInput from '../../components/form-group/SelectMultipleInput';
import { ArrayDataField } from '../../components/data-fields';
import { PermissionSelection } from '../../components/permission-selection-view';
import { findAllByAltText } from '@testing-library/react';

interface CreateRoleFormProps {
  name: string;
  permissions?: string[];
}

const CreateRoleForm: React.FunctionComponent<{
  onSubmit: (data: CreateRoleFormProps) => void;
  default?: CreateRoleFormProps;
  permissions?: string[];
  errorMessage?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const { handleSubmit, errors, control, watch, setValue, getValues } = useForm<
    CreateRoleFormProps
  >({
    shouldFocusError: true,
    defaultValues: props.default,
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  useEffect(() => {
    setValue('name', props.default?.name);
    setValue('permissions', props.default?.permissions);
  }, [props.default, setValue]);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <div className="row">
        <div className="col-xl-10 col-12">
      <div className="textField">
        <Controller
          control={control}
          name="name"
          rules={{ required: 'Name is Required' }}
          defaultValue={props.default?.name}
          render={({ onChange, value, name }) => (
            <TextInput
              label="Name"
              placeholder="Name"
              error={errors.name?.message}
              onChange={onChange}
              default={value}
              name={name}
            />
          )}
        ></Controller>
      </div>

      <div className="textField">
        <Controller
          control={control}
          name="permissions"
          defaultValue={props.default?.permissions}
          render={({ onChange, value, name }) => (
            <PermissionSelection
              heading="Permissions"
              disabled={false}
              onChange={onChange}
              selected={value || []}
            ></PermissionSelection>
          )}
        ></Controller>
      </div>

      <div className="mb-2 mt-2">
        {props.errorMessage && (
          <span className="message_validation">{props.errorMessage}</span>
        )}
      </div>

      <div className="button-outer text-right">
        <Button type="submit" disabled={props.isSubmitting}>
          Save
        </Button>
      </div>
      </div>
      </div>
      </form>
  );
};

export default CreateRoleForm;
