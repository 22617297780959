import React from 'react';
import { MetaData } from '../../models/meta-data';
import { DataTable } from '../../components/data-table';
import { Dict } from '../../models';
import CrudListBaseViewWithToolbar from './list-view/CrudListBaseViewWithToolbar';

interface CrudListViewProps {
  metaData: MetaData;
  actions?: React.ReactNode[];
  defaultFilters?: Dict;
  queryParams?: Dict;
  hideHeaders?: boolean;
  dataTableClassName?: string;
  id?: string;
  utility?: React.ReactNode;
  getAllPayoutIds?: any;
  payoutFilter?: string;
}

const CrudListView: React.SFC<CrudListViewProps> = (props) => {
  return (
    <CrudListBaseViewWithToolbar
      metaData={props.metaData}
      actions={props.actions}
      defaultFilters={props.defaultFilters}
      queryParams={props.queryParams}
      getAllPayoutIds={props.getAllPayoutIds}
      payoutFilter={props.payoutFilter}
    >
      {props.utility}
      <DataTable
        recordIdKey="_id"
        list={[]}
        className={props.dataTableClassName}
        id={props.id || ''}
      >
        {props.children}
      </DataTable>
    </CrudListBaseViewWithToolbar>
  );
};

export default CrudListView;
