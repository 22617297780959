import {
  CrudService,
  UserService,
  EntityService,
  UploadService,
} from './services';
import CatalogService from './services/catalog';
import ApiServiceDataStore from './services/data';
import PaymentService from './services/payment';

export default class EdsApiClient {
  public crud: CrudService;
  public user: UserService;
  public entity: EntityService;
  public upload: UploadService;
  public catalog: CatalogService;
  public payment: PaymentService;
  private store: ApiServiceDataStore = {
    apiDomain: '',
    token: undefined,
  };

  constructor(apiDomain: string) {
    this.store.apiDomain = apiDomain;
    this.crud = new CrudService(this.store);
    this.user = new UserService(this.store);
    this.entity = new EntityService(this.store);
    this.upload = new UploadService(this.store);
    this.catalog = new CatalogService(this.store);
    this.payment = new PaymentService(this.store);
  }

  public setToken(token?: string) {
    this.store.token = token;
  }
}
