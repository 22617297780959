import { ApiResponse } from '../models';
import { Product } from '../models/catalog';
import * as http from './http';
import ApiService from './service';

export default class CatalogService extends ApiService {
  public async getMyProducts(type: string): Promise<Product[]> {
    const url = `${this.apiDomain}/product/my-products/${type}`;
    const response = await http.get(url, this.token);
    return response.data?.data ? response.data?.data : response.data;
  }
  public async getMyAds(userId: string): Promise<Product[]> {
    const url = `${this.apiDomain}/product/my-ads/${userId}`;
    const response = await http.get(url, this.token);
    return response.data.data;
  }
  public async getPurchasedProducts(userId: string): Promise<Product[]> {
    const url = `${this.apiDomain}/product/my-purchase/${userId}`;
    const response = await http.get(url, this.token);
    return response.data.data;
  }
}
