import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteKeys } from './route-keys';
import ListView from './ListView';

const CategoryRouter: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path={RouteKeys.List} component={ListView} />
    </Switch>
  );
};

export default CategoryRouter;
