import React, { useContext } from 'react';
import { ButtonLink } from '../../button';
import { MetaDataContext } from '../../../contexts';

const CreateActionButton: React.FunctionComponent<{
  text?: string;
  iconClass?: string;
  hideIcon?: boolean;
}> = (props) => {
  const metaData = useContext(MetaDataContext);
  return (
    <ButtonLink link={`${metaData?.route}/new`} size="sm">
      {!props.hideIcon && (
        <i className={props.iconClass || 'icon-plus icon-plus--white'} />
      )}
      {props.text || 'Create'}
    </ButtonLink>
  );
};

export default CreateActionButton;
