import React from 'react';
import ClickLink from '../../components/click-link';
import PlainLayout from '../../layout/PlainLayout';
import CreateBrandForm from './CreateBrandForm';
import { metaData } from './metaData';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';

const CreateView: React.FunctionComponent = (props) => {
  const history = useHistory();
  const { data: subCategories, loading, loaded } = useDataLoader(() =>
    api.crud.getList({ resource: 'subCategory', noPagination: true })
  );
  const { performAction, isPerforming } = usePerformAction(
    (data: any) =>
      api.crud.create(
        {
          resource: metaData.resource,
        },
        data
      ),
    () => {
      toastr.success('Success', 'Brand created successfully');
      history.push(metaData.route || '/');
    },
    () => {
      toastr.error('Error', 'Unable to create brand');
    }
  );
  return (
    <PlainLayout>
      <div>
        <div className="row mb-4">
          <div className="col"><h4 className="font-weight-bold">Create New Brand</h4></div>
          <div className="col-auto">
            <ClickLink link={`${metaData.route}`}>
              <i className="icon-chevron-left mr-2 font-large"></i> Back
            </ClickLink>
          </div>
        </div>
        {(!loaded || loading) && (
          <ContainerLoader height={500}></ContainerLoader>
        )}
        {loaded && (
          <CreateBrandForm
            onSubmit={(d) => performAction(d)}
            isSubmitting={isPerforming}
            subCategories={subCategories.data.data}
          />
        )}
      </div>
    </PlainLayout>
  );
};

export default CreateView;
