import React from 'react';
import './AvatarView.scss';
import defaultAvatar from './default-avatar.png';
import defaultProject from './default-project.png';

interface AvatarViewProps {
  src?: string;
  name?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  defaultImageType?: 'avatar' | 'project';
}

const AvatarView: React.SFC<AvatarViewProps> = (props) => {
  const noImage =
    props.defaultImageType === 'project' ? defaultProject : defaultAvatar;
  return (
    <div className="Avatar">
      <img
        className={`Avatar__image Avatar__image--${props.size || 'small'}`}
        src={props.src || noImage}
        alt={props.name}
        title={props.name}
      />
    </div>
  );
};

export default AvatarView;
