import React, { useContext } from 'react';

import { DropDown, DropDownSelect, DropDownMenu } from '../dropdown';
import { DataTableFieldProps } from '../data-table';
import { DataRecordContext, MetaDataContext } from '../../contexts';

const ActionsDataField: React.SFC<DataTableFieldProps> = (props) => {
  const recordDetails = useContext(DataRecordContext);
  const metaData = useContext(MetaDataContext);

  const showChildrens = React.Children.map(props.children, (child) => {
    if (
      React.isValidElement(child) &&
      child.props.show &&
      !child.props.show(recordDetails, metaData)
    ) {
      return undefined;
    }
    return child;
  })?.filter((child) => child);
  if (!showChildrens || !showChildrens.length) {
    return <></>;
  }

  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        if (child.props.show) {
          if (!child.props.show(recordDetails, metaData)) {
            return <></>;
          }
        }
        return React.cloneElement(child, { ...props, ...child.props });
      }
      return child;
    });
  };

  return (
    <DropDown className="dropdownAction">
      <DropDownSelect theme="secondary" hideCue>
        <i className="icon-ellipse-v" />
      </DropDownSelect>
      <DropDownMenu>{renderChildren()}</DropDownMenu>
    </DropDown>
  );
};

export default ActionsDataField;
