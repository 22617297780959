import React, { useState } from 'react';
import { SubscriptionPlan } from '../../../../api/models';

interface PlanProps {
    onChange: (data: SubscriptionPlan) => void;
    data: SubscriptionPlan[];
    label?: string;
    error?: string;
    name?: string;
    default?: string;
}

const Plans: React.FunctionComponent<PlanProps> = (props) => {
    const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();

    const renderPlanDetails = (plan: SubscriptionPlan) => {
        return (
            <>
                {' '}
                <div className="bg-white shadow px-5 pt-lg-4 pt-3 pb-2 pb-lg-3 position-relative mb-3"> <div className="row">
                    <div className="col-2">
                        <input
                            type="radio"
                            id={plan._id}
                            checked={plan._id === selectedPlan?._id}
                            onClick={() => {
                                props.onChange(plan);
                                setSelectedPlan(plan);
                            }}
                        />{' '}
                    </div>
                    <div className="col-6">{plan.name}<br />Maximum 15 posts</div>
                    <div className="col-4">{`$ ${plan.amount}${plan.period ? `/${plan.period}` : ''} `}</div>
                </div></div>

            </>
        );
    };

    return (
        <>
            <div className={`Input Input--gap-md`}>
                {props.label && (
                    <label htmlFor={props.label || props.name || 'Plan'} className="Input__label Input__label--full">
                        {props.label}
                    </label>
                )}
                {props.data.map((plan) => {
                    return renderPlanDetails(plan);
                })}
                {props.error && <span className="Input__error">{props.error}</span>}
                {props.data.length < 1 && <span className="Input__error">{`No plan found`}</span>}
            </div>
        </>
    );
};

export default Plans;
