import React from 'react';

import './Layout.scss';

interface WhiteBoxProps {
  bottomMargin?: string;
  alignCenter?: boolean;
  round?: boolean;
}

const WhiteBox: React.SFC<WhiteBoxProps> = (props) => {
  return (
    <div
      className={`white-box white-box--boxShadow-lg ${
        props.alignCenter ? 'white-box--text-center' : ''
      } ${props.round ? 'white-box--round-lg' : ''}  ${
        props.bottomMargin ? 'mb-4' : ''
      }`}
    >
      {props.children}
    </div>
  );
};

export default WhiteBox;
