export enum RouteKeys {
  Home = '/',
  Auth = '/auth',
  BannerManagement = '/banner',
  AdminUser = '/admin-user',
  NotFound = '/page-not-found',
  Dashboard = '/dashboard',
  Category = '/category',
  Subcategory = '/subcategory',
  Role = '/role',
  Permission = '/permission',
  Brand = '/brand',
  SubscriptionPlan = '/subscription-plan',
  SubscriptionCustomPlanRequests = '/subscription-custom-plan-request',
  User = '/user',
  Product = '/product',
  Leads = '/leads',
  Payment = '/payment',
  Payout = '/payout',
  ChangePassword = '/change-password',
  CompanyDetails = '/company-details',
  Auctions = '/auction',
  Pages = '/pages',
  ReportedUsers = '/reported-users',
  ReportedAds = '/reported-ads',
}
