import React, { useState, useEffect, useCallback } from 'react';
import useWindowSrollHandler from '../hooks/useWindowSrollHandler';
import {
  findNestedObjectValue,
  setNestedObjectValue,
} from '../components/utils';

interface PaginatedDataLoaderContextInterface<T = any, U = any> {
  loading?: boolean;
  loaded?: boolean;
  allPagesLoaded?: boolean;
  error?: Error;
  data?: T;
  list?: U[];
  page: number;
  loadPage: (page: number) => T;
  reload: () => T;
}

export const PaginatedDataLoaderContext = React.createContext<
  PaginatedDataLoaderContextInterface
>({
  loading: false,
  loaded: false,
  allPagesLoaded: false,
  error: undefined,
  data: undefined,
  page: 0,
  loadPage: (page: number) => [],
  list: [],
  reload: () => undefined,
});

interface PaginatedDataLoaderProps<T = any> {
  loadPage: (page?: number) => Promise<any>;
  source?: string;
  mergeSources?: string[];
  mergeData?: (old: T, added: T) => T;
  defaultData?: T;
}

const PaginatedDataLoader: React.SFC<PaginatedDataLoaderProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<any>(props.defaultData);
  const [list, setList] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);

  const resolveList = useCallback((_data: any, source?: string): any[] => {
    return findNestedObjectValue(_data, source);
  }, []);

  const _mergeData = useCallback((old: any[], newData: any, _page?: number) => {
    if (!_page) {
      return newData;
    }
    const merged = [];
    if (old) {
      merged.push(...old);
    }
    if (newData && newData.length) {
      merged.push(...newData);
    }

    return merged;
  }, []);

  const mergeData = useCallback(
    (old: any[], added: any, _page?: number) => {
      const _list = resolveList(added, props.source);
      if (!_list || !_list.length) {
        setAllPagesLoaded(true);
      }

      return _mergeData(old, _list, _page);
    },
    [resolveList, setAllPagesLoaded, props, _mergeData]
  );

  const mergeAllSources = useCallback(
    (_data: any, _page: number) => {
      if (!props.mergeSources || !props.mergeSources.length) {
        return _data;
      }

      for (const source of props.mergeSources) {
        const oldData = findNestedObjectValue(data, source);
        const newData = findNestedObjectValue(_data, source);
        const newValue = _mergeData(oldData, newData, _page);
        setNestedObjectValue(_data, source, newValue);
      }
      return _data;
    },
    [props, _mergeData, data]
  );

  const reload = () => {
    setPage(1);
    setList([]);
    setData(props.defaultData);
    setError(undefined);
    setAllPagesLoaded(false);
    setLoaded(false);
    setLoading(false);
  };

  const loadPage = useCallback(
    async (_page: number) => {
      if (loading || allPagesLoaded) {
        return;
      }
      if (_page < page) {
        return;
      }
      setLoading(true);
      setLoaded(false);
      try {
        setPage(_page);
        const loadedData = await props.loadPage(_page);
        const _data = mergeAllSources(loadedData, _page);
        setData(_data);
        const merged = mergeData(list, _data, _page);
        setList(merged);
      } catch (e) {
        setError(e);
      }
      setLoaded(true);
      setLoading(false);
    },
    [
      loading,
      allPagesLoaded,
      setLoading,
      setLoaded,
      setPage,
      props,
      setData,
      mergeData,
      mergeAllSources,
      setList,
      setError,
      list,
      page,
    ]
  );

  useEffect(() => {
    if (!loading && !loaded && !error) {
      loadPage(1);
    }
  }, [loadPage, loading, loaded, error]);

  const handleScroll = (direction: 'pageUp' | 'pageDown') => {
    if (direction === 'pageDown') {
      loadPage(page + 1);
    }
  };

  useWindowSrollHandler(handleScroll, [handleScroll]);

  return (
    <PaginatedDataLoaderContext.Provider
      value={{
        loaded,
        allPagesLoaded,
        loading,
        data,
        error,
        page,
        loadPage,
        list,
        reload,
      }}
    >
      {props.children}
    </PaginatedDataLoaderContext.Provider>
  );
};

export default PaginatedDataLoader;
