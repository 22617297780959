import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Select from 'react-select';
import api from '../../../api';
import { CrudListRequestModel } from '../../../api/models';
import { SelectInputProps } from './props';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '53px',
  }),

  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '10',
  }),
  option: (provided: any, state: any) => {
    return {
      ...provided,
      background: state.isFocused ? '#f2f9f9' : '#fff',
      color: '#1d1d1d',
    };
  },
};

interface CustomState {
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  selected: any;
  options: any[];
  selectedValue?: string | number;
}

class SelectInput extends Component<
  SelectInputProps & { excludedValues?: (string | number)[] },
  CustomState
> {
  static defaultProps: Partial<SelectInputProps> = {
    default: '',
    gap: 'md',
    placeholder: 'Select...',
    error: '',
    name: '',
    label: '',
    data: [],
    disabled: false,
    optionLabel: 'name',
    optionValue: '_id',
    stickyStyles: {},
    isSearchable: true,
    isRtl: false,
  };

  constructor(props: SelectInputProps) {
    super(props);
    this.state = {
      isLoading: false,
      isRtl: props.isRtl,
      isSearchable: props.isSearchable,
      selected: '',
      options: [],
      selectedValue: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    // const options = [];
    if (this.props.api) {
      const request: CrudListRequestModel = {
        resource: this.props.api,
        // pagination: {},
        sort: {},
        queryParams: this.props.queryParams,
      };
      this.toggleLoading();
      const { data: responseData } = await api.crud.getListWithoutPagination(request);
      this.toggleLoading();
      // console.log('options:', data);
      const data = responseData?.data ? responseData.data : responseData;
      if (
        this.props.default &&
        data.filter(
          (el: any) =>
            el[this.props.optionValue || '_id'] === this.props.default
        ).length === 0
      ) {
        this.setState(() => ({
          selectedValue: this.props.defaultIfNotFound,
        }));
        this.handleChange({
          [this.props.optionValue || '_id']: this.props.defaultIfNotFound,
        });
      }
      this.setState(() => ({
        options: data,
      }));
    } else {
      // console.log('dataList: ', this.props.data);
      this.setState(
        (prevState: CustomState): CustomState => ({
          ...prevState,
          options: this.props.data || [],
        })
      );
    }
  }

  componentDidUpdate(prevProps: SelectInputProps) {
    if (prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  toggleLoading = () =>
    this.setState((state) => ({ isLoading: !state.isLoading }));

  toggleRtl = () => this.setState((state) => ({ isRtl: !state.isRtl }));

  toggleSearchable = () =>
    this.setState((state) => ({ isSearchable: !state.isSearchable }));

  updateDefault() {
    // console.log('this.props.default(SelectInput): ', this.props.default);
    if (this.props.default !== undefined) {
      this.setState({ selectedValue: this.props.default });
    }
  }

  handleChange(selected: any) {
    // console.log('selected: ', selected);

    // const value = event.value;
    this.setState({
      selected,
      selectedValue: selected[this.props.optionValue || '_id'],
    });
    this.props.onChange(selected[this.props.optionValue || '_id'], selected);
  }

  showValue() {
    return this.state.options.find((option) => {
      if (this.state.selectedValue !== undefined) {
        return (
          option[this.props.optionValue || '_id'] === this.state.selectedValue
        );
      }
      return (
        option[this.props.optionValue || '_id'] ===
        this.state.selected[this.props.optionValue || '_id']
      );
    });
  }

  render() {
    const {
      gap,
      name,
      label,
      placeholder,
      stickyStyles,
      error,
      id,
      excludedValues,
    } = this.props;
    const { isSearchable, isLoading, isRtl } = this.state;
    const inputIdCandidate = id || name || label || placeholder;
    const inputId = inputIdCandidate;
    let { options } = this.state;
    if (excludedValues) {
      options = options.filter(
        (op) => !excludedValues.includes(op[this.props.optionValue || '_id'])
      );
    }
    return (
      <div className={`Input Input--gap-${gap}`} style={stickyStyles}>
        {label && (
          <label htmlFor={inputId} className="Input__label Input__label--full">
            {label}
          </label>
        )}
        <Select
          styles={customStyles}
          className="basic-single"
          classNamePrefix="select"
          isLoading={isLoading}
          isRtl={isRtl}
          isSearchable={isSearchable}
          placeholder={this.props.placeholder}
          getOptionLabel={(option) => option[this.props.optionLabel || 'name']}
          getOptionValue={(option) => option[this.props.optionValue || '_id']}
          options={options}
          onChange={this.handleChange}
          value={this.showValue()}
          isDisabled={this.props.disabled ? this.props.disabled : false}
          id={inputId}
          components={this.props.components}
        />
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

export default SelectInput;
