import React from 'react';
import {
  DateDataField,
  LinkField,
  TextDataField,
} from '../../components/data-fields';
import { TextView } from '../../components/view-fields';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { metaData } from './metaData';
import StatusView from './StatusView';

const ListView: React.FunctionComponent = () => {
  return (
    <PlainLayout>
      <CrudListView metaData={metaData} actions={[]}>
        <LinkField source="user.name" label="User">
          <TextView />
        </LinkField>
        <TextDataField source="user.email" label="Email"></TextDataField>
        <TextDataField source="user.phone" label="Phone"></TextDataField>
        <DateDataField
          source="createdAt"
          label="Requested On"
          dateFormat="DD-MMM-YYYY hh:mm A"
        />
        <StatusView source="status" label="Status" />
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
