import React from 'react';

import './TableToolbar.scss';

export const TableToolbar: React.SFC = (props) => {
  return <div className="table-toolbar"><div className="table-toolbar__row">{props.children}</div></div>;
};

export const TableToolbarLeft: React.SFC = (props) => {
  return <div className="table-toolbar__left">{props.children}</div>;
};

export const TableToolbarRight: React.SFC = (props) => {
  return <div className="table-toolbar__right">{props.children}</div>;
};

export default { TableToolbar, TableToolbarLeft, TableToolbarRight };
