import React from 'react';
import { AccountType } from '../../api/models';
import {
  CreateActionButton,
  StatusActionButton,
} from '../../components/actions/action-buttons';
import {
  CrudListFilterField,
  DateDataField,
  LinkField,
  TextDataField,
} from '../../components/data-fields';
import { DateInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import { TextView } from '../../components/view-fields';
import CrudListFilters from '../../layout/crud/components/CrudListFilters';
import CrudListSearch from '../../layout/crud/components/CrudListSearch';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { Dict } from '../../models';
import { getEnumValues } from '../../utils/enum-utils';
import { metaData } from './metaData';

const ListView: React.FunctionComponent = () => {
  const accountTypes = getEnumValues(AccountType).map((accountType: string) => {
    return { _id: accountType, title: accountType };
  });
  accountTypes.unshift({ _id: '', title: 'Select' });
  const filters: React.ReactNode[] = [
    <CrudListFilterField field="status" label="Status">
      <SelectInput
        data={[
          {
            name: 'Select',
            value: '',
          },
          {
            name: 'Active',
            value: 'active',
          },
          {
            name: 'InActive',
            value: 'inactive',
          },
        ]}
        optionLabel="name"
        optionValue="value"
      />
    </CrudListFilterField>,

    <CrudListFilterField field="accountType" label="Account Type">
      <SelectInput data={accountTypes} optionLabel="title" optionValue="_id" />
    </CrudListFilterField>,
    <CrudListFilterField
      field="dateFrom"
      label="From Date"
      relatedFieldValueProps={{
        maxDate: (fields: Dict) => fields.dateTo || new Date(),
      }}
    >
      <DateInput></DateInput>
    </CrudListFilterField>,

    <CrudListFilterField
      field="dateTo"
      label="To Date"
      relatedFieldValueProps={{
        minDate: (fields: Dict) => fields.dateFrom,
        maxDate: (fields: Dict) => new Date(),
      }}
    >
      <DateInput></DateInput>
    </CrudListFilterField>,
  ];
  return (
    <PlainLayout>
      <CrudListView
        metaData={metaData}
        actions={[
          <CrudListSearch />,
          <CrudListFilters queryFilters>{filters}</CrudListFilters>,
        ]}
      >
        <LinkField source="name" label="Name">
          <TextView />
        </LinkField>
        <TextDataField source="email" label="Email"></TextDataField>
        <TextDataField source="phone" label="Phone"></TextDataField>
        <TextDataField source="followedCount" label="Followers"></TextDataField>
        <TextDataField
          source="followingCount"
          label="Following"
        ></TextDataField>
        <TextDataField
          source="reportedCount"
          label="Abuse Count"
        ></TextDataField>
        <DateDataField
          source="createdAt"
          label="Created Date"
          dateFormat="DD-MMM-YYYY"
        />
        <TextDataField source="accountType" label="AccountType"></TextDataField>
        <StatusActionButton source="isActive" label="Status" />
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
