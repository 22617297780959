import React from 'react';
import { CreateActionButton, StatusActionButton } from '../../components/actions/action-buttons';
import { LinkField } from '../../components/data-fields';
import { TextView } from '../../components/view-fields';
import CrudListSearch from '../../layout/crud/components/CrudListSearch';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { metaData } from './metaData';

const ListView: React.FunctionComponent = () => {
  return (
    <PlainLayout>
      <CrudListView metaData={metaData} actions={[<CrudListSearch />,<CreateActionButton />]}>
        <LinkField source="title" label="Title">
          <TextView />
        </LinkField>
        <StatusActionButton source="isActive" label="Status" />
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
