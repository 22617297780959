import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteKeys } from './route-keys';

const ListView = lazy(() => import('./ListView'));
const EditView = lazy(() => import('./EditView'));
const NotFound = lazy(() => import('../notfound'));

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path={RouteKeys.New} component={EditView} />
        <Route path={RouteKeys.Edit} component={EditView} />
        <Route path={RouteKeys.List} component={ListView} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
