import api from '../api';
import unauthorizedApiResponseInterceptor from '../api/interceptor';

import { actions as userActions } from './reducers/authUser';
import { actions as commonActions } from './reducers/common';

const initialize = (store: any) => {
  const state = store.getState();
  unauthorizedApiResponseInterceptor(() => {
    store.dispatch(userActions.logout());
    store.dispatch(commonActions.clearData());
    api.setToken(undefined);
    window.location.reload();
  });

  api.setToken(state.authUser.token);
  if (state.authUser.token) {
    api.setToken(state.authUser.token);
    // store.dispatch(userActions.fetchProfile());
  }
};

export default initialize;
