import React from 'react';
import { Link } from 'react-router-dom';
import { SideMenuItem } from './interfaces';

interface SubMenuLinkProps extends SideMenuItem {
  active?: boolean;
}

const SubMenuLink: React.SFC<SubMenuLinkProps> = (props) => {
  return (
    <li key={props.menuKey} className={`submenu__item`}>
      <Link
        to={`${props.link}`}
        className={`submenu__link  ${
          props.active ? 'submenu__link--active' : ''
        }`}
      >
        {props.icon && <i className={` icon-${props.icon} submenu__icon`} />}
        {props.label}
      </Link>
      {props.children}
    </li>
  );
};

export default SubMenuLink;
