import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';

import { RouteKeys } from './route-keys';
import CategoryRouter from '../category';
import SubcategoryRouter from '../subcategory';
import AdminUserRouter from '../admin-users';
import PermissionRouter from '../permission';
import RoleRouter from '../role';
import BrandRouter from '../brand';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import SubscriptionPlanRouter from '../subscription-plan';
import SubscriptionCustomPlanRequestsRouter from '../subscription-custom-plan-request';
import UserRouter from '../users';
import Home from '../home';
import Auth from '../auth';
import ProductRouter from '../products';
import LeadsRouter from '../leads';
import AuctionRouter from '../auctions';
import PaymentsRouter from '../payments';
import PayoutRouter from '../payouts';
import BannerRouter from '../banner-management';
import NotFound from '../notfound';
import Dashboard from '../dashboard';
import ChangePassword from '../auth/ChangePassword';
import CompanyDetails from '../settings/company-details/CompanyDetailsView';
import ReportedAds from '../reported-ads';
import ReportedUsers from '../reported-users';
import Pages from '../pages';
class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<ContainerLoader height={500} text={'Loading...'} />}>
        <Switch>
          <RedirectIfAuthenticatedRoute
            path={RouteKeys.Auth}
            component={Auth}
          />
          {/* <Route exact path={RouteKeys.Home} component={Home} /> */}
          <AuthenticateRoute
            exact
            path={RouteKeys.Home}
            component={Dashboard}
          />
          <AuthenticateRoute
            path={RouteKeys.Category}
            component={CategoryRouter}
          />
          <AuthenticateRoute
            path={RouteKeys.Subcategory}
            component={SubcategoryRouter}
          />
          <AuthenticateRoute path={RouteKeys.Brand} component={BrandRouter} />
          <AuthenticateRoute
            path={RouteKeys.AdminUser}
            component={AdminUserRouter}
          />
          <AuthenticateRoute path={RouteKeys.Role} component={RoleRouter} />
          <AuthenticateRoute
            path={RouteKeys.Permission}
            component={PermissionRouter}
          />
          <AuthenticateRoute
            path={RouteKeys.SubscriptionPlan}
            component={SubscriptionPlanRouter}
          />
          <AuthenticateRoute
            path={RouteKeys.SubscriptionCustomPlanRequests}
            component={SubscriptionCustomPlanRequestsRouter}
          />
          <AuthenticateRoute
            path={RouteKeys.Product}
            component={ProductRouter}
          />
          <AuthenticateRoute path={RouteKeys.Leads} component={LeadsRouter} />
          <AuthenticateRoute
            path={RouteKeys.Auctions}
            component={AuctionRouter}
          />
          <AuthenticateRoute
            path={RouteKeys.BannerManagement}
            component={BannerRouter}
          />

          <AuthenticateRoute path={RouteKeys.User} component={UserRouter} />
          <AuthenticateRoute
            path={RouteKeys.Payment}
            component={PaymentsRouter}
          />
          <AuthenticateRoute path={RouteKeys.Payout} component={PayoutRouter} />

          <AuthenticateRoute path={RouteKeys.Dashboard} component={Dashboard} />
          <AuthenticateRoute
            path={RouteKeys.ChangePassword}
            component={ChangePassword}
          />
          <AuthenticateRoute
            path={RouteKeys.ChangePassword}
            component={ChangePassword}
          />
          <AuthenticateRoute
            path={RouteKeys.CompanyDetails}
            component={CompanyDetails}
          />
          <AuthenticateRoute path={RouteKeys.Pages} component={Pages} />
          <AuthenticateRoute
            path={RouteKeys.ReportedAds}
            component={ReportedAds}
          />
          <AuthenticateRoute
            path={RouteKeys.ReportedUsers}
            component={ReportedUsers}
          />
          <Route exact path={RouteKeys.NotFound} component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
