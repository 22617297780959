import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../components/button';
import { TextInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import SwitchInput from '../../components/form-group/switch-input';
import { getEnumValues } from '../../utils/enum-utils';
import {
  PostType,
  CustomSubscription,
  SubscriptionPeriod,
  AccountType,
} from '../../api/models';

const accountTypes = getEnumValues(AccountType).map((accountType: string) => {
  return { name: accountType };
});
const subscriptionPeriods = getEnumValues(SubscriptionPeriod).map(
  (period: string) => {
    return { name: period };
  }
);

const postTypes = getEnumValues(PostType).map((postType: string) => {
  return { name: postType };
});

const CreateSubscriptionPlanForm: React.FunctionComponent<{
  onSubmit: (data: CustomSubscription) => void;
  default?: CustomSubscription;
  errorMessage?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const { handleSubmit, errors, control, setValue } = useForm<
    CustomSubscription
  >({
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: props.default,
  });

  const [isRecurring, setIsRecurring] = useState(
    props.default?.isRecurring ? props.default?.isRecurring : false
  );

  const onSubmit = (data: CustomSubscription) => {
    props.onSubmit({
      ...data,
    });
  };

  useEffect(() => {
    setValue('planName', props.default?.planName);
    setValue('postType', props.default?.postType);
    setValue('accountType', props.default?.accountType);
    setValue('noOfPosts', props.default?.noOfPosts);
    setValue('amount', props.default?.amount);
    setValue('listingDays', props.default?.listingDays);
    setValue('isRecurring', props.default?.isRecurring);
    setValue('period', props.default?.period);
  }, [props.default, setValue]);

  const handleRecurringInput = (value: boolean) => {
    setIsRecurring(value);
    if (!value) {
      setValue('period', undefined);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="textField">
          <Controller
            control={control}
            name="isRecurring"
            defaultValue={props.default?.isRecurring}
            render={({ onChange, value, name }) => (
              <SwitchInput
                label="Recurring"
                placeholder="Recurring"
                error={errors.isRecurring?.message}
                onChange={(val) => {
                  onChange(val);
                  handleRecurringInput(val);
                }}
                name={name}
                default={value}
              />
            )}
          ></Controller>
        </div>

        {isRecurring && (
          <div className="textField">
            <Controller
              control={control}
              name="period"
              defaultValue={props.default?.period}
              rules={{ required: 'Period is Required' }}
              render={({ onChange, value, name }) => (
                <SelectInput
                  label="Period"
                  placeholder="Select Period"
                  error={errors.period?.message}
                  onChange={(val) => {
                    onChange(val);
                    setValue(
                      'period',
                      subscriptionPeriods?.find((item) => item.name === val)
                        ?.name
                    );
                  }}
                  name={name}
                  default={value}
                  optionLabel="name"
                  optionValue="name"
                  data={subscriptionPeriods}
                />
              )}
            ></Controller>
          </div>
        )}

        <div className="textField">
          <Controller
            control={control}
            name="planName"
            rules={{ required: 'Name is Required' }}
            defaultValue={props.default?.planName}
            render={({ onChange, value, name }) => (
              <TextInput
                label="Name"
                placeholder="Name"
                error={errors.planName?.message}
                onChange={onChange}
                name={name}
                default={value}
              />
            )}
          ></Controller>
        </div>

        <div className="textField">
          <Controller
            control={control}
            name="accountType"
            defaultValue={props.default?.accountType}
            rules={{ required: 'Account Type is Required' }}
            render={({ onChange, value, name }) => (
              <SelectInput
                label="Account Type"
                placeholder="Select Account Type"
                error={errors.accountType?.message}
                onChange={(val) => {
                  onChange(val);
                  setValue(
                    'accountType',
                    accountTypes?.find((item) => item.name === val)?.name
                  );
                }}
                name={name}
                default={value}
                optionLabel="name"
                optionValue="name"
                data={accountTypes}
              />
            )}
          ></Controller>
        </div>

        <div className="textField">
          <Controller
            control={control}
            name="postType"
            defaultValue={props.default?.postType}
            rules={{ required: 'Post Type is Required' }}
            render={({ onChange, value, name }) => (
              <SelectInput
                label="Post Type"
                placeholder="Select Post Type"
                error={errors.postType?.message}
                onChange={(val) => {
                  onChange(val);
                  setValue(
                    'postType',
                    postTypes?.find((item) => item.name === val)?.name
                  );
                }}
                name={name}
                default={value}
                optionLabel="name"
                optionValue="name"
                data={postTypes}
              />
            )}
          ></Controller>
        </div>

        <div className="textField">
          <Controller
            control={control}
            name="noOfPosts"
            rules={{ required: 'No.of Posts is Required' }}
            defaultValue={props.default?.noOfPosts}
            render={({ onChange, value, name }) => (
              <TextInput
                label="No.of Posts"
                placeholder="No.of Posts"
                error={errors.noOfPosts?.message}
                onChange={(val) => {
                  onChange(val);
                  setValue('noOfPosts', parseFloat(val));
                }}
                name={name}
                default={value}
                type="number"
              />
            )}
          ></Controller>
        </div>

        <div className="textField">
          <Controller
            control={control}
            name="amount"
            rules={{ required: 'Amount is Required' }}
            defaultValue={props.default?.amount}
            render={({ onChange, value, name }) => (
              <TextInput
                label="Amount"
                placeholder="Amount"
                error={errors.amount?.message}
                onChange={(val) => {
                  onChange(val);
                  setValue('amount', parseFloat(val));
                }}
                name={name}
                default={value}
                type="number"
              />
            )}
          ></Controller>
        </div>

        <div className="textField">
          <Controller
            control={control}
            name="listingDays"
            rules={{ required: 'Listing Days is Required' }}
            defaultValue={props.default?.listingDays}
            render={({ onChange, value, name }) => (
              <TextInput
                label="Listing Days"
                placeholder="Listing Days"
                error={errors.listingDays?.message}
                onChange={(val) => {
                  onChange(val);
                  setValue('listingDays', parseFloat(val));
                }}
                name={name}
                default={value}
                type="number"
              />
            )}
          ></Controller>
        </div>

        <div className="mb-2 mt-2">
          {props.errorMessage && (
            <span className="message_validation">{props.errorMessage}</span>
          )}
        </div>

        <div className="button-outer text-right">
          <Button type="submit" disabled={props.isSubmitting}>
            Save & Send Mail
          </Button>
        </div>
      </form>
    </>
  );
};

export default CreateSubscriptionPlanForm;
