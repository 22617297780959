import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { MediaFile } from '../../api/models';
import FileUploadView from './FileUploadView';

const FileUploadController: React.FunctionComponent<{
  control: Control;
  name: string;
  setValue: (field: string, value: any) => void;
  value?: MediaFile;
  error?: string;
  showIcon?: boolean;
}> = (props) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState<File>();

  const onProgress = (event: ProgressEvent) => {
    const progressPercentage = event.loaded / event.total;
    setProgress(progressPercentage);
  };

  const uploadFile = async (fl: File) => {
    setFile(fl);
    setUploading(true);
    try {
      const uploadedResponse = await api.upload.upload(fl, onProgress);
      props.setValue(props.name, uploadedResponse.data);
    } catch (error) {
      toastr.error('File', error.response.data.message);
    }
    setUploading(false);
    setFile(undefined);
  };

  const closeFile = () => {
    setUploading(false);
    props.setValue(props.name, undefined);
    setFile(undefined);
  };

  return (
    <>
      <Controller
        control={props.control}
        name={props.name}
        rules={{
          validate: (value) =>
            !uploading || 'Please wait till the upload completed',
        }}
        defaultValue={props.value}
        render={({ name, value }) => {
          return (
            <FileUploadView
              title="Image"
              name={name}
              id={name}
              fileName={value?.originalname}
              supportedFormats={['jpeg', 'jpg', 'png']}
              onFileChosen={uploadFile}
              onFileClosed={closeFile}
              progress={progress}
              isUploading={uploading}
              isChosen={!!file || !!value?.originalname}
              fileSize={
                value?.size ? `${(value?.size || 0) / 1000} KB` : undefined
              }
              isUploadingCompleted={!!value?.originalname}
              filePath={value?.path}
              showIcon={props.showIcon}
            ></FileUploadView>
          );
        }}
      />
      {props.error && <span className="TextArea__error">{props.error}</span>}
    </>
  );
};

export default FileUploadController;
