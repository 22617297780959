import { useSelector } from 'react-redux';
import { State } from '../store/interfaces';

const useHasAnyOnePermission = (
  permissionList?: (string | undefined)[]
): boolean => {
  const permissions = useSelector(
    (state: State) => state.authUser.apiPermissions
  );

  if (!permissionList) {
    return true;
  }
  if (!permissions) {
    return false;
  }
  for (const permission of permissionList) {
    if (!permission || permissions?.[permission]) {
      return true;
    }
  }

  return false;
};

export default useHasAnyOnePermission;
