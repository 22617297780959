import React, { useState } from 'react';

import './Dropdown.scss';

interface DropDownProps {
  label?: string;
  className?: string;
}

const DropDown: React.SFC<DropDownProps> = (props) => {
  const [show, setShow] = useState(false);

  const toggle = () => setShow(!show);

  const clasNames: string[] = [];
  if (props.className) {
    clasNames.push(props.className);
  }

  const renderDropDown = () => {
    clasNames.push('dropdown');
    return (
      <div role="presentation" className={clasNames.join(' ')} onClick={toggle}>
        {React.Children.map(props.children, (child, index) => {
          if (React.isValidElement(child)) {
            if (!show && index !== 0) {
              const childProps = { ...child.props, hidden: true };
              return React.cloneElement(child, { ...childProps });
            }
            return child;
          }
        })}
      </div>
    );
  };

  if (props.label) {
    clasNames.push('labelled-dropdown');
    return (
      <div className={clasNames.join(' ')} onClick={toggle}>
        <label className="label">{props.label}</label>
        {renderDropDown()}
      </div>
    );
  }

  return renderDropDown();
};

export default DropDown;
