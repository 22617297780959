import React from 'react';
import { Link } from 'react-router-dom';
import { SideMenuItem } from './interfaces';

interface MenuLinkProps extends SideMenuItem {
  active?: boolean;
}

const MenuLink: React.SFC<MenuLinkProps> = (props) => {
  return (
    <li
      key={props.menuKey}
      className={`menu__item ${props.active ? ' menu__item--active' : ''}`}
    >
      <Link to={`${props.link}`} className="menu__link">
        <i className={` icon-${props.icon} menu__icon`} />
        {props.label}
      </Link>
    </li>
  );
};

export default MenuLink;
