import React from 'react';
import './Button.scss';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
  light?: boolean;
  white?: boolean;
  noTransform?: boolean;
  expand?: boolean;
  btnLink?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const Button: React.SFC<ButtonProps> = (props) => {
  const classes = ['Button', `Button--${props.size || 'md'}`];
  if (props.className) classes.push(props.className);
  if (props.light) classes.push(`Button--light`);
  if (!props.noTransform) classes.push(`Button--uppercase`);
  if (props.white) classes.push(`Button--white`);
  if (props.btnLink) classes.push(`Button--link`);
  if (props.expand) classes.push(`Button--block`);

  return (
    <button
      className={classes.join(' ')}
      type={props.type || 'button'}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
