import React from 'react';
import ClickLink from '../../components/click-link';
import PlainLayout from '../../layout/PlainLayout';
import { metaData } from './metaData';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';
import CrudTabularDetailsView from '../../layout/crud/CrudTabularDetailsView';
import {
  CreateActionButton,
  DeleteActionButton,
  EditActionButton,
} from '../../components/actions/action-buttons';
import { ArrayDataField, TextDataField } from '../../components/data-fields';
import './DetailsView.scss';
import {
  OrderStatusSyllables,
  Product,
  ProductDetails,
} from '../../api/models/catalog';
import ProductChart from '../dashboard/ProductsChart';
import { AvatarView } from '../../components/avatar';
import format from 'date-fns/format';
import { Payment, PaymentType } from '../../api/models';
import { Payout, PayoutStatus } from '../../api/models/payout';
import PayoutProducListView from './components/ProductListView';
const EditView: React.FunctionComponent = (props) => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data, loading, loaded } = useDataLoader<{ data: Payout }>(() =>
    api.crud.get({
      resource: metaData.resource,
      resourceId: params.id,
    })
  );
  return (
    <PlainLayout>
      <h5 className="ItemSummary__item">Payout Details</h5>
      {loaded && !loading && (
        <>
          <div className="col-xl-auto col-12 text-right d-xl-block d-none">
            <ClickLink link={metaData.route}>
              <i className="icon-arrow-left mr-2"></i>Back
            </ClickLink>
          </div>
          <div className="py-4">
            <div className="pt-4 pb-3 border-bottom">
              <div className="row">
                <div className="col-6 col-md-auto mb-3 ">
                  <span className="text-muted font-small">Price</span>
                  <h5 className="h6 mb-0">
                    {data.data.currency || '$'}
                    {data.data.totalPayoutAmount}
                  </h5>
                </div>
                <div className="col-6 col-md-auto mb-3 ">
                  <span className="text-muted font-small">InvoiceNumber </span>
                  <h5 className="font-medium mb-0">{data.data.payoutNumber}</h5>
                </div>
                <div className="col-6 col-md-auto mb-3 ">
                  <span className="text-muted font-small">Requested Date</span>
                  <h5 className="font-medium mb-0">
                    {format(new Date(data.data.createdAt), 'dd/MMM/yyyy')}
                  </h5>
                </div>
              </div>
            </div>
            <div className="pt-4 pb-3  border-bottom">
              <h6 className="font-base mb-3 ">User Details</h6>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Name
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.requestedUser?.name}
                </div>
              </div>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Email
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.requestedUser?.email}
                </div>
              </div>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Phone
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.requestedUser?.phone}
                </div>
              </div>
            </div>

            {data.data.status === PayoutStatus.Processed && (
              <div className="pt-4 pb-3  border-bottom">
                <h6 className="font-base mb-3 ">Processed User Details</h6>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Processed Date
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {format(
                      new Date(data.data.payoutProcessedOn!),
                      'dd/MMM/yyyy'
                    )}
                  </div>
                </div>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Name
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.processedBy?.name}
                  </div>
                </div>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Email
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.processedBy?.email}
                  </div>
                </div>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Phone
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.processedBy?.phone}
                  </div>
                </div>
              </div>
            )}

            <div className="pt-4 pb-3  border-bottom">
              <h6 className="font-base mb-3 ">Payment Details</h6>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Payment Mode
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data?.data?.payoutPaymentDetails?.paymentMode}
                </div>
              </div>
              {data?.data?.payoutPaymentDetails?.paymentMode === 'paypal' && (
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Paypal ID
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data?.data?.payoutPaymentDetails?.paypalRecipient}
                  </div>
                </div>
              )}
              {data?.data?.payoutPaymentDetails?.paymentMode === 'bank' && (
                <>
                  <div className="row font-small">
                    <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                      Bank Name
                    </div>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                      {data?.data?.payoutPaymentDetails?.bankDetails?.bankName}
                    </div>
                  </div>
                  <div className="row font-small">
                    <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                      Bank Code
                    </div>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                      {data?.data?.payoutPaymentDetails?.bankDetails?.bankCode}
                    </div>
                  </div>
                  <div className="row font-small">
                    <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                      Branch
                    </div>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                      {data?.data?.payoutPaymentDetails?.bankDetails?.branch}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <PayoutProducListView
            products={data.data.productDetails}
          ></PayoutProducListView>
        </>
      )}
    </PlainLayout>
  );
};

export default EditView;
