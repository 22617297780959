import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { RouteKeys } from './route-keys';
import RedirectIfAuthenticatedRoute from '../routes/RedirectIfAuthenticatedRoute';

import Login from './Login';
import NotFound from '../notfound';

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <RedirectIfAuthenticatedRoute
          path={RouteKeys.Login}
          component={Login}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
