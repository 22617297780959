import React from 'react';
// import PropTypes, { string } from 'prop-types';

import '../Input.scss';

interface CustomProps {
  default?: string;
  name?: string;
  placeholder?: string;
  autoComplete?: string;
  label?: string;
  gap?: 'sm' | 'md' | 'lg';
  size?: 'lg' | 'sm';
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
}

interface CustomState {
  value?: string;
}

class PasswordInput extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    default: '',
    name: '',
    label: '',
    placeholder: '',
    gap: 'md',
    size: 'sm',
    onChange: () => {
      return;
    },
    error: '',
    disabled: false,
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    // console.log('2. this.props.default(PasswordInput): ', this.props.default);
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
      this.props.onChange(this.props.default);
    }
  }

  handleChange(e: { target: any }) {
    const { value } = e.target;
    this.setState({ value });
    this.props.onChange(value);
  }

  render() {
    const {
      size,
      label,
      placeholder,
      name,
      gap,
      error,
      disabled,
      autoComplete,
    } = this.props;
    const extraClassesInput: string[] = [];
    if (size === 'lg') extraClassesInput.push('Input__field--lg');
    const extraClasses = extraClassesInput.join(' ');
    const inputIdCandidate = name || label || placeholder || '';
    const inputId = inputIdCandidate.toLowerCase().split(' ').join('-');
    return (
      <div className={`Input Input--gap-${gap}`}>
        {label && (
          <label className="Input__label" htmlFor={inputId}>
            {label}
          </label>
        )}
        <input
          id={inputId}
          className={`Input__field ${extraClasses}`}
          type="password"
          placeholder={placeholder}
          onChange={this.handleChange}
          value={this.state.value}
          disabled={disabled}
          autoComplete={autoComplete}
        />

        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

// PasswordInput.propTypes = {
//   default: PropTypes.string,
//   name: PropTypes.string,
//   placeholder: PropTypes.string,
//   label: PropTypes.string,
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   size: PropTypes.oneOf(['lg', 'sm']),
//   onChange: PropTypes.func,
//   error: PropTypes.string,
//   disabled: PropTypes.bool,
// };

// PasswordInput.defaultProps = {
//   default: '',
//   name: '',
//   label: '',
//   placeholder: '',
//   gap: 'md',
//   size: 'sm',
//   onChange: () => {},
//   error: '',
//   disabled: false,
// };

export default PasswordInput;
