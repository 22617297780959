import React from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  Attribute,
  NumberAttribute,
  StringAttribute,
} from '../../api/models/attribute';
import { Filters, SelectFilter } from '../../api/models/filters';
import ClickAction from '../../components/actions/ClickAction';
import { Button } from '../../components/button';
import { ActionsDataField, TextDataField } from '../../components/data-fields';
import { DataTable } from '../../components/data-table';
import { TextInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import SwitchInput from '../../components/form-group/switch-input';

const AddFilterForm: React.FunctionComponent<{
  filter?: Filters;
  onSubmit: (attr: Filters) => void;
  onCancel?: () => void;
  properties?: { name: string; value: string }[];
}> = (props) => {
  const { control, watch, errors, setValue, handleSubmit } = useForm<
    Filters & { minNumberOfItemsToShow?: number }
  >({
    defaultValues: props.filter,
  });
  const type = watch(`type`);

  const constrollerClassName = 'col-lg-4 col-sm-6 col-xs-12 textField my-auto';

  const renderTypeController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`type`}
          control={control}
          defaultValue={props.filter?.type}
          rules={{ required: 'Filter Type is required' }}
          render={({ onChange, name, value }) => {
            return (
              <SelectInput
                label="Filter Type"
                optionLabel="name"
                optionValue="value"
                error={errors?.type?.message}
                data={[
                  {
                    name: 'Slider Filter',
                    value: 'Slider',
                  },
                  {
                    name: 'Select Filter',
                    value: 'Select',
                  },
                ]}
                onChange={onChange}
                default={value}
                name={name}
              ></SelectInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderMultipleController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`multiple`}
          control={control}
          defaultValue={props.filter?.multiple}
          render={({ onChange, name, value }) => {
            return (
              <SwitchInput
                key={`multiple`}
                label="Is Multi Selectable?"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.multiple?.message}
              ></SwitchInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderKeyController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`key`}
          control={control}
          defaultValue={props.filter?.key}
          rules={{ required: 'Key is required' }}
          render={({ onChange, name, value }) => {
            return (
              <SelectInput
                label="Filter Key"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.key?.message}
                optionValue="value"
                optionLabel="name"
                data={props.properties}
              ></SelectInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderNameController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`name`}
          control={control}
          defaultValue={props.filter?.name}
          rules={{ required: 'Name is required' }}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="Filter Name"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.name?.message}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderPrefixController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`valuePrefix`}
          control={control}
          defaultValue={props.filter?.valuePrefix}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="Value Prefix"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.valuePrefix?.message}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };
  const renderSuffixController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`valueSuffix`}
          control={control}
          defaultValue={props.filter?.valueSuffix}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="Value Suffix"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.valueSuffix?.message}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderMinNoOfItemsToShowController = () => {
    const err = errors as DeepMap<SelectFilter, FieldError>;
    const filter = props.filter as SelectFilter;

    return (
      <div className={constrollerClassName}>
        <Controller
          name={'minNumberOfItemsToShow'}
          control={control}
          defaultValue={filter?.minNumberOfItemsToShow}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="No Of Items To Show initially"
                placeholder="No Of Items To Show initially"
                type="number"
                error={err?.minNumberOfItemsToShow?.message}
                onChange={onChange}
                default={value}
                name={name}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div className="col-6 text-right">
        <Button type="submit">{props.filter ? 'Update' : 'Add'}</Button>
      </div>
    );
  };
  const renderCancelButton = () => {
    return (
      <div className="col-6">
        <Button type="button" light onClick={props.onCancel}>
          Cancel
        </Button>
      </div>
    );
  };

  return (
    <form
      className="w-100"
      onSubmit={handleSubmit(props.onSubmit)}
      style={{ overflowX: 'hidden' }}
    >
      <div className="w-100">
        <div className="row no-gutter px-md-4 px-3 py-4">
          <h6 className="col-12">Filter details</h6>
          {renderTypeController()}
          {renderNameController()}
          {renderKeyController()}
          {renderMultipleController()}
          {renderPrefixController()}
          {renderSuffixController()}
          {type === 'Select' && renderMinNoOfItemsToShowController()}
        </div>
        <div className="row no-gutter p-4">
          {renderCancelButton()}
          {renderSubmitButton()}
        </div>
      </div>
    </form>
  );
};

export default AddFilterForm;
