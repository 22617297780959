import React from 'react';
import {
  TableToolbar,
  TableToolbarLeft,
  TableToolbarRight,
} from '../table-toolbar';
import { ToolBarSummary } from '../toolbar-summary';
import { MetaDataHeaders } from '../../models/meta-data';

interface ToolBarProps<T = any> {
  title: string;
  count?: number;
  isLoading?: boolean;
  headers?: MetaDataHeaders[];
  actions?: React.ReactNode[];
  data?: T;
  hideHeaders?: boolean;
}

const ToolBar: React.SFC<ToolBarProps> = (props) => {
  return (
    <>
      <TableToolbar>
        <TableToolbarLeft>
          <ToolBarSummary
            title={props.title}
            count={props.count}
            headers={props.headers}
            data={props.data}
            hideHeaders={props.hideHeaders || props.isLoading}
          />
        </TableToolbarLeft>
        <TableToolbarRight>
          {props.actions && props.actions.length > 0 && (
            <div className="row gutter-6">
              {props.actions?.map((action) => {
                if (React.isValidElement(action)) {
                  return (
                    <div className="col-auto align-self-center">
                      {React.cloneElement(action, {
                        ...action.props,
                        data: props.data,
                      })}
                    </div>
                  );
                }
                return action;
              })}
            </div>
          )}
        </TableToolbarRight>
      </TableToolbar>
    </>
  );
};

export default ToolBar;
