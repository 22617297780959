import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Subscribe } from '../../../../api/models/subscription';
import { Category } from '../../../../api/models/category';
import { Button } from '../../../../components/button';
import { TextInput } from '../../../../components/form-group';
import TextArea from '../../../../components/form-group/TextArea';
import SelectInput from '../../../../components/form-group/SelectInput';
import PlanInput from './Plans';
import { PaymentMode, SubscriptionPlan } from '../../../../api/models';


const CreateSubscriptionForm: React.FunctionComponent<{
  onSubmit: (data: Subscribe) => void;
  default?: Subscribe;
  errorMessage?: string;
  isSubmitting?: boolean;
  categories?: Category[];
  paymentModes: { _id: string; title: string }[];
  postTypes: { _id: string; title: string }[];
  subscriptionPlans: SubscriptionPlan[];
}> = (props) => {

  const plans = props.subscriptionPlans.filter(plan => !plan.isTopFeatured && !plan.isBoostBackToTop && !plan.isCustom);
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>(
    plans
  );

  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const [paymentMode, setPaymentMode] = useState<string | undefined>(undefined);

  const { handleSubmit, errors, control, watch, setValue } = useForm<Subscribe>(
    {
      shouldFocusError: true,
      shouldUnregister: true,
      defaultValues: props.default,
    }
  );

  const handlePostTypeChange = (val: string) => {
    setPostType(val);
    setSelectedPlan(undefined);
    let filteredPlans = plans.filter(plan => plan.postType === val);
    if (category) {
      filteredPlans = filteredPlans.filter(plan => plan.categories?.includes(category));
    }
    setSubscriptionPlans(filteredPlans);
  }

  const handleCategoryChange = (val: string) => {
    setCategory(val);
    setSelectedPlan(undefined);
    let filteredPlans = plans.filter(plan => plan.categories?.includes(val));
    if (postType) {
      filteredPlans = filteredPlans.filter(plan => plan.postType === postType);
    }
    setSubscriptionPlans(filteredPlans);
  }

  const onSubmit = (data: Subscribe) => {
    delete data.postType;
    props.onSubmit({
      ...data,
    });
  };

  useEffect(() => {
    setValue('categoryId', props.default?.categoryId);
    setValue('postType', props.default?.postType);
    setValue('subscriptionPlan', props.default?.subscriptionPlan);
    setValue('paymentMode', props.default?.paymentMode);
    setValue('comments', props.default?.comments);
    setValue('chequeDetails', props.default?.chequeDetails);
  }, [props.default, setValue]);

  const renderCategoryController = () => {
    return (
      <div className="col-xl-5 col-sm-6 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="categoryId"
            rules={{ required: 'Category is Required' }}
            defaultValue={props.default?.categoryId}
            render={({ onChange, value, name }) => (
              <SelectInput
                label="Category"
                placeholder="Select Category"
                error={errors.categoryId?.message}
                onChange={(val) => {
                  handleCategoryChange(val);
                  onChange(val);
                }}
                name={name}
                default={value}
                optionLabel="title"
                optionValue="_id"
                data={props.categories}
              />
            )}
          ></Controller>
        </div>
      </div>
    );
  };

  const renderPostTypeController = () => {
    return (
      <div className="col-xl-5 col-sm-6 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="postType"
            rules={{ required: 'Post Type is Required' }}
            defaultValue={props.default?.postType}
            render={({ onChange, value, name }) => (
              <SelectInput
                label="Post Type"
                placeholder="Select Post Type"
                error={errors.postType?.message}
                onChange={(val) => {
                  handlePostTypeChange(val);
                  onChange(val);
                }}
                name={name}
                default={value}
                optionLabel="title"
                optionValue="_id"
                data={props.postTypes}
              />
            )}
          ></Controller>
        </div>
      </div>
    );
  };

  const renderPlanController = () => {
    return (
      <div className="col-12">
        <div className="textField" style={{ width: '85%' }} >
          <Controller
            control={control}
            name="subscriptionPlan"
            rules={{ required: 'Subscription Plan is Required' }}
            defaultValue={props.default?.subscriptionPlan}
            render={({ onChange, value, name }) => (
              <PlanInput
                label="Subscription Plan"
                error={errors.subscriptionPlan?.message}
                onChange={(plan) => {
                  setSelectedPlan(plan)
                  onChange(plan._id);
                }}
                name={name}
                default={value}
                data={subscriptionPlans}
              />
            )}
          ></Controller>
        </div>
      </div>
    );
  };

  const renderPaymentModeController = () => {
    return (
      <div className="col-xl-5 col-sm-6 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="paymentMode"
            rules={{ required: 'PaymentMode is Required' }}
            defaultValue={props.default?.paymentMode}
            render={({ onChange, value, name }) => (
              <SelectInput
                label="Payment Mode"
                placeholder="Select Payment Mode"
                error={errors.paymentMode?.message}
                onChange={(val) => {
                  setPaymentMode(val);
                  onChange(val);
                }}
                name={name}
                default={value}
                optionLabel="title"
                optionValue="_id"
                data={props.paymentModes}
              />
            )}
          ></Controller>
        </div>
      </div>
    );
  };


  const renderChequeDetailsController = () => {

    return (<div className="col-xl-5 col-sm-6 col-12">
      <div className="textField">
        <Controller
          control={control}
          name="chequeDetails"
          rules={{ required: 'Cheque Details is Required' }}
          defaultValue={props.default?.chequeDetails}
          render={({ onChange, value, name }) => (
            <TextInput
              label="Cheque Details"
              placeholder="Cheque Details"
              error={errors.chequeDetails?.message}
              onChange={onChange}
              name={name}
              default={value}
            />
          )}
        ></Controller>
      </div>
    </div>)
  }

  const renderErrorMessage = () => {
    return (<div className="mb-2 mt-2">
      {props.errorMessage && (
        <span className="message_validation">{props.errorMessage}</span>
      )}
    </div>)
  }

  const renderCommentsController = () => {
    return (
      <div className="col-xl-10 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="comments"
            defaultValue={props.default?.comments}
            render={({ onChange, value, name }) => (
              <TextArea
                label="Comments"
                placeholder="Comments"
                error={errors.comments?.message}
                onChange={onChange}
                name={name}
                default={value}
                rows={4}
              />
            )}
          ></Controller>
        </div>
      </div>)
  }


  const renderSubmitButton = () => {
    return (<div className="button-outer">
      <Button type="submit" disabled={props.isSubmitting}>
        {`${selectedPlan ? `Pay $ ${selectedPlan.amount}` : 'Submit'}`}
      </Button>
    </div>)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row ml-5">
          {renderCategoryController()}
          {renderPostTypeController()}
          {renderPlanController()}
          {renderPaymentModeController()}
          {paymentMode === PaymentMode.Cheque && renderChequeDetailsController()}
          {renderCommentsController()}
          <div className="col-xl-5 col-sm-6 col-12">
            {renderErrorMessage()}
            {renderSubmitButton()}
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateSubscriptionForm;
