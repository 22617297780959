import React from 'react';
// import PropTypes from 'prop-types';

import './TextArea.scss';

interface CustomProps {
  default?: string;
  name?: string;
  placeholder?: string;
  label?: string;
  id?: string;
  gap?: 'sm' | 'md' | 'lg';
  size?: 'lg' | 'sm';
  noBorder?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: string;
  disabled?: boolean;
  stickyStyles?: any;
  onKeyPress?: (value: object) => void;
  rows?: number;
  autoresize?: boolean;
}

interface CustomSate {
  value?: string;
  rowlength?: number;
  height?: number;
}

class TextArea extends React.Component<CustomProps, CustomSate> {
  static defaultProps: Partial<CustomProps> = {
    default: '',
    name: '',
    label: '',
    placeholder: '',
    gap: 'md',
    onChange: () => {
      return;
    },
    error: '',
    size: 'sm',
    noBorder: false,
    disabled: false,
    stickyStyles: {},
    onKeyPress: () => {
      return;
    },
  };
  private textareaRef: React.RefObject<HTMLTextAreaElement>;
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.textareaRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.autoresize) {
      const textAreaHeight = this.textareaRef.current?.scrollHeight;
      this.setState({ height: textAreaHeight });
    }
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (
      this.props.default !== undefined &&
      prevProps.default !== this.props.default
    ) {
      this.updateDefault();
    }
  }

  updateDefault() {
    // console.log('2. this.props.default(TextInput): ', this.props.default);
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
      this.props.onChange(this.props.default);
    }
  }

  handleChange(e: { target: any }) {
    const { value } = e.target;
    this.setState({ value });
    this.props.onChange(value);
  }

  handleKeypress(ele: any) {
    if (this.props.autoresize) {
      ele.target.style.height = this.textareaRef.current?.scrollHeight;
      ele.target.style.height = `${ele.target.scrollHeight}px`;
    }
  }

  render() {
    const {
      size,
      noBorder,
      name,
      label,
      placeholder,
      gap,
      stickyStyles,
      error,
      disabled,
      onFocus,
      onBlur,
      id,
      rows,
    } = this.props;
    const extraClassesInput = [];
    if (size === 'lg') extraClassesInput.push('TextArea__field--lg');
    if (noBorder) extraClassesInput.push('TextArea__field--noborder');

    if (disabled) {
      extraClassesInput.push('TextArea__field--disabled');
    }
    const extraClasses: string = extraClassesInput.join(' ');
    const inputIdCandidate = id || name || label || placeholder || '';
    const inputId = inputIdCandidate.toLowerCase().split(' ').join('-');
    return (
      <div className={`TextArea TextArea--gap-${gap}`} style={stickyStyles}>
        {label && (
          <label className="TextArea__label" htmlFor={inputId}>
            {label}
          </label>
        )}
        <textarea
          id={inputId}
          className={`TextArea__field ${extraClasses}`}
          placeholder={placeholder}
          onChange={this.handleChange}
          value={this.state.value}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyPress={(e) => this.handleKeypress(e)}
          rows={rows}
          ref={this.textareaRef}
          style={{ height: this.state.height }}
        />
        {error && <span className="TextArea__error">{error}</span>}
      </div>
    );
  }
}

export default TextArea;
