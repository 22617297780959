import { ApiResponse } from '../models';
import { Product } from '../models/catalog';
import * as http from './http';
import ApiService from './service';

export default class PaymentService extends ApiService {
  public async getMyPayments(userId: string): Promise<any> {
    const url = `${this.apiDomain}/payment/my-payments/${userId}`;
    const response = await http.get(url, this.token);
    return response.data.data;
  }
}
