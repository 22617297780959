import { Dispatch } from 'redux';

import api from '../../api';
import { ApiPermissions, AllEntitiesResponse } from '../../api/models';

import { State } from '../interfaces';

const ACTION_LOAD_ENTITIES_REQUEST = 'ENTITIES/LOAD_ENTITIES_REQUEST';
const ACTION_LOAD_ENTITIES_SUCCESS = 'ENTITIES/LOAD_ENTITIES_SUCCESS';
const ACTION_LOAD_ENTITIES_ERROR = 'ENTITIES/LOAD_ENTITIES_ERROR';

interface LoadEntitiesRequestAction {
  type: typeof ACTION_LOAD_ENTITIES_REQUEST;
}

interface LoadEntitiesSuccessAction {
  type: typeof ACTION_LOAD_ENTITIES_SUCCESS;
  data?: AllEntitiesResponse;
}

interface LoadEntitiesErrorAction {
  type: typeof ACTION_LOAD_ENTITIES_ERROR;
}

type Actions =
  | LoadEntitiesRequestAction
  | LoadEntitiesSuccessAction
  | LoadEntitiesErrorAction;

export interface EntitiesState {
  apiRoles: { name: string }[];
  apiPermissions: ApiPermissions[];
  isRequesting?: boolean;
  isLoaded?: boolean;
}

const initialState: EntitiesState = {
  apiRoles: [],
  apiPermissions: [],
};

// reducer
export default (
  state: EntitiesState = initialState,
  action: Actions
): EntitiesState => {
  switch (action.type) {
    case ACTION_LOAD_ENTITIES_REQUEST:
      return {
        ...state,
        isRequesting: true,
        isLoaded: false,
      };
    case ACTION_LOAD_ENTITIES_ERROR:
      return {
        ...state,
        isLoaded: false,
        isRequesting: false,
      };
    case ACTION_LOAD_ENTITIES_SUCCESS:
      return {
        ...state,
        apiRoles: action.data?.apiRoles || state.apiRoles,
        apiPermissions: action.data?.apiPermissions || state.apiPermissions,
        isRequesting: false,
        isLoaded: true,
      };

    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  loadEntities: (refresh?: boolean) => async (
    dispatch: Dispatch,
    getState: () => State
  ) => {
    const state = getState();
    if (!refresh && state.entityState.isRequesting) {
      return;
    }
    dispatch({
      type: ACTION_LOAD_ENTITIES_REQUEST,
    });
    try {
      const response = await api.entity.loadAllEntities();

      dispatch({
        type: ACTION_LOAD_ENTITIES_SUCCESS,
        data: { apiPermissions: response },
      });
    } catch (error) {
      dispatch({
        type: ACTION_LOAD_ENTITIES_ERROR,
      });
    }
  },
};
