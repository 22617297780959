import React from 'react';
import { Dict } from '../../models';
import { FilterOptions } from '../../layout/crud/components/CrudListFilters';

interface CrudListFilterFieldProps<T = any> {
  label: string;
  field: string;
  children: React.ReactChild;
  onChange?: (value: T, options?: { noReload?: boolean }) => void;
  default?: T;
  relatedFieldValueProps?: Dict<(fields: Dict) => any>;
  childProps?: Dict;
  disableClose?: boolean;
  options?: FilterOptions;
}

const CrudListFilterField: React.SFC<CrudListFilterFieldProps> = (props) => {
  if (React.isValidElement(props.children)) {
    return React.cloneElement(props.children, {
      ...props.children.props,
      ...props.childProps,
      default: props.default,
      onChange: (_val: any) => {
        if (props.onChange) {
          props.onChange(_val);
        }
      },
    });
  }
  return <>{props.children}</>;
};

export default CrudListFilterField;
