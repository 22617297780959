import React, { useContext } from 'react';
import { DataTableFieldProps } from '../data-table';
import { TextView } from '../view-fields';
import { DataRecordContext, MetaDataContext } from '../../contexts';
import { resolveFieldValue } from '../utils';
import NumberView from '../view-fields/NumberView';

interface NumberDataFieldProps extends DataTableFieldProps<string> {
  bold?: boolean;
  fontLarge?: boolean;
  formatContent?: (value: any) => string;
  nullValue?: string;
}

const NumberDataField: React.SFC<NumberDataFieldProps> = (props) => {
  const recordDetails = useContext(DataRecordContext);
  const metaData = useContext(MetaDataContext);
  const value = resolveFieldValue(recordDetails, props, metaData);

  return <NumberView {...props} value={value}></NumberView>;
};

export default NumberDataField;
