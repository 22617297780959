import React from 'react';

export interface DataRecordContextInterface<T = any> {
  record?: T;
  recordId?: string;
  index?: number;
}

const DataRecordContext = React.createContext<DataRecordContextInterface>({});

export default DataRecordContext;
