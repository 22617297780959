import React from 'react';
import './Header.scss';
import { useSelector } from 'react-redux';
import { State } from '../../store/interfaces';
import HeaderUser from './HeaderUser';
import HeaderMenuToggle from './HeaderMenuToggle';
import Logo from '../logo';

interface HeaderProps {
  viewList?: boolean;
}

const Header: React.SFC<HeaderProps> = (props) => {
  const user = useSelector((store: State) => store.authUser.user);
  return (
    <div className="Header">
      <div className="HeaderRow">
        <HeaderMenuToggle />
        <div className="Header__item Header__logo">
          <Logo white />
        </div>
        {user && (
          <div className="Header__item Header__options">
            <div className="Header__options-row">
              <HeaderUser user={user} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
