import React, { useContext } from 'react';
import { PayoutStatus } from '../../../api/models/payout';
import { DataRecordContext, MetaDataContext } from '../../../contexts';
import { DataTableFieldProps } from '../../data-table';
interface Props extends DataTableFieldProps {
  addToApprovedList: any;
  selectedValues: any;
  checkbox?: any;
  allLoadedPayoutsLength?: any;
  allCheckBox?: boolean;
  payoutsTab?: PayoutStatus;
}
const Checkbox: React.SFC<Props> = (props) => {
  const metaData = useContext(MetaDataContext);
  const recordDetails = useContext(DataRecordContext);

  return (
    <>
      {recordDetails?.record?.status !== PayoutStatus.Processed &&
        props?.payoutsTab === PayoutStatus.Requested && (
          <>
            <input
              type="checkbox"
              id={recordDetails.recordId || 'check'}
              checked={
                (props.selectedValues?.includes(recordDetails.recordId) &&
                  !props?.allCheckBox) ||
                props?.allCheckBox
              }
              onChange={(e) => {
                props.addToApprovedList(
                  recordDetails?.recordId,
                  metaData?.resource
                );
              }}
            />
          </>
        )}
    </>
  );
};

export default Checkbox;
