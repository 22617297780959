import { MetaData } from '../../models';
import { RouteKeys } from './route-keys';

export const metaData: MetaData = {
  title: 'Subscription Custom Plan Requests',
  resource: 'admin-subscription/custom-plan-request',
  source: 'data.data',
  route: RouteKeys.List,
  paginate: true,
  perPage: 10,
  page: 1,
};
