import React from 'react';
import ReactDOM from 'react-dom';

import './Modal.scss';

interface ModalProps {
  pos?: 'center' | 'top';
  width?: string;
  onClose?: () => boolean;
  isOpen?: boolean;
}

class Modal extends React.Component<ModalProps> {
  el: {
    modal: HTMLDivElement;
    modalOverlay: HTMLDivElement;
    modalFrame: HTMLDivElement;
    modalClose: HTMLSpanElement | null;
    modalContent: HTMLDivElement;
  };
  constructor(props: ModalProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.el = buildModal({
      width: props.width,
      onClose: props.onClose,
      pos: props.pos,
    });
  }

  componentDidMount() {
    document.body.append(this.el.modal);
    if (this.el.modalClose) {
      this.el.modalClose.addEventListener('click', this.closeModal);
    }
    this.el.modal.style.display = this.props.isOpen ? 'block' : 'none';
  }

  componentDidUpdate(prevProps: ModalProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.el.modal.style.display = this.props.isOpen ? 'block' : 'none';
    }
  }

  componentWillUnmount() {
    // console.log('unmounting...');
    if (this.el.modalClose) {
      this.el.modalClose.removeEventListener('click', this.closeModal);
    }
    // this.el.modal.remove()
    document.body.removeChild(this.el.modal);
  }

  closeModal() {
    if (this.props.onClose) {
      const shouldClose = this.props.onClose();
      if (shouldClose) this.el.modal.style.display = 'none';
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el.modalContent);
  }
}

export default Modal;

function buildModal(
  options: ModalProps = {}
): {
  modal: HTMLDivElement;
  modalOverlay: HTMLDivElement;
  modalFrame: HTMLDivElement;
  modalClose: HTMLSpanElement | null;
  modalContent: HTMLDivElement;
} {
  const modal = document.createElement('div');
  modal.className = 'Modal';
  // overlay
  const modalOverlay = document.createElement('div');
  modalOverlay.className = 'Modal__overlay';
  // frame
  const modalFrame = document.createElement('div');
  modalFrame.className = `Modal__frame Modal__frame--${
    options.pos || 'center'
  }`;
  if (options.width) {
    modalFrame.style.maxWidth = `${options.width}px`;
  }
  // content
  const modalContent = document.createElement('div');
  modalContent.className = 'Modal__content';
  // close
  let modalClose = null;
  if (options.onClose) {
    modalClose = document.createElement('span');
    modalClose.className = 'Modal__close';
    const modalCloseIcon = document.createElement('i');
    modalCloseIcon.className = 'icon-cancel';
    modalClose.appendChild(modalCloseIcon);
    modalFrame.appendChild(modalClose);
  }
  // frame children
  modalFrame.appendChild(modalContent);
  // modal children
  modal.appendChild(modalOverlay);
  modal.appendChild(modalFrame);

  return {
    modal,
    modalOverlay,
    modalFrame,
    modalClose,
    modalContent,
  };
}
