import React from 'react';

interface TextViewProps<T = any> {
  value?: string;
  bold?: boolean;
  fontLarge?: boolean;
  formatContent?: (value: T) => string;
  nullValue?: string;
}

const TextView: React.SFC<TextViewProps> = (props) => {
  const formatText = (value: any) => {
    if (!value) {
      return props.nullValue || '';
    }

    if (props.formatContent) {
      return props.formatContent(value);
    }
    return value.toString();
  };

  const textValue = formatText(props.value);
  if (props.bold) {
    return <span className="DataTable__item--bold">{textValue}</span>;
  }
  if (props.fontLarge) {
    return <span className="DataTable__item--bold h6 m-0">{textValue}</span>;
  }

  return <span>{textValue}</span>;
};

export default TextView;
