import React, { useContext } from 'react';
import { CrudListDataLoaderContext } from '../../../data-loader-components/CrudListDataLoader';
import Search from '../../../components/search';
import { MetaDataContext } from '../../../contexts';

interface CrudListSearchProps {
  field?: string;
}

const CrudListSearch: React.SFC<CrudListSearchProps> = (props) => {
  const crudListContext = useContext(CrudListDataLoaderContext);
  const metaDataContext = useContext(MetaDataContext);

  const field = props.field || 'query';

  const onSearch = (value?: string) => {
    if (value) {
      crudListContext?.setCrudQueryParams({ [field]: value });
    } else {
      crudListContext?.removeCrudQueryParams(field);
    }
  };

  return (
    <Search
      direction="right"
      onSearch={onSearch}
      placeHolder={`${
        metaDataContext?.title
          ? `Search in ${metaDataContext?.title}`
          : 'Search...'
      }`}
    />
  );
};

export default CrudListSearch;
