import { PhoneNumberUtil } from 'google-libphonenumber';

export const ValidatePhoneNumber = (phoneNumber: string) => {
  phoneNumber = phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`;
  let valid = false;

  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
  } catch (e) {
    valid = false;
  }
  return valid;
};

export const ValidateEmail = (mail: string) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }

  return false;
};

export const CheckPhoneNumberEmpty = (phoneNumber: string) => {
  let valid = false;
  phoneNumber = phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const countryCode = phoneUtil.parse(phoneNumber).getCountryCode();

    valid = phoneNumber.replace(countryCode ? countryCode.toString() : '', '')
      ? true
      : false;
  } catch (e) {
    valid = false;
  }
  return valid;
};

export const ValidatePhoneNumberRegEx = (phoneNumber: string) => {
  phoneNumber = phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`;
  let phoneLength = 0;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();

    const countryCode = phoneUtil.parse(phoneNumber).getCountryCode();
    phoneLength = phoneNumber.replace(
      countryCode ? `+${countryCode.toString()}` : '',
      ''
    )?.length;
  } catch (e) {
    return false;
  }

  if (/^\+[1-9]{1}[0-9]{6,14}$/.test(phoneNumber) && phoneLength > 6) {
    return true;
  }

  return false;
};

export const ValidateString = (value: string) => {
  if (/^[A-Za-z. ]+$/.test(value?.trim())) {
    return true;
  }

  return false;
};

export const ValidateNumber = (phoneNumber: string) => {
  phoneNumber = phoneNumber.includes('+')
    ? phoneNumber.replace('+', '')
    : phoneNumber;
  if (/^\d{7,16}$/.test(phoneNumber)) {
    return true;
  }

  return false;
};
