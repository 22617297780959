import { AccountType } from './user';
export interface SubscriptionPlan {
  _id: string;
  name: string;
  postType: PostType;
  accountType: AccountType;
  noOfPosts: number;
  amount: number;
  listingDays: number;
  isRecurring?: boolean;
  period?: SubscriptionPeriod;
  isCustom?: boolean;
  isBoostBackToTop?: boolean;
  isBasic?: boolean;
  isTopFeatured?: boolean;
  categories?: string[];
  subCategories?: string[];
  productType: ProductType;
  planType?: string;
}

export interface SubscriptionPlanCategoryUpdateData {
  categories?: string[];
  subCategories?: string[];
}

export enum SubscriptionPeriod {
  Monthly = 'Monthly',
}

export enum PostType {
  City = 'City Post',
  National = 'National Post',
}

export enum ProductType {
  Auction = 'auction',
  Sale = 'sale',
  Lead = 'lead',
  Branding = 'branding',
}
