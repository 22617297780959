import React from 'react';
import {
  CreateActionButton,
  StatusActionButton,
} from '../../components/actions/action-buttons';
import {
  ActionsDataField,
  CrudListFilterField,
  LinkField,
  TextDataField,
} from '../../components/data-fields';
import { TextView } from '../../components/view-fields';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { metaData } from './metaData';
import EditCategoryAction from './components/EditCategoryAction';
import SelectInput from '../../components/form-group/SelectInput';
import CrudListFilters from '../../layout/crud/components/CrudListFilters';
import { getEnumValues } from '../../utils/enum-utils';
import { AccountType, PostType } from '../../api/models';
import CrudListSearch from '../../layout/crud/components/CrudListSearch';

const ListView: React.FunctionComponent = () => {
  const accountTypes = getEnumValues(AccountType).map((accountType: string) => {
    return { _id: accountType, title: accountType };
  });
  accountTypes.unshift({ _id: '', title: 'Select' });

  const postTypes = getEnumValues(PostType).map((postType: string) => {
    return { _id: postType, title: postType };
  });
  postTypes.unshift({ _id: '', title: 'Select' });

  const filters: React.ReactNode[] = [
    <CrudListFilterField field="categoryId" label="Category">
      <SelectInput api="category" optionLabel="title" optionValue="_id" />
    </CrudListFilterField>,
    <CrudListFilterField field="accountType" label="Account Type">
      <SelectInput data={accountTypes} optionLabel="title" optionValue="_id" />
    </CrudListFilterField>,
    <CrudListFilterField field="postType" label="Post Type">
      <SelectInput data={postTypes} optionLabel="title" optionValue="_id" />
    </CrudListFilterField>,
    <CrudListFilterField field="isBoostBackToTop" label="Boost Back To Top">
      <SelectInput
        data={[
          { _id: '', title: 'Select' },
          { _id: true, title: 'Yes' },
        ]}
        optionLabel="title"
        optionValue="_id"
      />
    </CrudListFilterField>,
    <CrudListFilterField field="isTopFeatured" label="Top Featured">
      <SelectInput
        data={[
          { _id: '', title: 'Select' },
          { _id: true, title: 'Yes' },
        ]}
        optionLabel="title"
        optionValue="_id"
      />
    </CrudListFilterField>,
    <CrudListFilterField field="isCustom" label="Custom">
      <SelectInput
        data={[
          { _id: '', title: 'Select' },
          { _id: true, title: 'Yes' },
        ]}
        optionLabel="title"
        optionValue="_id"
      />
    </CrudListFilterField>,
  ];

  return (
    <PlainLayout>
      <CrudListView
        metaData={metaData}
        actions={[
          <CrudListSearch />,
          <CreateActionButton />,
          <CrudListFilters queryFilters>{filters}</CrudListFilters>,
        ]}
      >
        <LinkField source="name" label="Name">
          <TextView bold />
        </LinkField>
        <TextDataField
          source="accountType"
          label="Account Type"
        ></TextDataField>
        <TextDataField
          source="productType"
          label="Product Type"
        ></TextDataField>
        <TextDataField source="postType" label="Post Type"></TextDataField>
        <TextDataField source="noOfPosts" label="No.Of Posts"></TextDataField>
        <TextDataField source="amount" label="Amount"></TextDataField>
        <TextDataField
          source="listingDays"
          label="Listing Days"
        ></TextDataField>
        <TextDataField
          source="isCustom"
          label="Custom"
          formatContent={(val: boolean) => (val ? 'Yes' : '-')}
          nullValue="-"
        ></TextDataField>
        <TextDataField
          source="isBoostBackToTop"
          label="Boost Back To Top"
          formatContent={(val: boolean) => (val ? 'Yes' : '-')}
          nullValue="-"
        ></TextDataField>
        <TextDataField
          source="isTopFeatured"
          label="Top Featured"
          formatContent={(val: boolean) => (val ? 'Yes' : '-')}
          nullValue="-"
        ></TextDataField>
        <StatusActionButton source="isActive" label="Status" />
        {/* <ActionsDataField actionsColumn>
          <EditCategoryAction></EditCategoryAction>
        </ActionsDataField> */}
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
