import React from 'react';

import './FilterDropdown.scss';
import '../dropdown/Dropdown.scss';

interface FilterDropdownProps {
  label?: string;
  onClick: () => void;
}

const FilterDropdown: React.SFC<FilterDropdownProps> = (props) => {
  if (props.label) {
    return (
      <div className="labelled-dropdown">
        <label className="label">{props.label}:&nbsp;</label>
        <div role="presentation" className="dropdown" onClick={props.onClick}>
          {props.children}
        </div>
      </div>
    );
  }

  return (
    <div role="presentation" className="dropdown" onClick={props.onClick}>
      {props.children}
    </div>
  );
};

interface FilterDropdownSelectProps {
  cueType: 'up' | 'down';
  hideCue?: boolean;
  theme?: 'primary' | 'secondary'| 'filled';

}

const FilterDropdownSelect: React.SFC<FilterDropdownSelectProps> = (props) => {
  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
  };
  // const cueIcon = props.cueType !== 'filter' ? 'menu-up' : 'filter';

  return (
    <button
      className={`dropdown__item dropdown__item-selected dropdown--theme-${
        props.theme || 'filled'
      }`}
      onClick={clickHandler}
    >
      <i className="font-large icon-filter mr-2"></i>
      {props.children}
    </button>
  );
};

const FilterDropdownMenu: React.SFC = (props) => {
  return <div className="filter-dropdown">{props.children}</div>;
};

interface FilterDropdownItemProps {
  isOpen: boolean;
  label: string;
  onClick: () => void;
}

const FilterDropdownItem: React.SFC<FilterDropdownItemProps> = (props) => {
  const { isOpen } = props;
  const openClass = isOpen ? 'icon-minus' : 'icon-plus';
  const displayClass = isOpen ? '' : 'd-none';
  return (
    <div className="filter-accordion__item">
      <div className="filter-accordion__head">
        <div className="filter-accordion__head-left">{props.label}</div>
        <div className="filter-accordion__head-right">
          <button
            className="dropdown__item"
            type="button"
            onClick={() => props.onClick()}
          >
            <i className={`${openClass}`} />
          </button>
        </div>
      </div>
      <div className={`filter-accordion__body ${displayClass}`}>
        <div className="filter-search">
          <button className="dropdown__item w-100" type="button">
            {props.children}
          </button>
        </div>
      </div>
    </div>
  );
};

export {
  FilterDropdown,
  FilterDropdownSelect,
  FilterDropdownMenu,
  FilterDropdownItem,
};
export default {
  FilterDropdown,
  FilterDropdownSelect,
  FilterDropdownMenu,
  FilterDropdownItem,
};
