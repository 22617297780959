import React from 'react';

export const TableBody: React.SFC = (props) => {
  return <tbody>{props.children}</tbody>;
};

export const TableBodyRow: React.SFC = (props) => {
  return <tr>{props.children}</tr>;
};

interface TableBodyItemProps {
  dataTitle?: string;
  actionsColumn?: boolean;
}

export const TableBodyItem: React.SFC<TableBodyItemProps> = (props) => {
  const classes = [];
  if (props.actionsColumn) {
    classes.push('DataTable__item--actions');
  }
  return (
    <td className={classes.join(' ')} data-title={props.dataTitle}>
      {props.children}
    </td>
  );
};
