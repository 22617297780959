import React, { Component } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import './SelectPhoneInput.scss';

interface CustomProps {
  default?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  error?: string;
  id?: string;
  name?: string;
  label?: string;
  gap?: 'sm' | 'md' | 'lg';
  showLoading?: boolean;
  stickyStyles?: any;
  disabled?: boolean;
}
interface CustomState {
  value: string;
}

class SelectPhoneInput extends Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    default: '',
    placeholder: '',
    onChange: () => {
      return;
    },
    error: '',
    name: '',
    label: '',
    gap: 'md',
    stickyStyles: {},
  };

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default || '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.default && this.state.value !== this.props.default) {
      this.setState({
        value: this.props.default ? this.props.default : '',
      });
    }
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (this.props.default && prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
    }
  }

  handleChange(value: string) {
    value = value || '';
    this.setState({ value });
    this.props.onChange(value);
  }

  render() {
    const {
      placeholder,
      error,
      id,
      name,
      label,
      gap,
      showLoading,
      stickyStyles,
      disabled,
    } = this.props;
    const inputIdCandidate = id || name || label || placeholder || '';
    const inputId = inputIdCandidate.toLowerCase().split(' ').join('-');
    return (
      <div className={`Input Input--gap-${gap}`} style={stickyStyles}>
        {label && (
          <label htmlFor={inputId} className="Input__label Input__label--full">
            {label}
          </label>
        )}
        <PhoneInput
          international
          // defaultCountry="IN"
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.handleChange}
          style={stickyStyles}
          numberInputProps={{ className: 'Input__field' }}
          disabled={disabled}
        />

        {showLoading && (
          <div className="InputLoading">
            <span className="InputLoading__dot InputLoading__dotF"></span>
            <span className="InputLoading__dot InputLoading__dotM"></span>
            <span className="InputLoading__dot InputLoading__dotL"></span>
          </div>
        )}
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

export default SelectPhoneInput;
