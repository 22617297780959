import React from 'react';
import { classNames } from 'react-select/src/utils';
import { OrderStatus } from '../../../../api/models/catalog';
import classnames from 'classnames';

const ProductStatus: React.FunctionComponent<{ status: OrderStatus }> = (
  props
) => {
  const className = classnames({
    'status font-xxs': true,
    'complete text-success':
      props.status === OrderStatus.Sold || props.status === OrderStatus.Pending,
    // 'complete text-success': props.status === OrderStatus.Pending,
  });
  const status =
    props.status === OrderStatus.OfferAccepted
      ? 'Offer Accepted'
      : props.status === OrderStatus.Sold
      ? 'Payment Recieved'
      : props.status.charAt(0).toUpperCase() + props.status.slice(1);
  return <span className={className}>{status}</span>;
};
export default ProductStatus;
