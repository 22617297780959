import React from 'react';
import { Link } from 'react-router-dom';

import './ClickLink.scss';

interface ClickLinkProps {
  size?: 'small' | 'medium' | 'large';
  link?: string;
  onClick?: () => void;
  underline?: boolean;
  type?: 'button' | 'submit';
  className?: string;
}

const ClickLink: React.SFC<ClickLinkProps> = (props) => {
  const classes = ['ClickLink', `ClickLink--${props.size || 'medium'}`];
  if (props.underline) {
    classes.push(`ClickLink--underline`);
  }
  if (props.className) {
    classes.push(props.className);
  }

  if (props.link) {
    return (
      <Link className={classes.join(' ')} to={props.link}>
        {props.children}
      </Link>
    );
  }

  return (
    <button
      className={classes.join(' ')}
      onClick={props.onClick}
      type={props.type || 'button'}
    >
      {props.children}
    </button>
  );
};

export default ClickLink;
