import React from 'react';
import ClickLink from '../../components/click-link';
import PlainLayout from '../../layout/PlainLayout';
import { metaData } from './metaData';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';
import CrudTabularDetailsView from '../../layout/crud/CrudTabularDetailsView';
import {
  CreateActionButton,
  DeleteActionButton,
  EditActionButton,
} from '../../components/actions/action-buttons';
import { ArrayDataField, TextDataField } from '../../components/data-fields';
import './DetailsView.scss';
import {
  OrderStatusSyllables,
  Product,
  ProductDetails,
} from '../../api/models/catalog';
import ProductChart from '../dashboard/ProductsChart';
import { AvatarView } from '../../components/avatar';
import format from 'date-fns/format';
const EditView: React.FunctionComponent = (props) => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data, loading, loaded } = useDataLoader<{ data: ProductDetails }>(
    () =>
      api.crud.get({
        resource: metaData.resource,
        resourceId: params.id,
      })
  );

  const renderImages = () => {
    return data.data.images?.map((image: any) => {
      return (
        <div className="col-auto mb-2">
          <figure>
            <img src={image.path} alt={data.data.title} />
          </figure>
        </div>
      );
    });
  };
  const renderAttributes = () => {
    return data.data.subcategory?.properties?.map((kf) => {
      const value =
        data.data?.[kf.key as keyof ProductDetails] ||
        data.data?.customAttributes?.[kf.key];
      return (
        <>
          <div className="col-6 col-md-4 mb-3 text-muted">{kf.label}</div>
          <div className="col-6 col-md-8 mb-3">{value || '-'}</div>
        </>
      );
    });
  };
  return (
    <PlainLayout>
      {loaded && !loading && (
        <>
          <h5 className="ItemSummary__item d-flex justify-content-between align-items-center">
            {data.data.title}{' '}
            <ClickLink link={metaData.route}>
              <i className="icon-arrow-left mr-2"></i>Back
            </ClickLink>
          </h5>
          <div className="py-4">
            <div className="row">
              <div className="col-auto">
                <h6 className="mb-1">Location</h6>
                <div
                  className="text-muted font-small"
                  style={{ maxWidth: '300px' }}
                >
                  {data.data.location.address}
                </div>
              </div>
              <div className="pb-3 col-12 col-md-6 col-xl-4 ">
                <div className="row">
                  <div className="col-col-6 col-md-4 mb-3 text-mutedauto mb-3 ">
                    <span className="text-muted font-small">Price</span>
                    <h5 className="h6 mb-0">
                      {data.data.currency || '$'}
                      {data.data.price}
                    </h5>
                  </div>
                  <div className="col-col-6 col-md-4 mb-3 text-mutedauto mb-3 ">
                    <span className="text-muted font-small">Category </span>
                    <h5 className="font-medium mb-0">
                      {data.data.category?.title}
                    </h5>
                  </div>
                  <div className="col-col-6 col-md-4 mb-3 text-mutedauto mb-3 ">
                    <span className="text-muted font-small">Sub Category </span>
                    <h5 className="font-medium mb-0">
                      {data.data.subcategory?.title}
                    </h5>
                  </div>
                </div>
              </div>
             
            </div>
            <div className="row font-small">
              <div className="pt-4 pb-3 col-12 col-md-6 col-xl-4 ">
                <h6 className="font-base mb-3 ">Product Details</h6>
                <div className="row">
                  <div className="col-6 col-md-4 mb-3 text-muted">Brand</div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data.brand?.title}{' '}
                  </div>
                  {renderAttributes()}

                  <div className="col-6 col-md-4 mb-3 text-muted">
                    ProductType
                  </div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data.productType}{' '}
                  </div>

                  <div className="col-6 col-md-4 mb-3 text-muted">
                    Active Till
                  </div>
                  <div className="col-6 col-md-8 mb-3">
                    {format(new Date(data.data.validTill!), 'dd/MMM/yyyy')}
                  </div>

                  <div className="col-6 col-md-4 mb-3 text-muted">
                    Order Status
                  </div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data?.orderStatus ? (
                      <span
                        className={`status ${
                          OrderStatusSyllables?.[data.data?.orderStatus]?.class
                        }`}
                      >
                        {OrderStatusSyllables?.[data.data?.orderStatus]?.label}
                      </span>
                    ) : (
                      ''
                    )}{' '}
                  </div>
                </div>
              </div>
              <div className="pt-4 pb-3 col-12 col-md-6 col-xl-4 ">
                <h6 className="font-base mb-3 ">Posted User</h6>
                <div className="row font-small">
                  <div className="col-6 col-md-4 mb-3 text-muted">Name</div>
                  <div className="col-6 col-md-8 mb-3">
                    {/* <AvatarView
                    size="large"
                    src={data.data.createdBy?.avatar.path}
                  /> */}
                    {data.data.createdBy.name}
                  </div>

                  <div className="col-6 col-md-4 mb-3 text-muted">Email</div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data.createdBy.email}
                  </div>

                  <div className="col-6 col-md-4 mb-3 text-muted">Phone</div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data.createdBy.phone}
                  </div>
                </div>
              </div>
              <div className="pt-4 pb-3 col-12 col-md-6 col-xl-4 ">
                <h6 className="font-base mb-3 ">Plan Details</h6>
                <div className="row font-small">
                  <div className="col-6 col-md-4 mb-3 text-muted">Name</div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data.subscriptionPlan?.name}
                  </div>

                  <div className="col-6 col-md-4 mb-3 text-muted">
                    AccountType
                  </div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data.subscriptionPlan?.accountType}
                  </div>

                  <div className="col-6 col-md-4 mb-3 text-muted">Amount</div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data.subscriptionPlan?.amount}
                  </div>

                  <div className="col-6 col-md-4 mb-3 text-muted">
                    No. of Posts
                  </div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data.subscriptionPlan?.noOfPosts}
                  </div>

                  <div className="col-6 col-md-4 mb-3 text-muted">Period</div>
                  <div className="col-6 col-md-8 mb-3">
                    {data.data.subscriptionPlan?.period}
                  </div>
                </div>
              </div>
              <div className="pt-4 pb-3 col-12 col-md-6 col-xl-4 ">
                <h6 className="font-base mb-3 ">Description</h6>
                <div className="text-muted font-small mb-3">
                  {data.data.description}
                </div>
              </div>{' '}
              <div className="pt-4 pb-3 col-12 col-md-6 col-xl-4 ">
                <h6 className="font-base mb-1">Product Images</h6>
                <div className="row gutter-6 img-listing mb-2">
                  {renderImages()}{' '}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </PlainLayout>
  );
};

export default EditView;
