import React, { useEffect, useState } from 'react';
import { ToolBar } from '../../../components/toolbar';
import { MetaData } from '../../../models/meta-data';
import { CrudListDataLoaderContextInterface } from '../../../data-loader-components/CrudListDataLoader';
import { ContainerLoader } from '../../../components/loader';
import { Dict } from '../../../models';
import CrudListBaseView from './CrudListBaseView';
import { PayoutStatus } from '../../../api/models/payout';

interface CrudListBaseViewWithToolbarProps {
  metaData: MetaData;
  actions?: React.ReactNode[];
  defaultFilters?: Dict;
  queryParams?: Dict;
  getAllPayoutIds?: any;
  payoutFilter?: string;
}

const CrudListBaseViewWithToolbar: React.SFC<CrudListBaseViewWithToolbarProps> = (
  props
) => {
  const [checkedPayoutList, setCheckedPayoutList] = useState<any>([]);
  useEffect(() => {
    if (props.getAllPayoutIds) {
      props.getAllPayoutIds(checkedPayoutList);
    }
  }, [checkedPayoutList]);
  const renderContent = (
    context: CrudListDataLoaderContextInterface
  ): React.ReactNode => {
    if (props?.payoutFilter) {
      let filteredList: any[] = [];
      if (context?.list?.length) {
        context?.list?.map((item) => {
          filteredList.push(...item?.['requestedData']);
        });
        setCheckedPayoutList(context.list);
      }
    }

    return (
      context.list &&
      React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          let newList: any[] = [];
          if (props?.payoutFilter) {
            let key: string;
            if (props?.payoutFilter === PayoutStatus.Processed) {
              key = 'approvedData';
            } else {
              key = 'requestedData';
            }

            context.list?.map((item) => {
              newList.push(...item?.[key]);
            });
          }
          return React.cloneElement(child, {
            ...child.props,
            list: props?.payoutFilter ? newList || [] : context?.list || [],
            onSort: (sortField: string, sortAsc?: boolean) =>
              context.onSortField(sortField, sortAsc ? 'ASC' : 'DESC'),
          });
        }
        return child;
      })
    );
  };

  const renderToolbar = (
    context: CrudListDataLoaderContextInterface
  ): React.ReactNode => {
    return (
      <ToolBar
        title={props.metaData.title}
        headers={props.metaData.headers}
        data={context.data}
        count={context.data?.count}
        actions={props.actions}
      />
    );
  };

  const renderLoader = (
    context: CrudListDataLoaderContextInterface
  ): React.ReactNode => {
    return (
      context.loading && (
        <ContainerLoader text="loading..." height={100}></ContainerLoader>
      )
    );
  };
  const renderErrorMessage = (
    context: CrudListDataLoaderContextInterface
  ): React.ReactNode => {
    return (
      !context.loading &&
      context.error && (
        <span className="text-center p-3 d-block">{context.error.message}</span>
      )
    );
  };

  const renderEmptyView = (
    context: CrudListDataLoaderContextInterface
  ): React.ReactNode => {
    return (
      !context.loading &&
      (!context.list || !context.list.length) &&
      !context.error && (
        <span className="text-center p-3 d-block">No data found</span>
      )
    );
  };

  return (
    <CrudListBaseView
      metaData={props.metaData}
      defaultFilters={props.defaultFilters}
      queryParams={props.queryParams}
    >
      {(context: CrudListDataLoaderContextInterface) => {
        return (
          <>
            {renderToolbar(context)}
            {renderContent(context)}
            {renderLoader(context)}
            {renderErrorMessage(context)}
            {renderEmptyView(context)}
          </>
        );
      }}
    </CrudListBaseView>
  );
};

export default CrudListBaseViewWithToolbar;
