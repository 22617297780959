import { AccountType } from './user';
import { PostType, SubscriptionPeriod } from './subscription-plan';
import { PaymentMode } from './payment';
import { Category } from './category';

export interface CustomSubscription {
  _id: string;
  planName: string;
  postType: PostType;
  accountType: AccountType;
  noOfPosts: number;
  amount: number;
  listingDays: number;
  isRecurring?: boolean;
  period?: SubscriptionPeriod;
  isCustom?: boolean;
}

export interface ActiveSubscription {
  _id: string;
  planName: string;
  postType: PostType;
  accountType: AccountType;
  noOfPosts: number;
  amount: number;
  listingDays: number;
  isRecurring?: boolean;
  period?: SubscriptionPeriod;
  isCustom?: boolean;
}

export interface Subscribe {
  subscriptionPlan: string;
  paymentMode: PaymentMode;
  categoryId: string;
  comments: string;
  chequeDetails: string;
  postType?: PostType;
  userId: string;
}
export interface Subscription {
  _id: string;
  amount: number;
  accountType: AccountType;
  status: SubscriptionStatus;
  noOfPostsRemaining?: number;
  postType?: PostType;
  noOfPosts?: number;
  listingDays?: number;
  isRecurring?: boolean;
  expireOn?: Date;
  isCustom?: boolean;
  period?: SubscriptionPeriod;
  planName?: string;
  user: string;
  subscriptionPlan: string;
  paymentId?: string;
  products?: string[];
  isBoostBackToTop?: boolean;
  isTopFeatured?: boolean;
  paymentType: string;
  categories?: Category[];
}
export enum SubscriptionStatus {
  CustomRequestPending = 'custom-request-pending',
  CustomPlanGenerated = 'custom-plan-generated',
  PaymentPending = 'payment-pending',
  PaymetSuccess = 'payment-success',
  PaymentFailed = 'payment-failed',
  PaymentCancelled = 'payment-cancelled',
}
