import React, { useContext } from 'react';
import { DataTableFieldProps } from '../data-table';
import { TextView } from '../view-fields';
import { DataRecordContext, MetaDataContext } from '../../contexts';
import { resolveFieldValue } from '../utils';

interface TextDataFieldProps extends DataTableFieldProps<string> {
  bold?: boolean;
  fontLarge?:boolean;
  formatContent?: (value: any) => string;
  nullValue?: string;
}

const TextDataField: React.SFC<TextDataFieldProps> = (props) => {
  const recordDetails = useContext(DataRecordContext);
  const metaData = useContext(MetaDataContext);
  const value = resolveFieldValue(recordDetails, props, metaData);

  return <TextView {...props} value={value}></TextView>;
};

export default TextDataField;
