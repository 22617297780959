import { stringify } from 'query-string';
import { store } from '../../store';
import {
  CrudDetailsRequestModel,
  CrudListRequestModel,
  CrudRequestModel,
} from '../models';
import * as http from './http';
import ApiService from './service';

export default class CrudService extends ApiService {
  public async getList(request: CrudListRequestModel) {
    const { page = 1, perPage = 10 } = request.pagination || {};
    const { field, order } = request.sort || {};
    const query = {
      _filters: JSON.stringify(request.filter),
      _sort: field,
      _order: order,
      page: request.noPagination ? undefined : page,
      perPage: request.noPagination ? undefined : perPage,
      ...request.queryParams,
    };
    const url = `${this.apiDomain}/${request.resource}?${stringify(query)}`;

    const response = await http.get(url, this.token);

    return { data: response.data, count: response.data?.count };
  }
  public async getWithOutId(request: CrudRequestModel) {
    const url = `${this.apiDomain}/${request.resource}`;
    const response = await http.get(url, this.token);
    return response.data.data;
  }

  public async create(request: CrudRequestModel, data: any) {
    const url = `${this.apiDomain}/${request.resource}`;
    return http.post(url, data, this.token);
  }

  public async postList(
    request: CrudRequestModel,
    data: any,
    endpoint?: string
  ) {
    const url = `${this.apiDomain}/${request.resource}/${endpoint}`;
    return http.post(url, data, this.token);
  }

  public async get(request: CrudDetailsRequestModel) {
    const url = `${this.apiDomain}/${request.resource}/${request.resourceId}`;
    const response = await http.get(url, this.token);
    return response.data;
  }

  public async update(
    request: CrudDetailsRequestModel,
    data: any,
    type?: string
  ) {
    let url = `${this.apiDomain}/${request.resource}/${request.resourceId}`;
    if (type) {
      url = `${this.apiDomain}/${request.resource}/${request.resourceId}/${type}`;
    }
    return http.put(url, data, this.token);
  }

  public async remove(request: CrudDetailsRequestModel) {
    const url = `${this.apiDomain}/${request.resource}/${request.resourceId}`;
    return http.remove(url, this.token);
  }

  public async download(
    request: CrudDetailsRequestModel,
    data: any,
    endpoint: string,
    filename: string
  ) {
    const url = `${this.apiDomain}/${request.resource}/${endpoint}`;
    const reponse = await http.download(url, data, this.token);
    http.saveDownloadedFile(
      reponse.data,
      'application/application/vnd.openxmlformats',
      filename
    );
    return;
  }
  public async getListWithoutPagination(request: CrudListRequestModel) {
    const { field, order } = request.sort || {};
    const query = {
      _filters: JSON.stringify(request.filter),
      _sort: field,
      _order: order,
      ...request.queryParams,
    };
    const state = store.getState();

    const url = `${this.apiDomain}/${request.resource}?${stringify(query)}`;

    const response = await http.get(url, state.authUser.token);

    return { data: response.data, count: response.data?.count };
  }
}
