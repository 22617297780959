import React from 'react';
import './EditorInput.scss';
import { Editor } from '@tinymce/tinymce-react';

interface CustomProps {
  default?: string;
  heading?: string;
  label?: string;
  theme?: 'minimal' | 'full';
  height?: string;
  name?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string;
}

interface CustomSate {
  value?: string;
}

class EditorInput extends React.Component<CustomProps, CustomSate> {
  static defaultProps: Partial<CustomProps> = {
    default: '',
    heading: '',
    label: '',
    theme: 'minimal',
    height: '250',
    name: '',
    onChange: () => {
      return;
    },
    disabled: false,
    error: '',
  };

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (this.props.default && prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
      this.props.onChange(this.props.default);
    }
  }

  handleChange(value: string) {
    this.props.onChange(value);
  }

  editorTheme() {
    const { theme, height } = this.props;
    // console.log('editor height',height);
    const fullTheme = {
      height,
      menubar: true,
      branding: false,
      statusbar: false,
      plugins: ['lists link image paste help wordcount code'],
      toolbar:
        'undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat ',
      // automatic_uploads: true,
      // images_upload_url: 'upload_php_file.php',
      // images_reuse_filename: true,
    };

    const minTheme = {
      height,
      menubar: false,
      branding: false,
      statusbar: false,
      plugins: ['lists link image paste help wordcount code'],
      toolbar:
        'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat ',
      // automatic_uploads: true,
      // images_upload_url: 'upload_php_file.php',
      // images_reuse_filename: true,
    };
    switch (theme) {
      case 'minimal':
        return minTheme;
      case 'full':
        return fullTheme;
      default:
        return fullTheme;
    }
  }

  render() {
    const { props } = this;
    return (
      <div className="EditorInput">
        {props.heading && <h3 className="text-center"> {props.heading}</h3>}
        {props.label && (
          <label className="Input__label" htmlFor={props.name}>
            {props.label}
          </label>
        )}
        <div
          style={{
            padding: 10,
          }}
        >
          <Editor
            apiKey="262zo6r213jtl10ik6fymeqjostmsrtrq1e2y9xpbkxafbrr"
            init={this.editorTheme()}
            textareaName={props.name}
            onEditorChange={this.handleChange}
            initialValue={this.state.value}
            value={this.state.value}
            disabled={this.props.disabled}
          />
          {props.error && <span className="Input__error">{props.error}</span>}
        </div>
      </div>
    );
  }
}

export default EditorInput;
