// import * as UserApi from '../api/user';

// import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';

import api from '../../api';
import { AuthUser } from '../../api/models';
import { Dict } from '../../models';
import { types as commonTypes } from './common';

// action types
export const types = {
  // login
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_ERROR: 'AUTH/LOGIN_ERROR',

  // logout
  LOGOUT: 'AUTH/LOGOUT',
  // redirect
  REDIRECT_SAVE: 'AUTH/REDIRECT_SAVE',
  REDIRECT_APPLY: 'AUTH/REDIRECT_APPLY',
};

export interface AuthState {
  isLoading: boolean;
  token?: string;
  redirectTo: string;
  email?: string;
  loginError?: string;
  apiPermissions?: Dict;
  user?: AuthUser;
}

interface ForgotPasswordState {
  id?: string;
  error?: string;
}

// initial state
const initialState: AuthState = {
  isLoading: false,
  redirectTo: '/',
};

// reducer
export default (state: AuthState = initialState, action: any): AuthState => {
  switch (action.type) {
    // login
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        email: action.data ? action.data.phoneNumber : state.email,
        loginError: undefined,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.data.token,
        loginError: undefined,
        user: action.data.user,
        apiPermissions: action.data.apiPermissions,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        token: undefined,
        loginError: action.data ? action.data.errorMessage : undefined,
        user: undefined,
        apiPermissions: undefined,
      };

    // logout
    case types.LOGOUT:
      api.setToken(undefined);
      return {
        ...state,
        token: undefined,
      };
    case commonTypes.RESET_DATA:
      return {
        ...initialState,
        email: state.email,
      };

    // redirect
    case types.REDIRECT_SAVE:
      return { ...state, redirectTo: action.data };
    case types.REDIRECT_APPLY:
      return { ...state, redirectTo: '/' };
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  login: (
    data: {
      email: string;
      password: string;
    },
    onSuccess?: () => void,
    onError?: (message?: string) => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.LOGIN_REQUEST,
      data: {
        email: data.email,
      },
    });
    try {
      const response = await api.user.login({
        username: data.email,
        password: data.password,
      });
      dispatch({ type: types.LOGIN_SUCCESS, data: response.data });
      api.setToken(response.data.token);
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.LOGIN_ERROR,
        data: { errorMessage: 'Invalid Username or Password!' },
      });
      onError?.('Invalid Username or Password!');
    }
  },
  updatePassword: (
    password: string,
    id: string,
    onSuccess?: () => void,
    onError?: (message?: string) => void
  ) => async (dispatch: Dispatch) => {
    try {
      const response = await api.user.updatePassword(id, password);
      if (onSuccess) onSuccess();
    } catch (error) {
      onError?.('Cannot Update Password!');
    }
  },
  logout: () => async (dispatch: Dispatch) => {
    dispatch({ type: types.LOGOUT });
  },

  redirectSave: (to: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.REDIRECT_SAVE, data: to });
  },

  redirectApply: () => (dispatch: Dispatch) =>
    dispatch({ type: types.REDIRECT_APPLY }),
};
