import React from 'react';

import './Header.scss';
import { useDispatch } from 'react-redux';
import { actions as uiActions } from '../../store/reducers/ui-data';

const HeaderMenuToggle: React.SFC = () => {
  const dispatch = useDispatch();
  const onMenuToggle = () => {
    dispatch(uiActions.toggleSideMenu(true));
  };

  return (
    <div id="menuToggle" className="Header__menu-toggle" onClick={onMenuToggle}>
      <span className="Header__toggle-bar" />
    </div>
  );
};

export default HeaderMenuToggle;
