import React from 'react';

interface DropDownMenuProps {
  isRight?: boolean;
  hidden?: boolean;
}

const DropDownMenu: React.SFC<DropDownMenuProps> = (props) => {
  const renderChildren = () => {
    return React.Children.map(props.children, (child, index) => {
      if (React.isValidElement(child)) {
        const childProps = {
          hidden: props.hidden,
          ...child.props,
        };
        return React.cloneElement(child, { ...childProps });
      }
      return child;
    });
  };

  const style: React.CSSProperties | undefined = props.hidden
    ? { display: 'none' }
    : undefined;

  return (
    <ul
      className={`dropdown__menu ${
        props.isRight ? 'dropdown__menu--right' : ''
      }`}
      style={style}
    >
      {renderChildren()}
    </ul>
  );
};

export default DropDownMenu;
