import React from 'react';
import moment from 'moment';
import TextView from './TextView';

interface DateViewProps {
  value?: string | Date;
  bold?: boolean;
  dateFormat?: string;
  nullValue?: string;
}

const DateView: React.SFC<DateViewProps> = (props) => {
  const formatContent = (): string => {
    if (!props.value) {
      return '';
    }
    if (props.dateFormat) {
      return moment(new Date(props.value)).format(props.dateFormat);
    }
    return props.value.toString();
  };

  const value = formatContent();
  return <TextView {...props} value={value}></TextView>;
};

export default DateView;
