import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../components/button';
import {
  SubscriptionPlan,
  SubscriptionPlanCategoryUpdateData,
} from '../../api/models';
import SelectMultipleInput from '../../components/form-group/SelectMultipleInput';

const EditCategoryForm: React.FunctionComponent<{
  onSubmit: (data: SubscriptionPlanCategoryUpdateData) => void;
  default?: SubscriptionPlan;
  errorMessage?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const { handleSubmit, errors, control, setValue } = useForm<SubscriptionPlan>(
    {
      shouldFocusError: true,
      shouldUnregister: true,
      defaultValues: props.default,
    }
  );

  const [errorCategories, setErrorCategories] = useState('');

  const onSubmit = (data: SubscriptionPlanCategoryUpdateData) => {
    data &&
    ((data.categories && data.categories?.length > 0) ||
      (data.subCategories && data.subCategories?.length > 0))
      ? props.onSubmit({
          ...data,
        })
      : setErrorCategories('Categories required');
  };

  useEffect(() => {
    setValue('categories', props.default?.categories);
    setValue('subCategories', props.default?.subCategories);
  }, [props.default, setValue]);

  return (
    <>
      {' '}
      <div className="row mb-4 ml-1">
        <h6>{props.default?.name}</h6>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-xl-10 col-12">
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className="textField">
                  <Controller
                    control={control}
                    name="categories"
                    defaultValue={props.default?.categories}
                    rules={{ required: 'Categories is Required' }}
                    render={({ onChange, value, name }) => (
                      <SelectMultipleInput
                        label="Categories"
                        placeholder="Select Categories"
                        api={`category/subscription-plan/${props.default?._id}/category-select-list`}
                        optionLabel="title"
                        optionValue="_id"
                        onChange={(val) => {
                          onChange(val);
                          setValue('categories', val);
                          setErrorCategories('');
                        }}
                        name={name}
                        default={value}
                        error={errorCategories}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="textField">
                  <Controller
                    control={control}
                    name="subCategories"
                    defaultValue={props.default?.categories}
                    rules={{ required: 'SubCategories is Required' }}
                    render={({ onChange, value, name }) => (
                      <SelectMultipleInput
                        label="SubCategories"
                        placeholder="Select SubCategories"
                        api={`category/subscription-plan/${props.default?._id}/sub-category-select-list`}
                        optionLabel="title"
                        optionValue="_id"
                        onChange={(val) => {
                          onChange(val);
                          setValue('subCategories', val);
                          setErrorCategories('');
                        }}
                        name={name}
                        default={value}
                        error={errorCategories}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-2 mt-2">
                  {props.errorMessage && (
                    <span className="message_validation">
                      {props.errorMessage}
                    </span>
                  )}
                </div>
                <div className="button-outer">
                  <Button type="submit" disabled={props.isSubmitting}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditCategoryForm;
