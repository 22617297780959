import React from 'react';
import { Button } from '../button';
import { useDispatch } from 'react-redux';

import { actions as userActions } from '../../store/reducers/authUser';
import { actions as commonActions } from '../../store/reducers/common';
import { useHistory } from 'react-router-dom';
import { RouteKeys as AuthRouteKeys } from '../../containers/auth/route-keys';

interface LogoutButtonProps {
  className?: string;
  noIcon?: boolean;
}

const LogoutButton: React.SFC<LogoutButtonProps> = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const logout = () => {
    dispatch(userActions.logout());
    dispatch(commonActions.clearData());
    history.replace(AuthRouteKeys.Login);
  };

  return (
    <Button
      className={`${props.className}`}
      type="button"
      onClick={logout}
      btnLink
    >
      {!props.noIcon && <i className="icon-logout"></i>}
      {' Logout'}
    </Button>
  );
};

export default LogoutButton;
