import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { TextInput, PasswordInput } from '../../components/form-group';
import AuthCanvas from './AuthCanvas';
import { useDispatch, useSelector } from 'react-redux';
import authUser, {
  actions as authUserActions,
} from '../../store/reducers/authUser';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../routes/route-keys';
import ClickLink from '../../components/click-link';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../components/button';
import { State } from '../../store/interfaces';
import PlainLayout from '../../layout/PlainLayout';
import { actions as commonActions } from '../../store/reducers/common';
import { RouteKeys as AuthRouteKeys } from '../../containers/auth/route-keys';
interface ChangePasswordFormProps {
  password: string;
  confirmPassword: string;
}

const ChangePassword: React.SFC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector((state: State) => state.authUser);
  const { handleSubmit, errors, control, watch } = useForm<
    ChangePasswordFormProps
  >({
    shouldFocusError: true,
  });

  const updatePassword = (data: { password: string }) => {
    setErrorMessage('');
    return dispatch(
      authUserActions.updatePassword(
        data.password,
        authState.user!._id,
        () => {
          dispatch(authUserActions.logout());
          dispatch(commonActions.clearData());
          history.replace(AuthRouteKeys.Login);
        },
        (message) => {
          setErrorMessage(message || '');
        }
      )
    );
  };
  return (
    <PlainLayout>
      <form onSubmit={handleSubmit(updatePassword)}>
        <Controller
          control={control}
          name="password"
          rules={{ required: 'Password is Required' }}
          render={({ onChange, value, name }) => (
            <PasswordInput
              gap="sm"
              placeholder="Password"
              error={errors.password?.message}
              onChange={onChange}
              name={name}
              default={value}
            />
          )}
        ></Controller>
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            required: true,
            validate: { isMatching: (value) => value === watch('password') },
          }}
          render={({ onChange, value, name }) => (
            <PasswordInput
              gap="sm"
              placeholder="Confirm Password"
              error={errors.confirmPassword?.message}
              onChange={onChange}
              name={name}
              default={value}
            />
          )}
        ></Controller>
        {errors.confirmPassword && (
          <>
            {errors.confirmPassword.type === 'required' && (
              <span className="message_validation">
                Confirm Password is required
              </span>
            )}
            {errors.confirmPassword.type === 'isMatching' && (
              <span className="message_validation">Passwords should match</span>
            )}
          </>
        )}
        <div className="mb-2 mt-2">
          {errorMessage && (
            <span className="message_validation">{errorMessage}</span>
          )}
        </div>
        <div className="button-outer text-right">
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </PlainLayout>
  );
};

export default ChangePassword;
