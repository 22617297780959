import React from 'react';
import { CreateActionButton } from '../../components/actions/action-buttons';
import { LinkField, TextDataField } from '../../components/data-fields';
import { TextView } from '../../components/view-fields';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { metaData } from './metaData';

const ListView: React.FunctionComponent = () => {
  return (
    <PlainLayout>
      <CrudListView metaData={metaData} actions={[<CreateActionButton />]}>
        <LinkField source="name" label="Name">
          <TextView />
        </LinkField>
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
