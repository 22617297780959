import React, { useState } from 'react';

import './PermissiosAccordion.scss';

interface PermissionAccordionProps {
  heading?: React.ReactNode;
  subHeading?: string;
  withBorder?: boolean;
  innerClass?: string;
  defaultOpen?: boolean;
  actions?: React.ReactNode[];
  headingLevel?: 'xs' | 'sm' | 'md' | 'lg';
  header?: React.ReactNode;
}

const PermissiosAccordion: React.SFC<PermissionAccordionProps> = (props) => {
  const { defaultOpen, actions, header, heading } = props;
  const [isOpen, setOpen] = useState(defaultOpen || false);
  const onOpen = () => {
    if (!defaultOpen) {
      setOpen(!isOpen);
    }
  };
  const borderClasses = props.withBorder
    ? 'PermissiosAccordion__item--border PermissiosAccordion__item--boxshadow'
    : '';
  const innerClass = props.innerClass || '';
  return (
    <div className={`PermissiosAccordion__item ${borderClasses} ${innerClass}`}>
      <div
        className="PermissiosAccordion__heading"
        role="presentation"
        onClick={() => onOpen()}
      >
        <div className="PermissiosAccordion__heading-left">
          <div className="d-flex align-items-center">
            {header}
            <span
              className={`PermissiosAccordion__title  ${
                props.headingLevel
                  ? `PermissiosAccordion__title--level-${
                      props.headingLevel || 'md'
                    }`
                  : ''
              }`}
            >
              {heading}
            </span>
          </div>
        </div>
        <div className="PermissiosAccordion__heading-right align-items-md-center">
          {props.subHeading && (
            <span className="text-muted font-xsmall">{props.subHeading}</span>
          )}
          {actions &&
            actions.map((action) => <span className="mr-2">{action}</span>)}
          {isOpen && (
            <i className="icon-chevron-up PermissiosAccordion__icon" />
          )}
          {!isOpen && (
            <i className="icon-chevron-down PermissiosAccordion__icon" />
          )}
        </div>
      </div>
      <div
        className={`PermissiosAccordion__collapse ${
          isOpen ? 'PermissiosAccordion__collapse--in' : ''
        }`}
        onClick={() => undefined}
      >
        {props.children}
      </div>
    </div>
  );
};

export default PermissiosAccordion;
