import React from 'react';
import { Link } from 'react-router-dom';

import './Button.scss';

interface ButtonLinkProps {
  link: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
  light?: boolean;
  expand?: boolean;
  btnLink?: boolean;
}

const ButtonLink: React.SFC<ButtonLinkProps> = (props) => {
  const classes = ['Button', `Button--${props.size}`];
  if (props.className) classes.push(props.className);
  if (props.light) classes.push(`Button--light`);
  if (props.btnLink) classes.push(`Button--link`);
  if (props.expand) classes.push(`Button--block`);

  return (
    <Link className={classes.join(' ')} to={props.link}>
      {props.children}
    </Link>
  );
};

export default ButtonLink;
