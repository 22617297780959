import React from 'react';
import { AccountType, Dict, PostType, ProductType } from '../../api/models';
import {
  CreateActionButton,
  StatusActionButton,
} from '../../components/actions/action-buttons';
import {
  CrudListFilterField,
  DateDataField,
  LinkField,
  TextDataField,
} from '../../components/data-fields';
import { DateInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import { TextView } from '../../components/view-fields';
import CrudListFilters from '../../layout/crud/components/CrudListFilters';
import CrudListSearch from '../../layout/crud/components/CrudListSearch';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { getEnumValues } from '../../utils/enum-utils';
import { metaData } from './metaData';

const ListView: React.FunctionComponent = () => {
  const accountTypes = getEnumValues(AccountType).map((accountType: string) => {
    return { _id: accountType, title: accountType };
  });
  accountTypes.unshift({ _id: '', title: 'Select' });

  const productTypes = getEnumValues(ProductType).map((postType: string) => {
    return { _id: postType, title: postType };
  });
  productTypes.unshift({ _id: '', title: 'Select' });
  const filters: React.ReactNode[] = [
    <CrudListFilterField field="categoryId" label="Category">
      <SelectInput api="category" optionLabel="title" optionValue="_id" />
    </CrudListFilterField>,
    <CrudListFilterField field="subcategoryId" label="SubCategory">
      <SelectInput api="subcategory" optionLabel="title" optionValue="_id" />
    </CrudListFilterField>,
    <CrudListFilterField
    field="startDate"
    label="Valid From"
    >
    <DateInput></DateInput>
    </CrudListFilterField>,

    <CrudListFilterField
    field="endDate"
    label="Valid To"
    >
    <DateInput></DateInput>
    </CrudListFilterField>,
  ];

  return (
    <PlainLayout>
      <CrudListView
        metaData={metaData}
        actions={[
          <CrudListSearch />,
          <CrudListFilters queryFilters>{filters}</CrudListFilters>,
        ]}
        queryParams={{
          productType: ProductType.Sale
        }}
      >
        <LinkField source="title" label="Title">
          <TextView bold />
        </LinkField>
        <TextDataField source="category.title" label="Category"></TextDataField>
        <TextDataField
          source="subcategory.title"
          label="SubCategory"
        ></TextDataField>
        <TextDataField source="price" label="Price($)"></TextDataField>
        <TextDataField source="postType" label="Post Type"></TextDataField>
        <TextDataField source="location.address" label="City"></TextDataField>
        <DateDataField source="validTill" label="Valid Till" dateFormat="DD-MMM-YYYY"/>
        {/* <TextDataField
          source="reportedCount"
          label="Reported Count"
        ></TextDataField> */}
        <StatusActionButton source="isActive" label="Status" />
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
