import React, { useContext } from 'react';
import { DataRecordContext } from '../../contexts';
import { Button } from '../button';
import { DataTableFieldProps } from '../data-table';
import { DropDownItem } from '../dropdown';

interface ActionFieldProps<T = any> extends DataTableFieldProps<T> {
  hidden?: boolean;
  onClick: (index: number, data: T) => void;
}

const ClickAction: React.SFC<ActionFieldProps> = (props) => {
  const record = useContext(DataRecordContext);
  return (
    <DropDownItem hidden={props.hidden}>
      <Button
        btnLink
        noTransform
        size="sm"
        onClick={() => props.onClick(record.index!, record.record)}
      >
        {props.children}
      </Button>
    </DropDownItem>
  );
};

export default ClickAction;
