import axios, { AxiosRequestConfig } from 'axios';

import { saveAs } from 'file-saver';

export function getAuthHeader(token?: string) {
  if (!token) {
    return {};
  }
  return {
    Authorization: `Bearer ${token}`,
  };
}

export async function get(url: string, token?: string) {
  const options = {
    headers: {
      ...getAuthHeader(token),
    },
  };
  return axios.get(url, options);
}

export async function post(url: string, data: any, token?: string) {
  const options: AxiosRequestConfig = {
    data,
    url,
    method: 'POST',
    headers: {
      ...getAuthHeader(token),
      'Content-Type': 'application/json',
    },
  };
  return axios.post(url, JSON.stringify(data), options);
}

export async function put(url: string, data: any, token?: string) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(token),
      'Content-Type': 'application/json',
    },
  };
  return axios.put(url, JSON.stringify(data), options);
}

export async function remove(url: string, token?: string) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(token),
    },
  };
  return axios.delete(url, options);
}

export async function upload(
  url: string,
  data: FormData,
  onUploadProgress: (progressEvent: any) => void,
  token?: string
) {
  const options: AxiosRequestConfig = {
    onUploadProgress,
    headers: {
      ...getAuthHeader(token),
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(url, data, options);
}

export async function download(
  url: string,
  data?: any,
  token?: string,
  accept?: string
) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(token),
      Accept: accept,
    },
    responseType: 'arraybuffer',
  };
  return axios.post(url, data, options);
}
export const saveDownloadedFile = (
  buffer: any,
  type: string,
  fileName: string
) => {
  const data = new Blob([buffer], {
    type,
  });
  saveAs(data, fileName);
};
