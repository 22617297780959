import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Brand } from '../../api/models/brand';
import { SubCategory } from '../../api/models/category';
import { Button } from '../../components/button';
import { TextInput } from '../../components/form-group';
import TextArea from '../../components/form-group/TextArea';
import SelectInput from '../../components/form-group/SelectInput';
import SelectMultipleInput from '../../components/form-group/SelectMultipleInput';

const CreateBrandForm: React.FunctionComponent<{
  onSubmit: (data: Brand) => void;
  default?: Brand;
  errorMessage?: string;
  isSubmitting?: boolean;
  subCategories?: SubCategory[];
}> = (props) => {
  const { handleSubmit, errors, control, watch, setValue } = useForm<Brand>({
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: props.default,
  });

  const onSubmit = (data: Brand) => {
    props.onSubmit({
      ...data,
    });
  };

  useEffect(() => {
    setValue('title', props.default?.title);
    setValue('description', props.default?.description);
    setValue('subCategoryIds', props.default?.subCategoryIds);
  }, [props.default, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
        <div className="col-xl-5 col-sm-6 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="title"
            rules={{ required: 'Title is Required' }}
            defaultValue={props.default?.title}
            render={({ onChange, value, name }) => (
              <TextInput
                label="Title"
                placeholder="Title"
                error={errors.title?.message}
                onChange={onChange}
                name={name}
                default={value}
              />
            )}
          ></Controller>
        </div>
</div>
        <div className="col-xl-5 col-sm-6 col-12">
          <div className="textField">
          {/* <Controller
            control={control}
            name="subCategoryId"
            rules={{ required: 'Sub category is Required' }}
            defaultValue={props.default?.subCategoryId}
            render={({ onChange, value, name }) => (
              <SelectInput
                label="Sub category"
                placeholder="Sub category"
                error={errors.subCategoryId?.message}
                onChange={(val) => {
                  onChange(val);
                }}
                name={name}
                default={value}
                optionLabel="title"
                optionValue="_id"
                data={props.subCategories}
              />
            )}
          ></Controller> */}
          <Controller
            control={control}
            name="subCategoryIds"
            defaultValue={props.default?.subCategories}
            rules={{ required: 'SubCategories is Required' }}
            render={({ onChange, value, name }) => (
              <SelectMultipleInput
                label="SubCategories"
                placeholder="Select SubCategories"
                // data={subCategory ? subCategory : []}
                data={props.subCategories}
                // api={api}
                optionLabel="title"
                optionValue="_id"
                onChange={(val) => {
                  onChange(val);
                  setValue('subCategoryIds', val);
                }}
                name={name}
                default={value}
              />
            )}
          ></Controller>
        </div>
</div>
<div className="col-xl-10 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="description"
            defaultValue={props.default?.description}
            render={({ onChange, value, name }) => (
              <TextArea
                label="Description"
                placeholder="Description"
                error={errors.description?.message}
                onChange={onChange}
                name={name}
                default={value}
                rows={4}
              />
            )}
          ></Controller>
        </div>
        </div>
<div className="col-xl-5 col-sm-6 col-12">
        <div className="mb-2 mt-2">
          {props.errorMessage && (
            <span className="message_validation">{props.errorMessage}</span>
          )}
        </div>

        <div className="button-outer">
          <Button type="submit" disabled={props.isSubmitting}>
            Save
          </Button>
        </div>
        </div>
        </div>
        </form>
    </>
  );
};

export default CreateBrandForm;
