import React from 'react';
import ClickLink from '../../components/click-link';
import PlainLayout from '../../layout/PlainLayout';
import CreateSubscriptionPlanForm from './CreateSubscriptionPlanForm';
import { metaData } from './metaData';
import { usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';

const CreateView: React.FunctionComponent = (props) => {
  const history = useHistory();
  const { performAction, isPerforming } = usePerformAction(
    (data: any) =>
      api.crud.create(
        {
          resource: metaData.resource,
        },
        data
      ),
    () => {
      toastr.success('Success', 'Subscription plan created successfully');
      history.push(metaData.route || '/');
    },
    () => {
      toastr.error('Error', 'Unable to create subscription plan');
    }
  );
  return (
    <PlainLayout>
      <div>
        <div className="row mb-4">
          <div className="col"><h4 className="font-weight-bold">Create New Subscription Plan</h4></div>
          <div className="col-auto">
            <ClickLink link={`${metaData.route}`}>
              <i className="icon-chevron-left mr-2 font-large"></i> Back
            </ClickLink>
          </div>
        </div>
        <CreateSubscriptionPlanForm
          onSubmit={(d) => performAction(d)}
          isSubmitting={isPerforming}
        />
      </div>
    </PlainLayout>
  );
};

export default CreateView;
