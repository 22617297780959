/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import { SideMenuItem } from './interfaces';
import MenuLink from './MenuLink';
import SubMenuLink from './SubMenuLink';
import PermissionCheckView from '../permission-check/PermissionCheckView';
import AnyOnePermissionCheckView from '../permission-check/AnyOnePermissionCheckView';
import { isSubMenuActive } from './utils';
import { useLocation } from 'react-router-dom';

interface MenuItemProps extends SideMenuItem {
  active?: boolean;
}

const MenuItem: React.SFC<MenuItemProps> = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const isOpen = menuOpen || props.active;

  if (!props.subMenu || props.subMenu.length === 0) {
    return <MenuLink {...props}></MenuLink>;
  }

  return (
    <AnyOnePermissionCheckView
      permissions={props.subMenu.map((subMenu) => subMenu.permission)}
    >
      <li
        key={props.menuKey}
        className={`menu__item ${isOpen ? ' menu__item--active' : ''}`}
      >
        <span
          className={`menu__link font-weight-semibold  text-gray-900 ${
            props.active ? ' menu__link--active' : ''
          }`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <i className={` icon-${props.icon} menu__icon`} />
          {props.label}

          {props.subMenu && props.subMenu.length > 0 && (
            <span className="sidemenu-dropdown-toggle"></span>
          )}
        </span>

        {isOpen && (
          <ul className="submenu">
            {props.subMenu.map((subMenu) => {
              return (
                <PermissionCheckView permission={subMenu.permission}>
                  <SubMenuLink
                    active={isSubMenuActive(location.pathname, subMenu.link!)}
                    {...subMenu}
                  ></SubMenuLink>
                </PermissionCheckView>
              );
            })}
          </ul>
        )}
      </li>
    </AnyOnePermissionCheckView>
  );
};

export default MenuItem;
