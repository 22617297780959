import DateInput from './date-input/DateInput';
import OTPInput from './otp-input';
import PasswordInput from './password-input';
import RadioSelectInput from './radio-select-input';
import SelectTextInput from './select-text-input';
import TextInput from './text-input';
import SelectPhoneInput from './SelectPhoneInput';
import CheckboxInput from './checkbox';
import TextArea from './TextArea';
import EditorInput from './editor-input';

export default {
  TextInput,
  PasswordInput,
  RadioSelectInput,
  SelectTextInput,
  OTPInput,
  DateInput,
  SelectPhoneInput,
  CheckboxInput,
  TextArea,
  EditorInput
};

export {
  TextInput,
  PasswordInput,
  RadioSelectInput,
  SelectTextInput,
  OTPInput,
  DateInput,
  SelectPhoneInput,
  CheckboxInput,
  TextArea,
  EditorInput
};
