import * as http from './http';
import ApiService from './service';
import { ApiPermissions, AllEntitiesResponse } from '../models';

export default class EntityService extends ApiService {
  permissionsEndPoint = '/permission';
  allEntititesEndPoint = '/v1/entity/all';

  async loadAllEntities(): Promise<AllEntitiesResponse> {
    const url = `${this.apiDomain}${this.permissionsEndPoint}`;
    const response = await http.get(url, this.token);

    return response.data?.data ? response.data?.data : response.data;
  }
}
