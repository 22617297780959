import React from 'react';

interface NoPermissionViewProps {
  permissions?: (string | undefined)[];
}

const NoPermissionView: React.SFC<NoPermissionViewProps> = (props) => {
  const permissions = props.permissions?.filter((p) => p);
  return (
    <div className="text-center pt-5">
      <h5>Sorry, You don&apos;t have permission to view this page!</h5>
      {permissions && permissions.length > 0 && (
        <p className="text-muted">
          Need <b>{permissions.join(', ')}</b> api permission
          {permissions.length > 1 ? 's' : ''}
        </p>
      )}
    </div>
  );
};

export default NoPermissionView;
