import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Attribute } from '../../api/models/attribute';
import { Category, SubCategory } from '../../api/models/category';
import { Filters } from '../../api/models/filters';
import ClickAction from '../../components/actions/ClickAction';
import AttributeInputController from '../../components/attributes/AttributeInputController';
import FilterInputController from '../../components/attributes/FilterInputController';
import { Button } from '../../components/button';
import AddAttributeForm from '../../components/custom-forms/AddAttributeForm';
import AddFilterForm from '../../components/custom-forms/AddFilterForm';
import { ActionsDataField, TextDataField } from '../../components/data-fields';
import { DataTable } from '../../components/data-table';
import { TextInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import SwitchInput from '../../components/form-group/switch-input';
import TextArea from '../../components/form-group/TextArea';
import Modal from '../../components/modal';
import FileUploadController from '../../components/upload/FileUploadController';

const CreateSubcategoryForm: React.FunctionComponent<{
  onSubmit: (data: SubCategory) => void;
  default?: SubCategory;
  errorMessage?: string;
  isSubmitting?: boolean;
  categories?: Category[];
}> = (props) => {
  const [editAttributeModal, setEditAttributeModal] = useState<{
    open?: boolean;
    data?: Attribute;
    index?: number;
  }>({});

  const [editFilterModal, setEditFilterModal] = useState<{
    open?: boolean;
    data?: Filters;
    index?: number;
  }>({});

  const { handleSubmit, errors, control, watch, setValue } = useForm<
    SubCategory
  >({
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: props.default,
  });

  const {
    fields: attributes,
    append: addAttribute,
    remove: removeAttriute,
  } = useFieldArray<Attribute>({
    control,
    name: 'properties',
  });

  const {
    fields: filters,
    append: addFilter,
    remove: removeFilter,
  } = useFieldArray<Filters>({
    control,
    name: 'filters',
  });

  useEffect(() => {
    setValue('title', props.default?.title);
    setValue('description', props.default?.description);
    setValue('properties', props.default?.properties);
    setValue('filters', props.default?.filters);
    setValue('brandRequired', props.default?.brandRequired);
  }, [props.default, setValue]);

  const onSubmit = (data: SubCategory) => {

    props.onSubmit({
      ...data,
      properties: (attributes as Attribute[]) || [],
      filters: (filters as Filters[]) || [],
    });
  };

  const updateAttribute = (index: number, data: Attribute) => {
    const properties = watch('properties');
    properties[index] = data;
    setValue(`properties`, [...properties]);
  };

  const updateFilter = (index: number, data: Filters) => {
    const fl = watch('filters');
    filters[index] = data;
    setValue(`filters`, [...fl]);
  };


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
        <div className="col-xl-5 col-sm-6 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="categoryId"
            rules={{ required: 'Category is Required' }}
            defaultValue={props.default?.categoryId}
            render={({ onChange, value, name }) => (
              <SelectInput
                label="Category"
                placeholder="Category"
                error={errors.categoryId?.message}
                onChange={(val) => {
                  onChange(val);
                  setValue(
                    'properties',
                    (props.categories?.find((cat) => cat._id === val)?.properties)
                    ?.map((prop) => { prop.inherited=true; return prop; })
                  );
                  setValue(
                    'filters',
                    props.categories?.find((cat) => cat._id === val)?.filters
                  );
                }}
                name={name}
                default={value}
                optionLabel="title"
                optionValue="_id"
                data={props.categories}
              />
            )}
          ></Controller>
        </div>
</div>
<div className="col-xl-5 col-sm-6 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="title"
            rules={{ required: 'Title is Required' }}
            defaultValue={props.default?.title}
            render={({ onChange, value, name }) => (
              <TextInput
                label="Title"
                placeholder="Title"
                error={errors.title?.message}
                onChange={onChange}
                name={name}
                default={value}
              />
            )}
          ></Controller>
        </div>
        </div>
        <div className="col-xl-5 col-sm-6 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="description"
            defaultValue={props.default?.description}
            render={({ onChange, value, name }) => (
              <TextArea
                label="Description"
                placeholder="Description"
                error={errors.description?.message}
                onChange={onChange}
                name={name}
                default={value}
                rows={4}
              />
            )}
          ></Controller>
        </div>
</div>
<div className="col-xl-5 col-sm-6 col-12">
        <div className="textField">
          <FileUploadController
            value={props.default?.image}
            control={control}
            name="image"
            setValue={setValue}
          ></FileUploadController>
        </div>
</div>
      <div className="col-xl-5 col-sm-6 col-12">

        
                <div className="textField">
                    <label className="d-block font-xsmall">&nbsp;</label>
                    <Controller
                      control={control}
                      name="brandRequired"
                      defaultValue={props.default?.brandRequired}
                      render={({ onChange, value, name }) => (
                        <SwitchInput
                          label="Brand Required"
                          placeholder="Brand Required"
                          error=""
                          onChange={onChange}
                          name={name}
                          default={value}
                        />
                      )}
                    >
                    </Controller>
                  </div>
      </div>
<div className="col-12">
        <div className="my-5">
          <h6 className="mb-3">Attributes</h6>
          <DataTable list={attributes} recordIdKey="key">
            <TextDataField label="Label" source="label"></TextDataField>
            <TextDataField label="Key" source="key"></TextDataField>
            <TextDataField label="Type" source="type"></TextDataField>
            <TextDataField
              label="Required"
              source="required"
              nullValue="No"
              formatContent={(val) => (val ? 'Yes' : 'No')}
            ></TextDataField>
            <ActionsDataField actionsColumn label="Actions">
              <ClickAction
                onClick={(index: number, data: Attribute) =>
                  setEditAttributeModal({ open: true, index, data })
                }
              >
                Edit
              </ClickAction>
              <ClickAction
                onClick={(index: number, data: Attribute) => {
                  removeAttriute(index);
                  const filterIndex = filters.findIndex(
                    (d) => d.key === data.key
                  );
                  if (filterIndex !== -1) {
                    removeFilter(filterIndex);
                  }
                }}
              >
                Delete
              </ClickAction>
            </ActionsDataField>
          </DataTable>
          <div className="mt-4">
          <Button
                  btnLink
                  size="sm"
                  className="text-red"
                  onClick={() => setEditAttributeModal({ open: true })}
                  >
                  + Add New Attribute
                </Button>
           
          </div>
        </div>
</div>
        {watch('properties')?.length > 0 && (
          <div className="col-xl-5 col-sm-6 col-12">
          <div className="textField">
            <h6>Filters</h6>
            <DataTable list={filters} recordIdKey="key">
              <TextDataField label="Name" source="name"></TextDataField>
              <TextDataField label="Key" source="key"></TextDataField>
              <TextDataField label="Type" source="type"></TextDataField>
              <ActionsDataField actionsColumn label="Actions">
                <ClickAction
                  onClick={(index: number, data: Filters) =>
                    setEditFilterModal({ open: true, index, data })
                  }
                >
                  Edit
                </ClickAction>
                <ClickAction
                  onClick={(index: number) => {
                    removeFilter(index);
                  }}
                >
                  Delete
                </ClickAction>
              </ActionsDataField>
            </DataTable>
            <Button
                  btnLink
                  size="sm"
                  className="text-red"
                  onClick={() => setEditFilterModal({ open: true })}
                  >
                  + Add New Attribute
                </Button>
           
          </div>
          </div>
        )}
        <div className="col-xl-5 col-sm-6 col-12">
        <div className="mb-2 mt-2">
          {props.errorMessage && (
            <span className="message_validation">{props.errorMessage}</span>
          )}
        </div>

        <div className="button-outer pt-3">
          <Button type="submit" disabled={props.isSubmitting}>
            Save
          </Button>
        </div>
        </div>
        </div>
      </form>
      {editAttributeModal.open && (
        <Modal width="798"
          isOpen={editAttributeModal.open}
          onClose={() => {
            setEditAttributeModal({});
            return true;
          }}
        >
          <AddAttributeForm
            key={editAttributeModal.data?.key || 'create'}
            attribute={editAttributeModal.data}
            onSubmit={(d: Attribute) => {
              if (editAttributeModal.data) {
                updateAttribute(editAttributeModal.index!, d);
              } else {
                addAttribute(d);
              }
              setEditAttributeModal({});
            }}
            onCancel={() => setEditAttributeModal({})}
          ></AddAttributeForm>
        </Modal>
      )}
      {editFilterModal.open && (
        <Modal width="798"
          isOpen={editFilterModal.open}
          onClose={() => {
            setEditAttributeModal({});
            return true;
          }}
        >
          <AddFilterForm
            key={editFilterModal.data?.key || 'create'}
            filter={editFilterModal.data}
            onSubmit={(d: Filters) => {
              if (editFilterModal.data) {
                updateFilter(editFilterModal.index!, d);
              } else {
                addFilter(d);
              }
              setEditFilterModal({});
            }}
            properties={watch('properties')?.map((pr: Attribute) => ({
              name: pr.label,
              value: pr.key,
            }))}
            onCancel={() => setEditFilterModal({})}
          ></AddFilterForm>
        </Modal>
      )}
    </>
  );
};

export default CreateSubcategoryForm;
