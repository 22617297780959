import React from 'react';
import { AccountType } from '../../api/models';
import {
  CreateActionButton,
  StatusActionButton,
} from '../../components/actions/action-buttons';
import {
  CrudListFilterField,
  DateDataField,
  LinkField,
  TextDataField,
} from '../../components/data-fields';
import { DateInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import { TextView } from '../../components/view-fields';
import CrudListFilters from '../../layout/crud/components/CrudListFilters';
import CrudListSearch from '../../layout/crud/components/CrudListSearch';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { Dict } from '../../models';
import { getEnumValues } from '../../utils/enum-utils';
import { metaData } from './metaData';

const ListView: React.FunctionComponent = () => {
  const accountTypes = getEnumValues(AccountType).map((accountType: string) => {
    return { _id: accountType, title: accountType };
  });
  accountTypes.unshift({ _id: '', title: 'Select' });
  const filters: React.ReactNode[] = [
    <CrudListFilterField field="reportingUserId" label="Reporter">
      <SelectInput
        api="user/reporting-users-list"
        optionLabel="name"
        optionValue="_id"
      />
    </CrudListFilterField>,
    <CrudListFilterField field="reporteeUserId" label="Reportee">
      <SelectInput
        api="user/reportee-users-list"
        optionLabel="name"
        optionValue="_id"
      />
    </CrudListFilterField>,
    <CrudListFilterField
      field="dateFrom"
      label="From Date"
      relatedFieldValueProps={{
        maxDate: (fields: Dict) => fields.dateTo || new Date(),
      }}
    >
      <DateInput></DateInput>
    </CrudListFilterField>,
    <CrudListFilterField
      field="dateTo"
      label="To Date"
      relatedFieldValueProps={{
        minDate: (fields: Dict) => fields.dateFrom,
        maxDate: (fields: Dict) => new Date(),
      }}
    >
      <DateInput></DateInput>
    </CrudListFilterField>,
  ];
  return (
    <PlainLayout>
      <CrudListView
        metaData={metaData}
        actions={[
          <CrudListSearch />,
          <CrudListFilters queryFilters>{filters}</CrudListFilters>,
        ]}
      >
        <LinkField
          linkProps={{ route: `/user` }}
          source="createdBy.name"
          label="Reporter"
          routeParams="createdBy.id"
        >
          <TextView />
        </LinkField>
        <LinkField
          linkProps={{ route: `/user` }}
          source="userId.name"
          label="Reportee"
          routeParams="userId._id"
        >
          <TextView />
        </LinkField>
        <TextDataField source="reason" label="Reason" />
        <TextDataField source="description" label="Description" />
        <DateDataField
          source="createdAt"
          label="Created Date"
          dateFormat="DD-MMM-YYYY"
        />
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
