import React, { useState } from 'react';
import ClickLink from '../../components/click-link';
import PlainLayout from '../../layout/PlainLayout';
import { metaData } from './metaData';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';
import CrudTabularDetailsView from '../../layout/crud/CrudTabularDetailsView';
import {
  CreateActionButton,
  DeleteActionButton,
  EditActionButton,
} from '../../components/actions/action-buttons';
import { ArrayDataField, TextDataField } from '../../components/data-fields';
import SubscriptionsListView from './components/subscription/ListView';
import { Button } from '../../components/button';
import Modal from '../../components/modal';
import SubscribeView from './components/subscription/CreateView';
import './detail.scss';
import { AvatarView } from '../../components/avatar';
import { User } from '../../api/models';
import { Product } from '../../api/models/catalog';
import MyAds from './components/ads/MyAds';
import TabView from './components/ads/TabView';
import MyPurchase from './components/ads/MyPurchase';
import MyPayments from './components/ads/Payments';
import { format } from 'date-fns';
import ProfileDetails from './components/ProfileDetails';

export enum MyProductType {
  MyAds,
  Purchase,
  Subscripton,
  Payments,
  Profile,
}
export interface TabCount {
  myAdsCount?: (value: number) => void;
  purchaseCount?: (value: number) => void;
  subscriptionCount?: (value: number) => void;
  userId: string;
}
const EditView: React.FunctionComponent = (props) => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [productType, setProductType] = useState<MyProductType>(
    MyProductType.MyAds
  );
  const [modalOpen, setModalOpen] = useState(false);
  const { data, loading, loaded } = useDataLoader<{ data: User }>(() =>
    api.crud.get({
      resource: metaData.resource,
      resourceId: params.id,
    })
  );
  const [myAdsCount, setMyAdsCount] = useState<number>();
  const [purchaseCount, setPurchaseCount] = useState<number>();
  const [mySubscriptionCount, setSubscriptionCount] = useState<number>();

  const renderModal = () => {
    return (
      <>
        <Modal
          width="798"
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            return true;
          }}
        >
          <SubscribeView
            accountType={data.data.accountType}
            userId={params.id}
            onSuccess={() => {
              setModalOpen(false);
              window.location.reload();
            }}
          />
        </Modal>
      </>
    );
  };

  return (
    <PlainLayout>
      {loaded && !loading && (
        <>
          <div className="border-bottom">
            <div className="row">
              <div className="col-12 text-right d-lg-none">
                <ClickLink link={metaData.route}>
                  {' '}
                  <i className="icon-arrow-left mr-2"></i>Back
                </ClickLink>{' '}
              </div>
              <div className="col-auto d-lg-block d-none">
                <AvatarView size="large" src={data.data.avatar?.path} />
              </div>
              <div className="col">
                <div className="row mb-lg-5 mb-2">
                  <div className="col-xl col-12">
                    <div className="d-flex flex-wrap no-gutters">
                      <div className="col-auto d-lg-none pr-3">
                        <AvatarView
                          size="medium"
                          src={data.data.avatar?.path}
                        />
                      </div>
                      <div className="col">
                        <h5 className="mb-1 font-weight-bold font-xl">
                          {data.data.name}{' '}
                        </h5>
                        <div className="font-small text-gray-800 mb-2 pb-1">
                          {data.data.email} <span className="px-2">|</span>
                          {data.data.phone}{' '}
                        </div>
                        
                      </div>
                    </div>
                    <ul className="d-flex flex-wrap list-unstyled p-0 font-small mb-4">
                      <li className="mr-1 bg-white border rounded px-2 py-1 d-inline-flex align-items-center">
                        <img
                          src={require('./images/automotive.png')}
                          alt=""
                          className="mr-1"
                        />
                        Automotive
                      </li>
                      <li className="mr-1 bg-white border rounded px-2 py-1 d-inline-flex align-items-center">
                        <img
                          src={require('./images/fashion.png')}
                          alt=""
                          className="mr-1"
                        />
                        Fashion
                      </li>
                      <li className="mr-1 bg-white border rounded px-2 py-1 d-inline-flex align-items-center">
                        +5
                      </li>
                    </ul>
                    <ul className="d-flex flex-wrap list-unstyled p-0">
                      <li className="mr-md-5 mr-4 font-small text-gray-500">
                        <span className="font-weight-medium font-xl text-gray-900">
                          {data.data.followedCount}
                        </span>{' '}
                        Posts
                      </li>
                      <li className="mr-md-5 mr-4 font-small text-gray-500">
                        <span className="font-weight-medium font-xl text-gray-900">
                          {data.data.followedCount || 0}
                        </span>{' '}
                        Followers
                      </li>
                      <li className="mr-md-5 mr-4 font-small text-gray-500">
                        <span className="font-weight-medium font-xl text-gray-900">
                          {data.data.followingCount || 0}
                        </span>{' '}
                        Following
                      </li>
                      <li className="font-small text-gray-500">
                        <span className="font-weight-medium font-xl text-gray-900">
                          {data.data.reportedCount || 0}
                        </span>{' '}
                        Abuse Count
                      </li>
                    </ul>
                    <div className="social mb-4 d-xl-none">
                      <ul className="align-items-center d-flex list-unstyled m-0 p-0">
                        <li className="d-flex pr-3 align-items-center">
                          <span className="icon-verified2 font-lg pr-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>{' '}
                          <span className="font-base text-gray-500">
                            Verified with
                          </span>
                        </li>
                        <li className="d-flex pr-1 h5 mb-0 pt-2">
                          <a href="#">
                            <span className="icon-fb"></span>
                          </a>
                        </li>
                        <li className="d-flex pr-1 h5 mb-0 pt-2">
                          <a href="#">
                            <span className="icon-gplus">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                              <span className="path5"></span>
                              <span className="path6"></span>
                              <span className="path7"></span>
                            </span>
                          </a>
                        </li>
                        <li className="d-flex pr-1 h5 mb-0 pt-2">
                          <a href="#">
                            <span className="icon-phone2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                          </a>
                        </li>
                        <li className="d-flex pr-1 h5 mb-0 pt-2">
                          <a href="#">
                            <span className="icon-mail2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                            </span>
                          </a>
                        </li>
                        <li className="pr-1 ">
                          <a href="#">
                            <img
                              src={require('./images/suncash.svg')}
                              className="img-fluid"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="font-base mb-md-4 mb-3 4 d-xl-none">
                      <i className="icon-star-filled-2 text-warning"></i>
                      <i className="icon-star-filled-2 text-warning"></i>
                      <i className="icon-star-filled-2 text-warning"></i>
                      <i className="icon-star-filled-2 text-warning"></i>
                      <i className="icon-star-outline-2 text-gray-500"></i>
                      <span className="font-sm text-gray-500 ml-2">(25)</span>
                    </div>
                  </div>
                  <div className="col-xl-auto col-12 text-right d-xl-block d-none">
                    <ClickLink link={metaData.route}>
                      {' '}
                      <i className="icon-arrow-left mr-2"></i>Back
                    </ClickLink>{' '}
                    <div className="social mb-4">
                      <ul className="align-items-center d-flex list-unstyled m-0 p-0 justify-content-end">
                        <li className="d-flex pr-3 align-items-center">
                          <span className="icon-verified2 font-lg pr-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>{' '}
                          <span className="font-base text-gray-500">
                            Verified with
                          </span>
                        </li>
                        <li className="d-flex pr-1 h5 mb-0 pt-2">
                          <a href="#">
                            <span className="icon-fb"></span>
                          </a>
                        </li>
                        <li className="d-flex pr-1 h5 mb-0 pt-2">
                          <a href="#">
                            <span className="icon-gplus">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                              <span className="path5"></span>
                              <span className="path6"></span>
                              <span className="path7"></span>
                            </span>
                          </a>
                        </li>
                        <li className="d-flex pr-1 h5 mb-0 pt-2">
                          <a href="#">
                            <span className="icon-phone2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                          </a>
                        </li>
                        <li className="d-flex pr-1 h5 mb-0 pt-2">
                          <a href="#">
                            <span className="icon-mail2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                            </span>
                          </a>
                        </li>
                        <li className="pr-1 ">
                          <a href="#">
                            <img
                              src={require('./images/suncash.svg')}
                              className="img-fluid"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="font-base mb-md-4 mb-3 4">
                      <i className="icon-star-filled-2 text-warning"></i>
                      <i className="icon-star-filled-2 text-warning"></i>
                      <i className="icon-star-filled-2 text-warning"></i>
                      <i className="icon-star-filled-2 text-warning"></i>
                      <i className="icon-star-outline-2 text-gray-500"></i>
                      <span className="font-sm text-gray-500 ml-2">(25)</span>
                    </div>
                  </div>
                </div>
                <TabView
                  productTypeChange={setProductType}
                  myAdsCount={myAdsCount}
                  purchaseCount={purchaseCount}
                  subscriptionCount={data.data.subscriptions.length}
                ></TabView>
              </div>
            </div>
          </div>
          <div className="tab-content pt-lg-4 pt-2" id="user-tabContent">
            {/* Ads */}
            {productType === MyProductType.MyAds && (
              <MyAds myAdsCount={setMyAdsCount} userId={params.id}></MyAds>
            )}
            {productType === MyProductType.Purchase && (
              <MyPurchase
                purchaseCount={setPurchaseCount}
                userId={params.id}
              ></MyPurchase>
            )}
            {productType === MyProductType.Subscripton && (
              <div>
                <div className="row mt-2">
                  {data?.data && (
                    <SubscriptionsListView
                      subscriptions={data.data.subscriptions}
                      onClick={() => setModalOpen(true)}
                    />
                  )}
                </div>
                {data?.data && renderModal()}
              </div>
            )}
            {productType === MyProductType.Payments && (
              <MyPayments userId={params.id}></MyPayments>
            )}
            {/* Ads */}
            {/* Profile */}
            {productType === MyProductType.Profile && (
              <ProfileDetails userDetails={data.data}></ProfileDetails>
            )}
            {/* Profile */}
            {/* Payments */}
            {/* <div
              className="tab-pane active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="invoice-pannel">
                <div className="row gutter-5 width-row">
                  <div className="col-md-4 panel-box1">
                    <div className="invoice-pannel-boxes p-3 rounded bg-white shadow-sm">
                      <p className="m-0">Total earnings</p>
                      <h5 className="font-xxl text-danger m-0 font-weight-bold">
                        $ 32000.00
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-4 panel-box1">
                    <div className="invoice-pannel-boxes p-3 rounded bg-white shadow-sm">
                      <p className="m-0">Awaiting payment</p>
                      <h5 className="font-xxl text-danger m-0 font-weight-bold">
                        $ 23500.00
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-4 panel-box1">
                    <div className="invoice-pannel-boxes p-3 rounded bg-white shadow-sm">
                      <p className="m-0">Total paid</p>
                      <h5 className="font-xxl text-danger m-0 font-weight-bold">
                        $ 15000.00
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="invoice-pannel-search pt-5 d-none d-md-block">
                  <div className="search-fields d-flex">
                    <div className="form-group mr-2 first-width">
                      <label className="m-0 text-gray-500 font-small">
                        Invoice ID
                      </label>
                      <input type="text" className="form-control bg-white" />
                    </div>
                    <div className="form-group mr-2 second-width">
                      <label className="m-0 text-gray-500 font-small">
                        Customer Name
                      </label>
                      <input type="text" className="form-control bg-white" />
                    </div>
                    <div className="form-group mr-2 first-width">
                      <label className="m-0 text-gray-500 font-small">
                        Status
                      </label>
                      <select className="form-control bg-white">
                        <option>all</option>
                        <option>one</option>
                        <option>two</option>
                      </select>
                    </div>
                    <div className="form-group mr-2 second-width datefield">
                      <label className="m-0 text-gray-500 font-small">
                        Date Range{' '}
                      </label>
                      <span className="position-relative d-block">
                        <input type="text" className="form-control bg-white" />
                        <i className="date-in icon-calendar"></i>
                      </span>
                    </div>
                    <div className="form-group mr-2 second-width datefield">
                      <label className="m-0 d-block">&nbsp; </label>
                      <input
                        type="submit"
                        className="btn btn-primary search-btn btn-danger"
                        value="Search"
                      />
                    </div>
                  </div>
                </div>

                <div className="invoice-pannel-listing pt-4 pt-md-0 ">
                  <div className="invoice-heads bg-gray-400 rounded d-none d-md-block">
                    <div className="row gutter-5">
                      <div className="col-md-2">
                        <p className="m-0">Invoice ID</p>
                      </div>
                      <div className="col-md-2">
                        <p className="m-0">Date</p>
                      </div>
                      <div className="col-md-2">
                        <p className="m-0">Recepient</p>
                      </div>
                      <div className="col-md-2">
                        <p className="m-0">Payment Type</p>
                      </div>
                      <div className="col-md-2">
                        <p className="m-0">Amount</p>
                      </div>
                      <div className="col-md-2">
                        <p className="m-0">Status</p>
                      </div>
                    </div>
                  </div>

                  <div className="invoice-row bg-white rounded">
                    <div className="row gutter-5">
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Invoice ID</p>
                        <div className="inv-id d-flex ">
                          <span>
                            <i className="icon-invoice"></i>
                          </span>
                          <p className="m-0">INV2020/1001</p>
                        </div>
                      </div>
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Date</p>
                        <p className="m-0">14 Fab 2020</p>
                      </div>
                      <div className="col-md-2 col-4 order-4 order-md-0 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Recepient</p>
                        <p className="m-0 font-weight-semibold">
                          Shini Sebastin
                        </p>
                      </div>
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Payment Type</p>
                        <p className="m-0">Credit Card</p>
                      </div>
                      <div className="col-md-2 col-12 order-last order-md-0 pt-1 pt-md-0 pb-md-0 price">
                        <h6 className="m-0">$ 12000.00</h6>
                      </div>
                      <div className="col-md-2 col-4 order-5 order-md-0 status pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Status</p>
                        <span className="text-white font-xxs rounded-pill status-completed">
                          Completed
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="invoice-row bg-white rounded">
                    <div className="row gutter-5">
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Invoice ID</p>
                        <div className="inv-id d-flex ">
                          <span>
                            <i className="icon-invoice"></i>
                          </span>
                          <p className="m-0">INV2020/1001</p>
                        </div>
                      </div>
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Date</p>
                        <p className="m-0">14 Fab 2020</p>
                      </div>
                      <div className="col-md-2 col-4 order-4 order-md-0 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Recepient</p>
                        <p className="m-0 font-weight-semibold">
                          Shini Sebastin
                        </p>
                      </div>
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Payment Type</p>
                        <p className="m-0">Credit Card</p>
                      </div>
                      <div className="col-md-2 col-12 order-last order-md-0 pt-1 pt-md-0 pb-md-0 price">
                        <h6 className="m-0">$ 12000.00</h6>
                      </div>
                      <div className="col-md-2 col-4 order-5 order-md-0 status pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Status</p>
                        <span className="text-white font-xxs rounded-pill status-progress">
                          In progress
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="invoice-row bg-white rounded">
                    <div className="row gutter-5">
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Invoice ID</p>
                        <div className="inv-id d-flex ">
                          <span>
                            <i className="icon-invoice"></i>
                          </span>
                          <p className="m-0">INV2020/1001</p>
                        </div>
                      </div>
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Date</p>
                        <p className="m-0">14 Fab 2020</p>
                      </div>
                      <div className="col-md-2 col-4 order-4 order-md-0 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Recepient</p>
                        <p className="m-0 font-weight-semibold">
                          Shini Sebastin
                        </p>
                      </div>
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Payment Type</p>
                        <p className="m-0">Credit Card</p>
                      </div>
                      <div className="col-md-2 col-12 order-last order-md-0 pt-1 pt-md-0 pb-md-0 price">
                        <h6 className="m-0">$ 12000.00</h6>
                      </div>
                      <div className="col-md-2 col-4 order-5 order-md-0 status pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Status</p>
                        <span className="text-white font-xxs rounded-pill status-completed">
                          Completed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             */}
            {/* Payments */}
          </div>
        </>
      )}
      {(!loaded || loading) && <ContainerLoader height={500}></ContainerLoader>}
    </PlainLayout>
  );
};

export default EditView;
