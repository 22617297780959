import React from 'react';
import { MetaData } from '../../../models/meta-data';
import CrudListDataLoader, {
  CrudListDataLoaderContext,
  CrudListDataLoaderContextInterface,
} from '../../../data-loader-components/CrudListDataLoader';
import { MetaDataContext } from '../../../contexts';
import { Dict } from '../../../models';

interface CrudListBaseViewProps {
  metaData: MetaData;
  defaultFilters?: Dict;
  queryParams?: Dict;
  children: (context: CrudListDataLoaderContextInterface) => React.ReactNode;
}

const CrudListBaseView: React.SFC<CrudListBaseViewProps> = (props) => {
  const defaultFilters: Dict = props.defaultFilters || {};
  if (props.metaData.urlFilterFields?.length) {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    props.metaData.urlFilterFields.forEach((filterField) => {
      if (filterField) {
        const paramValue = params.get(filterField);
        if (paramValue) {
          defaultFilters[filterField] = paramValue.toString();
        } else {
          delete defaultFilters[filterField];
        }
      }
    });
  }

  return (
    <MetaDataContext.Provider value={props.metaData}>
      <CrudListDataLoader
        metaData={props.metaData}
        defaultFilters={defaultFilters}
        queryParams={props.queryParams}
      >
        <CrudListDataLoaderContext.Consumer>
          {props.children}
        </CrudListDataLoaderContext.Consumer>
      </CrudListDataLoader>
    </MetaDataContext.Provider>
  );
};

export default CrudListBaseView;
