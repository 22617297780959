import React from 'react';

interface DropDownSelectProps {
  theme?: string;
  cueType?: number;
  hideCue?: boolean;
}

const DropDownSelect: React.SFC<DropDownSelectProps> = (props) => {
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  };
  const cueIcon = props.cueType === 1 ? 'menu-up' : 'chevron-down';

  return (
    <button
      className={`dropdown__item dropdown__item-selected dropdown--theme-${props.theme}`}
      onClick={clickHandler}
    >
      {props.children}
      {!props.hideCue && <i className={`dropdown__arrow icon-${cueIcon}`} />}
    </button>
  );
};

export default DropDownSelect;
