import React from 'react';
import { Header } from '../components/header';
import { SideMenu } from '../components/side-menu';

interface PlainLayoutInterface {
  hideHeader?: boolean;
  hideSideMenu?: boolean;
}

const PlainLayout: React.SFC<PlainLayoutInterface> = (props) => {
  const header = !props.hideHeader && <Header />;
  const sideMenu = !props.hideSideMenu && <SideMenu key="side-menu" />;
  return (
    <>
      <div>
        {header}
        <div className="content-wrapper">
          {sideMenu}
          <div className={props.hideSideMenu ? 'w-100' : 'outer p-lg-5 p-3'}>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlainLayout;
