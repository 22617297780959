import React from 'react';
import useHasPermission from '../../hooks/useHasPermission';
import NoPermissionView from './NoPermissionView';

interface PermissionCheckViewProps {
  permission?: string;
  showNoPermissionView?: boolean;
}

const PermissionCheckView: React.SFC<PermissionCheckViewProps> = (props) => {
  const hasPermission = useHasPermission(props.permission);

  if (hasPermission) {
    return <>{props.children}</>;
  }
  if (props.showNoPermissionView) {
    return (
      <NoPermissionView permissions={[props.permission]}></NoPermissionView>
    );
  }
  return <></>;
};

export default PermissionCheckView;
