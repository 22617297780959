import { useSelector } from 'react-redux';
import { State } from '../store/interfaces';

const useHasPermission = (permission?: string): boolean => {
  const permissions = useSelector(
    (state: State) => state.authUser.apiPermissions
  );

  if (!permissions) {
    return true;
  }

  if (!permission) {
    return true;
  }

  return permissions?.[permission] || false;
};

export default useHasPermission;
