import React from 'react';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { ContainerLoader } from '../../../../components/loader';
import CreateSubscriptionForm from './CreateSubscriptionForm';
import { AccountType, PaymentMode, PostType } from '../../../../api/models';
import { getEnumValues } from '../../../../utils/enum-utils';
import { capitalizeFirstLetter } from '../../../../utils/text-utils';

const CreateView: React.FunctionComponent<{ accountType: AccountType, userId: string, onSuccess: () => void; }> = (
    props
) => {
    const history = useHistory();
    const {
        data: categories,
        loading: loadingCategories,
        loaded: loadedCategories,
    } = useDataLoader(() =>
        api.crud.getList({ resource: 'category', noPagination: true })
    );
    const {
        data: subscriptionPlans,
        loading: loadingPlans,
        loaded: loadedPlans,
    } = useDataLoader(() =>
        api.crud.getList({
            resource: 'admin-subscription-plan',
            noPagination: true,
            queryParams: { isActive: true, accountType: props.accountType },
        })
    );

    const paymentModes = getEnumValues(PaymentMode).map((paymentMode: string) => {
        return { _id: paymentMode, title: capitalizeFirstLetter(paymentMode) };
    });

    const postTypes = getEnumValues(PostType).map((postType: string) => {
        return { _id: postType, title: postType };
    });

    const { performAction, isPerforming } = usePerformAction(
        (data: any) =>
            api.crud.create(
                {
                    resource: 'admin-subscription/add-user-subscription',
                },
                { ...data, userId: props.userId }
            ),
        () => {
            toastr.success('Success', 'Subscription created successfully');
            props.onSuccess();
        },
        () => {
            toastr.error('Error', 'Unable to create subscription');
        }
    );
    return (
        <div>
            <div className="row mb-4">
                <div className="col-12 ml-5">
                    <h4 className="font-weight-bold">Create New Subscription</h4>
                </div>
            </div>
            {(!loadedPlans ||
                !loadedCategories ||
                loadingPlans ||
                loadingCategories) && <ContainerLoader height={500}></ContainerLoader>}
            {loadedPlans && loadedCategories && (
                <CreateSubscriptionForm
                    onSubmit={(d) => performAction(d)}
                    isSubmitting={isPerforming}
                    categories={categories.data.data}
                    postTypes={postTypes}
                    paymentModes={paymentModes}
                    subscriptionPlans={subscriptionPlans.data.data}
                />
            )}
        </div>
    );
};

export default CreateView;
