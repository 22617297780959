import React, { useEffect } from 'react';
import { Attribute } from '../../api/models/attribute';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import AttributeInputController from '../../components/attributes/AttributeInputController';
import { Button } from '../../components/button';
import { SelectPhoneInput, TextInput } from '../../components/form-group';
import TextArea from '../../components/form-group/TextArea';
import SelectInput from '../../components/form-group/SelectInput';
import validator from 'validator';

interface CreateAdminFormProps {
  name: string;
  email: string;
  phone: string;
  role: string;
  username?: string;
}

const CreateCategoryForm: React.FunctionComponent<{
  onSubmit: (data: CreateAdminFormProps) => void;
  default?: CreateAdminFormProps;
  errorMessage?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const { handleSubmit, errors, control, watch, setValue } = useForm<
    CreateAdminFormProps
  >({
    shouldFocusError: true,
    shouldUnregister: false,
    defaultValues: props.default,
  });

  useEffect(() => {
    setValue('name', props.default?.name);
    setValue('email', props.default?.email);
    setValue('phone', props.default?.phone);
    setValue('role', props.default?.role);
    setValue('username', props.default?.username);
  }, [props.default, setValue]);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <div className="row">
      <div className="col-xl-5 col-sm-6 col-12">
      <div className="textField">
        <Controller
          control={control}
          name="name"
          rules={{ required: 'Name is Required' }}
          defaultValue={props.default?.name}
          render={({ onChange, value, name }) => (
            <TextInput
              label="Name"
              placeholder=""
              error={errors.name?.message}
              onChange={onChange}
              name={name}
              default={value}
            />
          )}
        ></Controller>
      </div>
      </div>
      <div className="col-xl-5 col-sm-6 col-12">
      <div className="textField">
        <Controller
          control={control}
          name="email"
          defaultValue={props.default?.email}
          rules={{
            required: 'Email is required',
            validate: (value) => validator.isEmail(value) || 'Invalid Email',
          }}
          render={({ onChange, value, name }) => (
            <TextInput
              label="Email"
              placeholder=""
              error={errors.email?.message}
              onChange={onChange}
              name={name}
              default={value}
            />
          )}
        ></Controller>
      </div>
      </div>
      <div className="col-xl-5 col-sm-6 col-12">
      <div className="textField">
        <Controller
          control={control}
          name="phone"
          rules={{
            required: 'Phone is required',
          }}
          defaultValue={props.default?.phone}
          render={({ onChange, value, name }) => (
            <SelectPhoneInput
              label="Phone"
              placeholder=""
              error={errors.phone?.message}
              onChange={onChange}
              name={name}
              default={value}
            />
          )}
        ></Controller>
      </div>
      </div>
      <div className="col-xl-5 col-sm-6 col-12">
      <div className="textField">
        <Controller
          control={control}
          name="role"
          defaultValue={props.default?.role}
          render={({ onChange, value, name }) => (
            <SelectInput
              label="Role"
              placeholder=""
              api="role"
              error={errors.role?.message}
              onChange={onChange}
              name={name}
              optionLabel="name"
              optionValue="name"
              default={value}
            />
          )}
        ></Controller>
      </div>
      <div className="mb-2 mt-2">
        {props.errorMessage && (
          <span className="message_validation">{props.errorMessage}</span>
        )}
      </div>
      </div>
      <div className="col-xl-5 col-sm-6 col-12">
      <div className="button-outer pt-3">
        <Button type="submit" noTransform disabled={props.isSubmitting}>
          Save
        </Button>
      </div>
      </div>
      </div>
      </form>
  );
};

export default CreateCategoryForm;
