import React from 'react';

import './ToolbarSummary.scss';
import { findNestedObjectValue } from '../utils';
import { MetaDataHeaders } from '../../models/meta-data';
import { Dict } from '../../models';

interface ToolBarSummaryProps<T = any> {
  hideHeaders?: boolean;
  headers?: MetaDataHeaders<T>[];
  data?: Dict;
  count?: number;
  title: string;
}

const ToolBarSummary: React.SFC<ToolBarSummaryProps> = (props) => {
  const renderSummary = () => {
    if (props.hideHeaders || (!props.hideHeaders && !props.count)) {
      return <></>;
    }

    if (props.headers && props.headers.length > 0) {
      return (
        <span className="ItemSummary__count">
          {props.headers.map((header) => {
            let value = findNestedObjectValue(props.data, header.source);

            if (header.formatValue) {
              value = header.formatValue(value);
            }
            if (header.label) {
              value = header.labelLeft
                ? `${header.label} ${value}`
                : `${value} ${header.label}`;
            }
            if (header.formatContent) {
              return header.formatContent(value);
            }
            return `${value || ''} `;
          })}
        </span>
      );
    }

    return (
      <span className="ItemSummary__count">
        {!props.hideHeaders ? `${props.count || 0} Total` : ' '}
      </span>
    );
  };
  return (
    <div className="ItemSummary">
      <h5 className="ItemSummary__item">{props.title}</h5>
      {renderSummary()}
    </div>
  );
};

export default ToolBarSummary;
