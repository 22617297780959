import React from 'react';

import './Upload.scss';

import addImageIcon from './add-image.png';

const getFileType = (type: string) => {
  switch (type) {
    case 'png':
    case 'jpeg':
      return `image/${type}`;
    case 'jpg':
      return `image/jpeg`;
    case 'doc':
    case 'docx':
      return 'application/msword';
    case 'pdf':
      return 'application/pdf';
    case 'xls':
    case 'xlsx':
      return 'application/vnd.ms-excel';
    default:
      return type;
  }
};

interface CustomProps {
  name: string;
  id: string;
  onFileChosen?: (file?: File) => void;
  disabled?: boolean;
  title?: string;
  description?: string;
  accept?: string[];
}

const FileUpload: React.SFC<CustomProps> = (props: CustomProps) => {
  const handleChange = (selectedFiles: FileList) => {
    let file: File | undefined;
    if (selectedFiles && selectedFiles.length > 0) {
      file = selectedFiles[0];
    }
    if (props.onFileChosen) {
      props.onFileChosen(file);
    }
  };
  return (
    <>
      <label className="fieldlabel">{props.title}</label>
      <label className="bg-white uploading-group-inn rounded d-flex  align-items-center cursor-pointer add-img-file px-3 pt-3 pb-2">
        <div className="align-items-center d-flex">
          <div className="px-2">
            <img src={addImageIcon} className="pb-1" />
          </div>
          <span className="font-small m-0 pb-2 text-gray-900">
            Add Image (max size 2MB)
          </span>
          <input
            type="file"
            id={props.id}
            onChange={(e: any) => handleChange(e.target.files)}
            disabled={props.disabled}
            hidden
            accept={
              props.accept
                ? props.accept.map((t) => getFileType(t)).join(', ')
                : undefined
            }
          />
        </div>
      </label>
    </>
  );
};

export default FileUpload;
