import { Dict } from '../../models';
import { SubscriptionPlan } from './subscription-plan';
import { AccountType, CreatedBy } from './user';

export interface VerifyPost {
  email: string;
  tokenId?: string;
  invoicenumber?: string;
  subscriptionPlanId?: string;
  isCustomPlan?: boolean;
}
export interface Payment {
  userId: string;
  email: string;
  amountPaid: number;
  tokenId?: string;
  invoiceNumber?: string;
  accountType: AccountType;
  serviceDetails?: ServiceDetails;
  status: PaymentStatus;
  paymentMode?: PaymentMode;
  paymentType?: PaymentType;
  referenceNumber?: number;
  authorizationNumber?: number;
  paymentDetails?: PaymentDetails;
  createdBy?: CreatedBy;
  currency?: string;
  createdAt: Date;
  subscriptionPlan?: SubscriptionPlan;
  productDetails?: any;
  stripePaymentDetails?:any
}
interface ServiceDetails {
  id: string;
  title: string;
}
export enum PaymentStatus {
  Pending = 'pending',
  Success = 'success',
  Cancelled = 'cancelled',
  Failed = 'failed',
}

export enum PaymentMode {
  Cash = 'cash',
  Online = 'online',
  Cheque = 'cheque',
}

export enum PaymentType {
  Subscription = 'subscription',
  Purchase = 'purchase',
}
interface PaymentDetails {
  result?: number;
  message?: string;
  referenceNumber?: string;
  authorizationNumber?: string;
  amount?: number;
  cardType?: string;
  invoiceNumber?: string;
}
