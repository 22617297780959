import { SideMenuItem } from './interfaces';

export const isActive = (pathname: string, menuItem: SideMenuItem): boolean => {
  if (!pathname || !menuItem) {
    return false;
  }
  if (!menuItem.link) {
    if (menuItem.subMenu && menuItem.subMenu.length > 0) {
      return (
        menuItem.subMenu.find((mi) => isActive(pathname, mi)) !== undefined
      );
    }
    return false;
  }
  if (
    menuItem.link.toLowerCase() === pathname.toLowerCase() ||
    pathname.toLowerCase().startsWith(menuItem.link.toLowerCase())
  ) {
    return true;
  }

  return false;
};

export const isMenuActive = (pathname: string, link: string): boolean => {
  if (!pathname || !link) {
    return false;
  }
  if (pathname === '/' && (link === 'dashboard' || link === '/')) {
    return true;
  } else if (link.toLowerCase() === pathname.toLowerCase()) {
    return true;
  }

  return false;
};

export const isSubMenuActive = (pathname: string, link: string): boolean => {
  if (!pathname || !link) {
    return false;
  }

  if (link.includes(pathname.toLowerCase())) {
    return true;
  }

  return false;
};
