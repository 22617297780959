/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import './SideMenu.scss';
import { SIDE_MENU_ITEMS } from './menu-items';
import MenuItem from './MenuItem';
import { useLocation } from 'react-router-dom';
import { isActive } from './utils';
import Logo from '../logo';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../store/interfaces';
import { actions as uiActions } from '../../store/reducers/ui-data';
import PermissionCheckView from '../permission-check/PermissionCheckView';

const SideMenu: React.SFC = () => {
  const uiData = useSelector((state: State) => state.uiData);

  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`sidemenu ${uiData.sideMenuToggleStatus ? 'active' : ''}`}
      >
        <div
          className={`sidemenu__close sidemenu__close--open ${
            uiData.sideMenuToggleStatus ? 'active' : ''
          }`}
        >
          <a
            href="#"
            className="close"
            onClick={() => dispatch(uiActions.toggleSideMenu(false))}
          >
            <span className="close__icon icon-cancel"></span>
          </a>
        </div>

        <ul className={`menu`}>
          {SIDE_MENU_ITEMS.map((menuItem) => {
            return (
              <PermissionCheckView permission={menuItem.permission}>
                <MenuItem
                  icon={menuItem.icon}
                  link={menuItem.link}
                  label={menuItem.label}
                  menuKey={menuItem.menuKey}
                  active={isActive(location.pathname, menuItem)}
                  subMenu={menuItem.subMenu}
                />
              </PermissionCheckView>
            );
          })}
        </ul>
      </div>
      <div
        className={`black-drop ${uiData.sideMenuToggleStatus ? 'active' : ''}`}
        role="presentation"
      />
    </>
  );
};

export default SideMenu;
