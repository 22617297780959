import { MetaData } from '../../models';
import { RouteKeys } from './route-keys';

export const metaData: MetaData = {
  title: 'Roles',
  resource: 'role',
  source: 'data.data',
  route: RouteKeys.List,
  paginate: true,
  perPage: 10,
  page: 1,
};
