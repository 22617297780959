import moment from 'moment-timezone';
import React from 'react';
import { Dict } from '../../api/models';
import { StatusActionButton } from '../../components/actions/action-buttons';
import {
  CrudListFilterField,
  DateDataField,
  LinkField,
  TextDataField,
} from '../../components/data-fields';
import { DateInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import { TextView } from '../../components/view-fields';
import CrudListFilters from '../../layout/crud/components/CrudListFilters';
import CrudListSearch from '../../layout/crud/components/CrudListSearch';
import CrudListView from '../../layout/crud/CrudListView';
import PlainLayout from '../../layout/PlainLayout';
import { capitalizeFirstLetter } from '../../utils/text-utils';
import { metaData } from './metaData';

const ListView: React.FunctionComponent = () => {
  const filters: React.ReactNode[] = [
    <CrudListFilterField field="status" label="Status">
      <SelectInput
        data={[
          {
            name: 'Failed',
            value: 'failed',
          },
          {
            name: 'Pending',
            value: 'pending',
          },
          {
            name: 'Success',
            value: 'success',
          },
        ]}
        optionLabel="name"
        optionValue="value"
      />
    </CrudListFilterField>,
    <CrudListFilterField field="paymentType" label="Payment Type">
      <SelectInput
        data={[
          {
            name: 'Purchase',
            value: 'purchase',
          },
          {
            name: 'Subscription',
            value: 'subscription',
          },
        ]}
        optionLabel="name"
        optionValue="value"
      />
    </CrudListFilterField>,
    <CrudListFilterField
      field="dateFrom"
      label="From Date"
      relatedFieldValueProps={{
        maxDate: (fields: Dict) => fields.dateTo || new Date(),
      }}
    >
      <DateInput></DateInput>
    </CrudListFilterField>,

    <CrudListFilterField
      field="dateTo"
      label="To Date"
      relatedFieldValueProps={{
        minDate: (fields: Dict) => fields.dateFrom,
        maxDate: (fields: Dict) => new Date(),
      }}
    >
      <DateInput></DateInput>
    </CrudListFilterField>,
  ];

  const queryParams = {
    timeZone: moment.tz.guess(),
  };

  return (
    <PlainLayout>
      <CrudListView
        metaData={metaData}
        actions={[
          <CrudListSearch />,
          <CrudListFilters queryFilters>{filters}</CrudListFilters>,
        ]}
        queryParams={queryParams}
      >
        <LinkField source="invoiceNumber" label="Invoice">
          <TextView />
        </LinkField>
        <TextDataField source="createdBy.name" label="User"></TextDataField>
        <TextDataField
          source="status"
          label="Status"
          formatContent={(val: string) => capitalizeFirstLetter(val)}
        ></TextDataField>
        <TextDataField
          source="paymentType"
          label="Payment Type"
          formatContent={(val: string) => capitalizeFirstLetter(val)}
        ></TextDataField>
        <TextDataField
          source="stripePaymentDetails.id"
          label="TransactionID"
        ></TextDataField>
        <DateDataField
          source="createdAt"
          label="Created At"
          dateFormat="DD-MMM-YYYY hh:mm A"
        />
        <TextDataField
          source="amountPaid"
          label="Amount"
          fontLarge
        ></TextDataField>
        <StatusActionButton source="isActive" label="Status" />
      </CrudListView>
    </PlainLayout>
  );
};

export default ListView;
