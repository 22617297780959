import React, { useContext } from 'react';
import { DataTableFieldProps } from '../data-table';
import { TextView } from '../view-fields';
import { DataRecordContext, MetaDataContext } from '../../contexts';
import { resolveFieldValue } from '../utils';
import { MediaFile } from '../../api/models';

interface ImageDataFieldProps extends DataTableFieldProps<MediaFile> {
  nullValue?: string;
  height?: number;
  className?: string;
}

const ImageDataField: React.SFC<ImageDataFieldProps> = (props) => {
  const recordDetails = useContext(DataRecordContext);
  const metaData = useContext(MetaDataContext);
  const value: MediaFile | undefined = resolveFieldValue(
    recordDetails,
    props,
    metaData
  );

  if (!value?.path) {
    return <>{props.nullValue || ''}</>;
  }

  return <img src={value.path} height={props.height || 50} className={props.className || "img-fluid"} />;
};

export default ImageDataField;
