import React, { useContext } from 'react';

import { DataTableFieldProps, DataFieldLinkProps } from '../data-table';
import { findNestedObjectValue, resolveFieldValue } from '../utils';
import { Link, useLocation } from 'react-router-dom';
import url from 'url';
import { DataRecordContext, MetaDataContext } from '../../contexts';

interface LinkFieldProps<T = any> extends DataTableFieldProps<T> {
  linkProps?: DataFieldLinkProps;
  staticRoute?: boolean;
  routeParams?: string;
}

const LinkField: React.SFC<LinkFieldProps> = (props) => {
  const location = useLocation();
  let routeParamValue = '';
  const { route, routeSource, className, routeAction } = {
    ...props.linkProps,
  };
  const metaData = useContext(MetaDataContext);
  const recordDetails = useContext(DataRecordContext);
  const value = resolveFieldValue(recordDetails, props, metaData);
  if (props.routeParams) {
    const routeIds = props.routeParams.split('.');
    let routeParamObj: any;
    routeIds.forEach((e: any, index: number) => {
      if (index === 0) {
        routeParamObj = recordDetails.record[e];
      } else {
        routeParamObj = routeParamObj[e];
      }
    });
    routeParamValue = routeParamObj;
  }
  const { staticRoute } = props;

  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...props, ...child.props, value });
      }
      return child;
    });
  };

  const baseRoute = route || metaData?.route || location.pathname;

  let to = baseRoute;

  if (!staticRoute) {
    const routeValue = routeParamValue
      ? routeParamValue
      : routeSource
      ? findNestedObjectValue(recordDetails?.record, routeSource)
      : recordDetails?.recordId;

    if (!routeValue) {
      return <>{renderChildren()}</>;
    }
    to = url.resolve(`${baseRoute}/`, routeValue || '');
    if (routeAction) {
      to = url.resolve(`${to}/`, routeAction);
    }
  }

  return (
    <Link className={className || 'DataTableField'} to={to}>
      {renderChildren()}
    </Link>
  );
};

export default LinkField;
