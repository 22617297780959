import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Select from 'react-select';
import api from '../../../api';
import { CrudListRequestModel } from '../../../api/models';
import { Dict } from '../../../models/interfaces';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '53px',
  }),

  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '10',
  }),
  option: (provided: any, state: any) => {
    return {
      ...provided,
      background: state.isFocused ? '#f2f9f9' : '#fff',
      color: '#1d1d1d',
    };
  },
};

interface CustomProps {
  default?: any[];
  api?: string;
  data?: any[];
  placeholder?: string;
  label?: string;
  id?: string;
  optionLabel?: string;
  optionValue?: string;
  onChange: (value: any, selected: any) => void;
  gap?: 'sm' | 'md' | 'lg';
  // size: PropTypes.oneOf(['lg']),
  error?: string;
  name?: string;
  defaultIfNotFound?: any[];
  disabled?: boolean;
  stickyStyles?: {};
  isSearchable?: boolean;
  isRtl?: boolean;
  queryParams?: Dict;
  excludedValues?: (string | number)[];
}

interface CustomState {
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  selected: any[];
  options: any[];
  selectedValue?: any[];
}

class SelectMultipleInput extends Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    default: [],
    gap: 'md',
    placeholder: 'Select...',
    error: '',
    name: '',
    label: '',
    data: [],
    disabled: false,
    optionLabel: 'name',
    optionValue: '_id',
    stickyStyles: {},
    isSearchable: true,
    isRtl: false,
  };

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isLoading: false,
      isRtl: props.isRtl,
      isSearchable: props.isSearchable,
      selected: [],
      options: [],
      selectedValue: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    // const options = [];
    if (this.props.api) {
      const request: CrudListRequestModel = {
        resource: this.props.api,
        // pagination: {},
        sort: {},
        queryParams: this.props.queryParams,
      };
      this.toggleLoading();
      const { data } = await api.crud.getListWithoutPagination(request);
      this.toggleLoading();

      this.setState(
        {
          options: data?.data ? data?.data : data,
        },
        () => {
          this.updateDefault();
        }
      );
    } else {
      // console.log('dataList: ', this.props.data);
      this.setState(
        (prevState: CustomState): CustomState => ({
          ...prevState,
          options: this.props.data || [],
        })
      );
    }
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  toggleLoading = () =>
    this.setState((state) => ({ isLoading: !state.isLoading }));

  toggleRtl = () => this.setState((state) => ({ isRtl: !state.isRtl }));

  toggleSearchable = () =>
    this.setState((state) => ({ isSearchable: !state.isSearchable }));

  updateDefault() {
    // console.log('this.props.default(SelectInput): ', this.props.default);
    const passedDefaultValues = this.props.default;

    if (passedDefaultValues && passedDefaultValues.length) {
      this.state.options.forEach((option) => {
        //  console.log('option: ', option);

        const passedDefaultValueIndex = passedDefaultValues.indexOf(
          option[this.props.optionValue || '_id']
        );
        if (passedDefaultValueIndex !== -1) {
          const result = this.state.selected.filter(
            (x) =>
              x[this.props.optionValue || '_id'] ===
              option[this.props.optionValue || '_id']
          );
          if (!result.length) {
            this.setState((prevState) => ({
              selected: [...prevState.selected, option],
            }));
          }
        }
      });

      this.setState({ selectedValue: this.props.default });
    }
  }

  handleChange(selected: any[]) {
    // console.log('selected: ', selected);

    // const value = event.value;
    const selectedValues = selected
      ? selected.map((option) => option[this.props.optionValue || '_id'])
      : [];
    this.setState({ selected, selectedValue: selectedValues });
    this.props.onChange(selectedValues, selected);
  }

  showValue() {
    return this.state.options.filter((option) => {
      let optionMatched = false;
      if (this.state.selected) {
        for (const selected of this.state.selected) {
          if (
            option[this.props.optionValue || '_id'] ===
            selected[this.props.optionValue || '_id']
          ) {
            optionMatched = true;
            break;
          }
        }
      }
      // console.log('match: ', optionMatched);
      return optionMatched;
    });
  }

  render() {
    const {
      gap,
      name,
      label,
      placeholder,
      stickyStyles,
      error,
      id,
      excludedValues,
    } = this.props;
    const { isSearchable, isLoading, isRtl } = this.state;
    const inputIdCandidate = id || name || label || placeholder;
    const inputId = inputIdCandidate;
    let { options } = this.state;
    if (excludedValues) {
      options = options.filter(
        (op) => !excludedValues.includes(op[this.props.optionValue || '_id'])
      );
    }
    return (
      <div className={`Input Input--gap-${gap}`} style={stickyStyles}>
        {label && (
          <label htmlFor={inputId} className="Input__label Input__label--full">
            {label}
          </label>
        )}
        <Select
          styles={customStyles}
          className="basic-multi-select"
          classNamePrefix="select"
          isLoading={isLoading}
          isRtl={isRtl}
          isSearchable={isSearchable}
          placeholder={this.props.placeholder}
          getOptionLabel={(option) => option[this.props.optionLabel || 'name']}
          getOptionValue={(option) => option[this.props.optionValue || '_id']}
          options={options}
          onChange={this.handleChange}
          value={this.showValue()}
          isDisabled={this.props.disabled ? this.props.disabled : false}
          id={inputId}
          isMulti={true}
        />
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

export default SelectMultipleInput;
