import { RouteKeys } from '../../containers/routes/route-keys';
import { SideMenuItem } from './interfaces';

export const SIDE_MENU_ITEMS: SideMenuItem[] = [
  {
    label: 'Dashboard',
    link: RouteKeys.Dashboard,
    icon: 'Dashboard',
    menuKey: 'dashboard',
  },
  {
    label: 'Entity',
    icon: 'Admin-User',
    menuKey: 'entity',
    subMenu: [
      {
        label: 'Category',
        link: RouteKeys.Category,
        icon: 'Category',
        menuKey: 'category',
        // permission: Permissions.ViewProjects,
      },
      {
        label: 'Subcategory',
        link: RouteKeys.Subcategory,
        icon: 'Subcategory',
        menuKey: 'subcategory',
        // permission: Permissions.ViewResources,
      },
      {
        label: 'Brand',
        link: RouteKeys.Brand,
        icon: 'Brand',
        menuKey: 'brand',
        // permission: Permissions.ViewProjects,
      },
    ],
  },
  {
    label: 'Sale',
    link: RouteKeys.Product,
    icon: 'Products',
    menuKey: 'sale',
  },
  {
    label: 'Auctions',
    link: RouteKeys.Auctions,
    icon: 'Products',
    menuKey: 'auctions',
  },
  {
    label: 'Leads',
    link: RouteKeys.Leads,
    icon: 'lead',
    menuKey: 'leads',
  },
  {
    label: 'User',
    link: RouteKeys.User,
    icon: 'Admin-User',
    menuKey: 'user',
    // permission: Permissions.ViewProjects,
  },
  {
    label: 'Subscription Plan',
    link: RouteKeys.SubscriptionPlan,
    icon: 'Subscription-Plan',
    menuKey: 'subscription-plan',
  },
  {
    label: 'Custom Plan Request',
    link: RouteKeys.SubscriptionCustomPlanRequests,
    icon: 'call-us',
    menuKey: 'subscription-plan',
  },
  {
    label: 'Payments',
    link: RouteKeys.Payment,
    icon: 'Payment',
    menuKey: 'payment',
    // permission: Permissions.ViewProjects,
  },
  {
    label: 'Payout',
    link: RouteKeys.Payout,
    icon: 'Payout',
    menuKey: 'payout',
    // permission: Permissions.ViewProjects,
  },
  {
    label: 'ReportedUsers',
    link: RouteKeys.ReportedUsers,
    icon: 'Admin-User',
    menuKey: 'user',
    // permission: Permissions.ViewProjects,
  },
  {
    label: 'ReportedAds',
    link: RouteKeys.ReportedAds,
    icon: 'Products',
    menuKey: 'sale',
    // permission: Permissions.ViewProjects,
  },
  {
    label: 'Banner Management',
    link: RouteKeys.BannerManagement,
    icon: 'Banner-Management',
    menuKey: 'banner-management',
  },
  {
    label: 'Pages',
    link: RouteKeys.Pages,
    icon: 'Banner-Management',
    menuKey: 'page-management',
  },

  {
    label: 'Settings',
    icon: 'Permission',
    menuKey: 'settings',
    subMenu: [
      {
        label: 'Admin User',
        link: RouteKeys.AdminUser,
        icon: 'Admin-User',
        menuKey: 'admin',
        // permission: Permissions.ViewProjects,
      },
      {
        label: 'Roles',
        link: RouteKeys.Role,
        icon: 'Roles',
        menuKey: 'role',
        // permission: Permissions.ViewProjects,
      },
      {
        label: 'Permissions',
        link: RouteKeys.Permission,
        icon: 'Permission',
        menuKey: 'permission',
        // permission: Permissions.ViewProjects,
      },
      {
        label: 'CompanyDetails',
        link: RouteKeys.CompanyDetails,
        icon: 'Permission',
        menuKey: 'permission',
        // permission: Permissions.ViewProjects,
      },
    ],
  },

  // {
  //   label: 'Resources',
  //   link: RouteKeys.Resources,
  //   icon: 'resources',
  //   menuKey: 'resources',
  //   permission: Permissions.ViewResources,
  // },
  // {
  //   label: 'Leaves',
  //   link: RouteKeys.Leaves,
  //   icon: 'hour-logs',
  //   menuKey: 'leaves',
  //   permission: Permissions.ViewLeaves,
  // },
  // {
  //   label: 'Reports',
  //   icon: 'reports',
  //   menuKey: 'reports',
  //   subMenu: [
  //     {
  //       label: 'Plan vs Actual',
  //       link: RouteKeys.PlanVsActual,
  //       icon: 'plan-vs-actual',
  //       permission: Permissions.ViewPlanVsActual,
  //     },
  //     {
  //       label: 'Resource Schedule',
  //       link: ReportsRouteKeys.ResourceScheduleView,
  //       icon: 'plan-vs-actual',
  //       permission: Permissions.ViewResourceScheduleView,
  //     },
  //   ],
  // },
  // {
  //   label: 'Plans',
  //   link: RouteKeys.Plans,
  //   icon: 'plans',
  //   menuKey: 'plans',
  //   permission: Permissions.ViewPlans,
  // },
  // {
  //   label: 'Hour Logs',
  //   link: RouteKeys.HourLogs,
  //   icon: 'hour-logs',
  //   menuKey: 'hour-logs',
  //   subMenu: [
  //     {
  //       label: 'Jira Hour Logs',
  //       link: RouteKeys.HourLogs,
  //       icon: 'no-icon',
  //       permission: Permissions.ViewHourLogs,
  //     },
  //     {
  //       label: 'All Resources',
  //       link: RouteKeys.ResourceHourLogs,
  //       icon: 'no-icon',
  //       permission: Permissions.ViewHourLogs,
  //     },
  //   ],
  // },
  // {
  //   label: 'Admin Users',
  //   link: RouteKeys.AdminUsers,
  //   icon: 'admin-users',
  //   menuKey: 'admin-users',
  //   permission: Permissions.ViewAdminUsers,
  // },
  // {
  //   label: 'Teams',
  //   link: RouteKeys.Teams,
  //   icon: 'teams',
  //   menuKey: 'teams',
  // },
  // {
  //   label: 'Settings',
  //   icon: 'system-configuration',
  //   menuKey: 'settings',
  //   subMenu: [
  //     {
  //       label: 'Skills',
  //       link: RouteKeys.Skills,
  //       icon: 'skills',
  //       menuKey: 'skills',
  //       permission: Permissions.ViewSkills,
  //     },
  //     {
  //       label: 'Budget Types',
  //       link: RouteKeys.BudgetTypes,
  //       icon: 'budget-type',
  //       menuKey: 'budget-types',
  //       permission: Permissions.ViewBudgetTypes,
  //     },
  //     {
  //       label: 'Access Logs',
  //       link: RouteKeys.AccessLogs,
  //       icon: 'access-logs',
  //       permission: Permissions.ViewApiAccessLogs,
  //     },
  //     {
  //       label: 'Api Roles',
  //       link: RouteKeys.ApiRoles,
  //       icon: 'api-roles',
  //       menuKey: 'api-roles',
  //       permission: Permissions.ViewApiRoles,
  //     },
  //     {
  //       label: 'Api Permissions',
  //       link: RouteKeys.ApiPermissions,
  //       icon: 'api-permissions',
  //       menuKey: 'api-permission',
  //       permission: Permissions.ViewApiPermissions,
  //     },
  //   ],
  // },
];
