import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';

import authUser from './reducers/authUser';
import { State } from './interfaces';
import uiData from './reducers/ui-data';
import entitiesReducer from './reducers/entity';

const rootReducer = combineReducers<State>({
  uiData,
  authUser,
  toastr: toastrReducer,
  loadingBar: loadingBarReducer,
  entityState: entitiesReducer,
});

export default rootReducer;
