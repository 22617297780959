import React, { useState, useContext } from 'react';
import {
  FilterDropdown,
  FilterDropdownSelect,
  FilterDropdownMenu,
  FilterDropdownItem,
} from '../../../components/filters';
import { CrudListDataLoaderContext } from '../../../data-loader-components/CrudListDataLoader';
import { Dict } from '../../../models';

export interface FilterOptions {
  noReload?: boolean;
}

interface CrudListFiltersProps {
  queryFilters?: boolean;
}

const CrudListFilters: React.SFC<CrudListFiltersProps> = (props) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const crudListContext = useContext(CrudListDataLoaderContext);

  const crudFilters = props.queryFilters
    ? crudListContext.crudRequest?.queryParams || {}
    : crudListContext.crudRequest?.filter || {};

  const filterComponents = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return {
        field: child.props.field,
      };
    }
    return undefined;
  })?.filter((child) => child);

  const onFilterToggle = (filter: string) => {
    setSelectedFilter(selectedFilter === filter ? '' : filter);
  };

  const crudFiltersCount = Object.keys(crudFilters).filter((f) =>
    filterComponents?.find((field) => field.field === f)
  ).length;

  const onFilterChange = (
    field: string,
    value: any,
    options?: FilterOptions
  ) => {
    if (props.queryFilters) {
      crudListContext.setCrudQueryParams(
        {
          [field]: value,
        },
        { noReload: options?.noReload }
      );
    } else {
      crudListContext.setCrudFilters(
        { [field]: value },
        { noReload: options?.noReload }
      );
    }
  };
  const onFilterRemove = (field: string, options?: FilterOptions) => {
    if (props.queryFilters) {
      crudListContext.removeCrudQueryParams(field, {
        noReload: options?.noReload,
      });
    } else {
      crudListContext.removeCrudFilters(field, { noReload: options?.noReload });
    }
  };

  const renderFiters = () => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        const filter = child.props;
        const isOpen = filter.field === selectedFilter;
        const defaultValue = crudFilters[filter.field];
        const { disableClose } = filter;
        const childProps: Dict = filter.childProps || {};

        if (filter.relatedFieldValueProps) {
          for (const prop in filter.relatedFieldValueProps) {
            if (filter.relatedFieldValueProps.hasOwnProperty(prop)) {
              childProps[prop] = filter.relatedFieldValueProps[prop](
                crudFilters
              );
            }
          }
        }
        return (
          <FilterDropdownItem
            key={filter.field}
            label={filter.label}
            onClick={() => onFilterToggle(filter.field)}
            isOpen={isOpen}
          >
            {React.cloneElement(child, {
              ...filter,
              childProps,
              onChange: (value: any) =>
                onFilterChange(filter.field, value, child.props.options),
              default: defaultValue,
            })}
            {!disableClose && defaultValue !== undefined && (
              <button
                type="button"
                className="filter__item-remove"
                onClick={() =>
                  onFilterRemove(filter.field, child.props.options)
                }
              >
                <i className="icon-cancel" />
              </button>
            )}
          </FilterDropdownItem>
        );
      }
      return child;
    });
  };

  return (
    <>
      <FilterDropdown onClick={() => setShowFilterMenu(!showFilterMenu)}>
        <FilterDropdownSelect cueType="down">FILTER</FilterDropdownSelect>
        {crudFiltersCount > 0 && (
          <span className="badge">{crudFiltersCount}</span>
        )}
      </FilterDropdown>
      {showFilterMenu && (
        <div className="filter">
          <FilterDropdownMenu>{renderFiters()}</FilterDropdownMenu>
        </div>
      )}
    </>
  );
};

export default CrudListFilters;
