import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../components/button';
import { TextInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import SwitchInput from '../../components/form-group/switch-input';
import { getEnumValues } from '../../utils/enum-utils';
import {
  PostType,
  SubscriptionPlan,
  SubscriptionPeriod,
  AccountType,
  ProductType,
  CrudListRequestModel,
} from '../../api/models';
import SelectMultipleInput from '../../components/form-group/SelectMultipleInput';
import api from '../../api';
import SelectMultipleCheckbox from '../../components/form-group/SelectMultipleInput/SelectMultipleCheckbox';

const accountTypes = getEnumValues(AccountType).map((accountType: string) => {
  return { name: accountType };
});

const productTypes = getEnumValues(ProductType).map((productType: string) => {
  return { name: productType };
});
const subscriptionPeriods = getEnumValues(SubscriptionPeriod).map(
  (period: string) => {
    return { name: period };
  }
);

const postTypes = getEnumValues(PostType).map((postType: string) => {
  return { name: postType };
});

const CreateSubscriptionPlanForm: React.FunctionComponent<{
  onSubmit: (data: SubscriptionPlan) => void;
  default?: SubscriptionPlan;
  errorMessage?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const { handleSubmit, errors, control, watch, setValue } = useForm<
    SubscriptionPlan
  >({
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: props.default,
  });

  const [isRecurring, setIsRecurring] = useState(
    props.default?.isRecurring ? props.default?.isRecurring : false
  );
  const [isBoostBackToTop, setIsBoostBackToTop] = useState(
    props.default?.isBoostBackToTop ? props.default?.isBoostBackToTop : false
  );
  const [isCustom, setIsCustom] = useState(
    props.default?.isCustom ? props.default?.isCustom : false
  );

  const [isSubCategoryDisabled, setIsSubCategoryDisabled] = useState(false);

  const [isTopFeatured, setIsTopFeatured] = useState(
    props.default?.isTopFeatured ? props.default?.isTopFeatured : false
  );
  const [planType, setPlanType] = useState<string>('');
  const [subCategory, setSubCategory] = useState<any[] | null>();
  const planTypeData = [
    { title: 'Custom', value: 'isCustom' },
    {
      title: 'Boost Back To Top',
      value: 'isBoostBackToTop',
    },
    { title: 'Top Featured', value: 'isTopFeatured' },
    { title: 'Recurring', value: 'isRecurring' },
    { title: 'Basic', value: 'isBasic' },
  ];
  const onSubmit = (data: SubscriptionPlan) => {
    if (data.planType === 'isRecurring') {
      data.isRecurring = true;
    }
    if (data.planType === 'isCustom') {
      data.isCustom = true;
    }
    if (data.planType === 'isBoostBackToTop') {
      data.isBoostBackToTop = true;
    }
    if (data.planType === 'isTopFeatured') {
      data.isTopFeatured = true;
    }
    if (data.planType === 'isBasic') {
      data.isBasic = true;
    }
    props.onSubmit({
      ...data,
    });
  };

  useEffect(() => {
    setValue('name', props.default?.name);
    setValue('postType', props.default?.postType);
    setValue('accountType', props.default?.accountType);
    setValue('noOfPosts', props.default?.noOfPosts || 1);
    setValue('amount', props.default?.amount);
    setValue('listingDays', props.default?.listingDays);
    setValue('isRecurring', props.default?.isRecurring);
    setValue('period', props.default?.period);
    setValue('isCustom', props.default?.isCustom);
    setValue('isBasic', props.default?.isBasic);
    setValue('isBoostBackToTop', props.default?.isBoostBackToTop);
    setValue('isTopFeatured', props.default?.isTopFeatured);
    if (props.default?.isRecurring) {
      setValue('planType', 'isRecurring');
    }
    if (props.default?.isCustom) {
      setValue('planType', 'isCustom');
    }
    if (props.default?.isBoostBackToTop) {
      setValue('planType', 'isBoostBackToTop');
    }
    if (props.default?.isTopFeatured) {
      setValue('planType', 'isTopFeatured');
    }
    if (props.default?.isBasic) {
      setValue('planType', 'isBasic');
    }
  }, [props.default, setValue]);

  const handleRecurringInput = (value: boolean) => {
    setIsRecurring(value);
    setIsTopFeatured(false);
    setIsBoostBackToTop(false);
    setIsCustom(false);
    if (!value) {
      setValue('period', undefined);
    }
  };

  const handleBasicInput = (value: boolean) => {
    // setIsRecurring(value);
    setIsRecurring(false);
    setIsTopFeatured(false);
    setIsBoostBackToTop(false);
    setIsCustom(false);
    // if (!value) {
    //   setValue('period', undefined);
    // }
  };

  const handleBoostBackInput = (value: boolean) => {
    setIsBoostBackToTop(value);
    setIsRecurring(false);
    setIsCustom(false);
    setIsTopFeatured(false);
  };

  const handleCustomInput = (value: boolean) => {
    setIsCustom(value);
    setIsRecurring(false);
    setIsBoostBackToTop(false);
    setIsTopFeatured(false);
  };

  const handleTopFeaturedInput = (value: boolean) => {
    setIsTopFeatured(value);
    setIsRecurring(false);
    setIsBoostBackToTop(false);
    setIsCustom(false);
  };

  const subCategoryList = (api: string | undefined) => {
    return (
      <div className="col-sm-6 col-12">
        <div className="textField">
          <Controller
            control={control}
            name="subCategories"
            defaultValue={props.default?.subCategories}
            rules={{ required: 'SubCategories is Required' }}
            render={({ onChange, value, name }) => (
              <SelectMultipleCheckbox
                label="SubCategories"
                placeholder="Select SubCategories"
                data={subCategory ? subCategory : []}
                api={api}
                optionLabel="title"
                optionValue="_id"
                onChange={(val) => {
                  onChange(val);
                  setValue('subCategories', val);
                }}
                name={name}
                default={value}
                disabled={isSubCategoryDisabled}
              />
            )}
          ></Controller>
        </div>
      </div>
    );
  };

  const getSubCategories = (ids: any) => {
    setSubCategory(null);
    setIsSubCategoryDisabled(true);
    var subCategoryFetch = async function () {
      const url = 'subcategory';
      // const url = props.default?._id
      //   ? `category/subscription-plan/${props.default?._id}/sub-category-select-list`
      //   : 'subcategory';
      const request: CrudListRequestModel = {
        resource: url,
        sort: {},
      };
      const { data } = await api.crud.getListWithoutPagination(request);

      const allFilteredSubCategories = data.data.filter((subCategory: any) =>
        ids.includes(subCategory.categoryId)
      );

      setSubCategory(allFilteredSubCategories);
      setIsSubCategoryDisabled(false);
    };

    subCategoryFetch();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-xl-10 col-12">
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className="textField">
                  <Controller
                    control={control}
                    name="planType"
                    defaultValue={planType}
                    rules={{ required: 'PlanType is Required' }}
                    render={({ onChange, value, name }) => (
                      <SelectInput
                        label="Plan Type"
                        placeholder="Select PlanType"
                        onChange={(val) => {
                          onChange(val);
                          setPlanType(val);
                          val === 'isCustom'
                            ? handleCustomInput(true)
                            : val === 'isRecurring'
                            ? handleRecurringInput(true)
                            : val === 'isBasic'
                            ? handleBasicInput(true)
                            : val === 'isBoostBackToTop'
                            ? handleBoostBackInput(true)
                            : handleTopFeaturedInput(true);
                        }}
                        name={name}
                        default={value}
                        optionLabel="title"
                        optionValue="value"
                        data={planTypeData}
                        disabled={!!props.default}
                      />
                    )}
                  ></Controller>
                  {/* {!isBoostBackToTop && !isTopFeatured && (
                    <div className="col-auto mb-2">
                      <div className="textField">
                        <Controller
                          control={control}
                          name="isCustom"
                          defaultValue={props.default?.isCustom}
                          render={({ onChange, value, name }) => (
                            <SwitchInput
                              label="Custom"
                              placeholder="Custom"
                              error={errors.isCustom?.message}
                              onChange={(val) => {
                                onChange(val);
                                handleCustomInput(val);
                              }}
                              name={name}
                              default={value}
                            />
                          )}
                        ></Controller>
                      </div>
                    </div>
                  )}
                  {!isCustom && !isTopFeatured && (
                    <div className="col-auto mb-2">
                      <div className="textField">
                        <Controller
                          control={control}
                          name="isBoostBackToTop"
                          defaultValue={props.default?.isBoostBackToTop}
                          render={({ onChange, value, name }) => (
                            <SwitchInput
                              label="Boost Back To Top"
                              placeholder="Boost Back To Top"
                              error={errors.isBoostBackToTop?.message}
                              onChange={(val) => {
                                onChange(val);
                                handleBoostBackInput(val);
                              }}
                              name={name}
                              default={value}
                            />
                          )}
                        ></Controller>
                      </div>
                    </div>
                  )}
                  {!isCustom && !isBoostBackToTop && (
                    <div className="col-auto mb-2">
                      <div className="textField">
                        <Controller
                          control={control}
                          name="isTopFeatured"
                          defaultValue={props.default?.isTopFeatured}
                          render={({ onChange, value, name }) => (
                            <SwitchInput
                              label="Top Featured"
                              placeholder="Top Featured"
                              error={errors.isTopFeatured?.message}
                              onChange={(val) => {
                                onChange(val);
                                handleTopFeaturedInput(val);
                              }}
                              name={name}
                              default={value}
                            />
                          )}
                        ></Controller>
                      </div>
                    </div>
                  )}
                  {!isCustom && !isBoostBackToTop && !isTopFeatured && (
                    <div className="col-auto mb-2">
                      <div className="textField">
                        <Controller
                          control={control}
                          name="isRecurring"
                          defaultValue={props.default?.isRecurring}
                          render={({ onChange, value, name }) => (
                            <SwitchInput
                              label="Recurring"
                              placeholder="Recurring"
                              error={errors.isRecurring?.message}
                              onChange={(val) => {
                                onChange(val);
                                handleRecurringInput(val);
                              }}
                              name={name}
                              default={value}
                            />
                          )}
                        ></Controller>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              {!isCustom && isRecurring && (
                <div className="col-sm-6 col-12">
                  <div className="textField">
                    <Controller
                      control={control}
                      name="period"
                      defaultValue={props.default?.period}
                      rules={{ required: 'Period is Required' }}
                      render={({ onChange, value, name }) => (
                        <SelectInput
                          label="Period"
                          placeholder="Select Period"
                          error={errors.period?.message}
                          onChange={(val) => {
                            onChange(val);
                            setValue(
                              'period',
                              subscriptionPeriods?.find(
                                (item) => item.name === val
                              )?.name
                            );
                          }}
                          name={name}
                          default={value}
                          optionLabel="name"
                          optionValue="name"
                          data={subscriptionPeriods}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              )}
              <div className="col-sm-6 col-12">
                <div className="textField">
                  <Controller
                    control={control}
                    name="name"
                    rules={{ required: 'Name is Required' }}
                    defaultValue={props.default?.name}
                    render={({ onChange, value, name }) => (
                      <TextInput
                        label="Name"
                        placeholder="Name"
                        error={errors.name?.message}
                        onChange={onChange}
                        name={name}
                        default={value}
                      />
                    )}
                  ></Controller>
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="textField">
                  <Controller
                    control={control}
                    name="accountType"
                    defaultValue={props.default?.accountType}
                    rules={{ required: 'Account Type is Required' }}
                    render={({ onChange, value, name }) => (
                      <SelectInput
                        label="Account Type"
                        placeholder="Select Account Type"
                        error={errors.accountType?.message}
                        onChange={(val) => {
                          onChange(val);
                          setValue(
                            'accountType',
                            accountTypes?.find((item) => item.name === val)
                              ?.name
                          );
                        }}
                        name={name}
                        default={value}
                        optionLabel="name"
                        optionValue="name"
                        data={accountTypes}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="textField">
                  <Controller
                    control={control}
                    name="productType"
                    defaultValue={props.default?.accountType}
                    rules={{ required: 'Product Type is Required' }}
                    render={({ onChange, value, name }) => (
                      <SelectInput
                        label="Product Type"
                        placeholder="Select Product Type"
                        error={errors.productType?.message}
                        onChange={(val) => {
                          onChange(val);
                          setValue(
                            'productType',
                            productTypes?.find((item) => item.name === val)
                              ?.name
                          );
                        }}
                        name={name}
                        default={value}
                        optionLabel="name"
                        optionValue="name"
                        data={productTypes}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="textField">
                  <Controller
                    control={control}
                    name="categories"
                    defaultValue={props.default?.categories}
                    rules={{ required: 'Categories is Required' }}
                    render={({ onChange, value, name }) => (
                      <SelectMultipleCheckbox
                        label="Categories"
                        placeholder="Select Categories"
                        api={
                          props.default?._id
                            ? `category/subscription-plan/${props.default?._id}/category-select-list`
                            : 'category'
                        }
                        optionLabel="title"
                        optionValue="_id"
                        onChange={(val) => {
                          onChange(val);
                          getSubCategories(val);
                          setValue('categories', val);
                        }}
                        name={name}
                        default={value}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
              {subCategory && subCategoryList(undefined)}
              {!subCategory &&
                // subCategoryList(
                //   props.default?._id
                //     ? `category/subscription-plan/${props.default?._id}/sub-category-select-list`
                //     : 'subcategory'
                subCategoryList('subcategory')}
              {!isCustom && (
                <div className="col-sm-6 col-12">
                  <div className="textField">
                    <Controller
                      control={control}
                      name="postType"
                      defaultValue={props.default?.postType}
                      rules={{ required: 'Post Type is Required' }}
                      render={({ onChange, value, name }) => (
                        <SelectInput
                          label="Post Type"
                          placeholder="Select Post Type"
                          error={errors.postType?.message}
                          onChange={(val) => {
                            onChange(val);
                            setValue(
                              'postType',
                              postTypes?.find((item) => item.name === val)?.name
                            );
                          }}
                          name={name}
                          default={value}
                          optionLabel="name"
                          optionValue="name"
                          data={postTypes}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              )}
              {!isCustom && (
                <div className="col-sm-6 col-12">
                  <div className="textField">
                    <Controller
                      control={control}
                      name="noOfPosts"
                      rules={{ required: 'No.of Posts is Required' }}
                      defaultValue={props.default?.noOfPosts}
                      render={({ onChange, value, name }) => (
                        <TextInput
                          label="No.of Posts"
                          placeholder="No.of Posts"
                          error={errors.noOfPosts?.message}
                          onChange={(val) => {
                            onChange(val);
                            setValue('noOfPosts', parseFloat(val));
                          }}
                          name={name}
                          default={
                            isBoostBackToTop || isTopFeatured ? 1 : value
                          }
                          type="number"
                          disabled={isBoostBackToTop || isTopFeatured}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              )}
              {!isCustom && (
                <div className="col-sm-6 col-12">
                  <div className="textField">
                    <Controller
                      control={control}
                      name="amount"
                      rules={{ required: 'Amount is Required' }}
                      defaultValue={props.default?.amount}
                      render={({ onChange, value, name }) => (
                        <TextInput
                          label="Amount"
                          placeholder="Amount"
                          error={errors.amount?.message}
                          onChange={(val) => {
                            onChange(val);
                            setValue('amount', parseFloat(val));
                          }}
                          name={name}
                          default={value}
                          type="number"
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              )}
              {!isCustom && (
                <div className="col-sm-6 col-12">
                  <div className="textField">
                    <Controller
                      control={control}
                      name="listingDays"
                      rules={{ required: 'Listing Days is Required' }}
                      defaultValue={props.default?.listingDays}
                      render={({ onChange, value, name }) => (
                        <TextInput
                          label="Listing Days"
                          placeholder="Listing Days"
                          error={errors.listingDays?.message}
                          onChange={(val) => {
                            onChange(val);
                            setValue('listingDays', parseFloat(val));
                          }}
                          name={name}
                          default={value}
                          type="number"
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              )}
              <div className="col-12">
                <div className="mb-2 mt-2">
                  {props.errorMessage && (
                    <span className="message_validation">
                      {props.errorMessage}
                    </span>
                  )}
                </div>
                <div className="button-outer">
                  <Button type="submit" disabled={props.isSubmitting}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateSubscriptionPlanForm;
