import React from 'react';
import './Upload.scss';

import uploadedIcon from './file-upload-done.svg';
import { Button } from '../button';

interface CustomProps {
  id: string;
  title: string;
  description?: string;
  filename: string;
  fileSize: string;
  onClose?: () => void;
  disabled?: boolean;
  filePath?: string;
  showIcon?: boolean;
}

const FileUploaded: React.SFC<CustomProps> = (props: CustomProps) => {
  const path = props.showIcon ? uploadedIcon : props.filePath
  return (
    <>
      <label className="fieldlabel">{props.title}</label>
      <div className="uploadfile uploadfile--border uploadfile--curve uploadfile--spacing uploaded">
        <div className="uploadfile__outer">
          <label className="uploadfile__label">
            <span className="uploadfile__filename">
              <div className="uploadfile__icon px-2">
                <img src={path || uploadedIcon} alt="" />
              </div>
              <span className="uploadfile__filename-outer">
                <span className="uploadfile__filename-text">
                  {props.filename}
                </span>
                <span className="uploadfile__filename-subtext">
                  {props.fileSize}
                </span>
              </span>
            </span>
          </label>
          {!props.disabled && (
            <Button type="button" btnLink onClick={props.onClose}>
              <span className="uploadfile__close">
                <i className="icon-close uploadfile__close-icon"></i>
              </span>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default FileUploaded;
