import { ProductDetails } from './catalog';
import { MediaFile } from './media';
import { AccountType, BankDetails, BusinessDetails, CreatedBy } from './user';

export interface Payout {
  requestedBy: string;
  requestedUser?: {
    id?: string;
    name?: string;
    avatar?: MediaFile;
    businessDetails?: BusinessDetails;
    address?: Location;
    email: string;
    phone: string;
  };
  productDetails: ProductDetails[];
  totalAmount: number;
  totalCommission: number;
  totalPayoutAmount: number;
  currency: string;
  accountType?: AccountType;
  status?: PayoutStatus;
  payoutNumber: string;
  comment?: string;
  processedBy?: CreatedBy;
  payoutProcessedOn?: Date;
  numberOfProducts: number;
  paypalPayoutDetails: {};
  payoutPaymentDetails: PayoutPaymentDetails;
  createdAt: Date;
  soldOn?: Date;
  deliveredOn?: Date;
}

export interface PayoutPaymentDetails {
  paymentMode: string;
  // payoutMode: string;
  paypalRecipient?: string;
  bankDetails?: BankDetails;
}
export enum PayoutStatus {
  Requested = 'requested',
  Processed = 'processed',
  Rejected = 'rejected',
  Pending = 'pending',
}
