import React from 'react';
import './Search.scss';

interface SearchProps {
  default?: string;
  placeHolder?: string;
  onSearch?: (value?: string) => void;
  direction?: 'left' | 'right';
}

interface SearchState {
  value?: string;
}

class Search extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onClear = this.onClear.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }
  onClear() {
    this.setState({ value: '' });
    if (this.props.onSearch) {
      this.props.onSearch('');
    }
  }

  componentDidUpdate(prevProps: SearchProps) {
    if (this.props.default && prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    // console.log('2. this.props.default(TextInput): ', this.props.default);
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.setState({ value });
  }

  handleSearch() {
    if (this.props.onSearch) {
      this.props.onSearch(this.state.value);
    }
  }

  handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13) {
      this.handleSearch();
    }
  }

  render() {
    if (this.props.direction !== 'right') {
      return (
        <div className="search">
          <button className="search__button" onClick={this.handleSearch}>
            <i className="icon-search" />
          </button>
          <input
            type="text"
            className="search__input"
            placeholder={this.props.placeHolder}
            onChange={this.handleChange}
            onKeyUp={this.handleKeyUp}
            value={this.state.value}
          />
        </div>
      );
    }
    return (
      <div className="search">
        <input
          type="text"
          className="search__input"
          placeholder={this.props.placeHolder}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          value={this.state.value}
        />
        <button className="search__button">
          <i className="icon-search" onClick={this.handleSearch} />
          {this.state.value && (
            <i className="font-medium icon-close" onClick={this.onClear} />
          )}
        </button>
      </div>
    );
  }
}

export default Search;
