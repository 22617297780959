import React from 'react';
import {
  ArrayDataField,
  DateDataField,
  TextDataField,
} from '../../../../components/data-fields';
import { DataTable } from '../../../../components/data-table';
import { ActiveSubscription } from '../../../../api/models/subscription';
import { Button } from '../../../../components/button';

interface ListViewProps {
  subscriptions: ActiveSubscription[];
  onClick?: () => void;
}

const renderSubscribeButton = (props: ListViewProps) => {
  return (
    <div className="col-12">
      <div className="float-right">
        <Button type="button" size="sm" className="mb-3" onClick={props.onClick}>
          <i className={'icon-plus icon-plus--white'} /> Subscribe
        </Button>
      </div>
    </div>
  );
};

const renderSubscriptionsTable = (props: ListViewProps) => {
  return (
    <>
      <div className="col-12">
        <div className="textField">
          {props.subscriptions && props.subscriptions.length ? (
            <DataTable list={props.subscriptions} recordIdKey="_id">
              <TextDataField label="Plan" source="planName"></TextDataField>
              <TextDataField label="Amount" source="amount"></TextDataField>
              <TextDataField
                label="Post Type"
                source="postType"
              ></TextDataField>
              <TextDataField label="Posts" source="noOfPosts"></TextDataField>
              <TextDataField
                label="Remaining Posts"
                source="noOfPostsRemaining"
              ></TextDataField>
              <ArrayDataField
                label="Categories"
                source="categories"
                property="title"
                separationChar=","
              />
              <DateDataField
                source="expireOn"
                label="Expire On"
                dateFormat="DD-MMM-YYYY"
              />
              <DateDataField
                source="createdAt"
                label="Created Date"
                dateFormat="DD-MMM-YYYY"
              />
            </DataTable>
          ) : (
            <span className="text-center p-3 d-block">No data found</span>
          )}
        </div>
      </div>
    </>
  );
};

const ListView: React.FunctionComponent<ListViewProps> = (props) => {
  return (
    <>
      {renderSubscribeButton(props)}
      {renderSubscriptionsTable(props)}
    </>
  );
};

export default ListView;
