import React, { useState } from 'react';
import { MetaData } from '../models/meta-data';
import { Dict, SortOrder } from '../models';
import api from '../api';
import PaginatedDataLoader, {
  PaginatedDataLoaderContext,
} from './PaginatedDataLoader';
import { CrudListRequestModel } from '../api/models';

interface CrudUpdateOptions {
  reset?: boolean;
  noReload?: boolean;
}

export interface CrudListDataLoaderContextInterface<T = any, U = any> {
  loading?: boolean;
  loaded?: boolean;
  error?: Error;
  data?: T;
  list?: U[];
  page: number;
  loadPage: (page: number) => T;
  setCrudQueryParams: (data: Dict, options?: CrudUpdateOptions) => void;
  removeCrudQueryParams: (key: string, options?: CrudUpdateOptions) => void;
  setCrudFilters: (data: Dict, options?: CrudUpdateOptions) => void;
  removeCrudFilters: (key: string, options?: CrudUpdateOptions) => void;
  onSortField: (sortField: string, sortOrder: SortOrder) => void;
  crudRequest?: CrudListRequestModel;
}

export const CrudListDataLoaderContext = React.createContext<
  CrudListDataLoaderContextInterface
>({
  loading: false,
  loaded: false,
  error: undefined,
  data: undefined,
  page: 0,
  crudRequest: undefined,
  loadPage: () => [],
  list: [],
  // tslint:disable-next-line: no-empty
  setCrudQueryParams: () => {},
  // tslint:disable-next-line: no-empty
  removeCrudQueryParams: () => {},
  // tslint:disable-next-line: no-empty
  setCrudFilters: () => {},
  // tslint:disable-next-line: no-empty
  removeCrudFilters: () => {},
  // tslint:disable-next-line: no-empty
  onSortField: () => {},
});

interface CrudListDataLoaderProps {
  metaData: MetaData;
  queryParams?: Dict;
  defaultFilters?: Dict;
}

const CrudListDataLoader: React.SFC<CrudListDataLoaderProps> = (props) => {
  const [crudRequest, setCrudRequest] = useState<CrudListRequestModel>({
    resource: props.metaData.resource,
    filter: props.defaultFilters || {},
    sort: {
      field: props.metaData.defaultSortField || '_id',
      order: props.metaData.defaultSortOrder || 'ASC',
    },
    pagination: props.metaData.paginate
      ? {
          perPage: props.metaData.perPage || 20,
          page: 1,
        }
      : undefined,
    queryParams: { ...props.metaData.queryParams, ...props.queryParams },
  });

  const loadPage = async (_page?: number) => {
    if (_page && _page !== 0 && !props.metaData.paginate) {
      return;
    }
    const newCrudRequest = {
      ...crudRequest,
      pagination: props.metaData.paginate
        ? {
            perPage: crudRequest?.pagination?.perPage || 20,
            page: _page || 1,
          }
        : undefined,
    };

    setCrudRequest(newCrudRequest);

    return api.crud.getList(newCrudRequest);
  };

  return (
    <PaginatedDataLoader
      loadPage={loadPage}
      source={props.metaData.source}
      mergeSources={props.metaData.mergeSources}
    >
      <PaginatedDataLoaderContext.Consumer>
        {(context) => {
          const onSortField = (sortField: string, sortOrder: SortOrder) => {
            const newCrudRequest: CrudListRequestModel = {
              ...crudRequest,
              sort: {
                field: sortField,
                order: sortOrder,
              },
            };

            setCrudRequest(newCrudRequest);

            context.reload();
          };

          const setCrudQueryParams = (
            queryParams: Dict,
            options?: CrudUpdateOptions
          ) => {
            const newCrudRequest = {
              ...crudRequest,
              queryParams: options?.reset
                ? queryParams
                : { ...crudRequest.queryParams, ...queryParams },
            };

            setCrudRequest(newCrudRequest);

            if (!options?.noReload) {
              context.reload();
            }
          };

          const removeCrudQueryParams = (
            key: string,
            options?: CrudUpdateOptions
          ) => {
            const newCrudRequest: CrudListRequestModel = {
              ...crudRequest,
              queryParams: { ...crudRequest.queryParams },
            };

            delete newCrudRequest.queryParams?.[key];

            setCrudRequest(newCrudRequest);

            if (!options?.noReload) {
              context.reload();
            }
          };

          const setCrudFilters = (
            filters: Dict,
            options?: CrudUpdateOptions
          ) => {
            const newCrudRequest = {
              ...crudRequest,
              filter: options?.reset
                ? filters
                : { ...crudRequest.filter, ...filters },
            };

            setCrudRequest(newCrudRequest);

            if (!options?.noReload) {
              context.reload();
            }
          };

          const removeCrudFilters = (
            key: string,
            options?: CrudUpdateOptions
          ) => {
            const newCrudRequest: CrudListRequestModel = {
              ...crudRequest,
              filter: { ...crudRequest.filter },
            };

            delete newCrudRequest.filter?.[key];

            setCrudRequest(newCrudRequest);

            if (!options?.noReload) {
              context.reload();
            }
          };

          return (
            <CrudListDataLoaderContext.Provider
              value={{
                loaded: context.loaded,
                loading: context.loading,
                data: context.data,
                error: context.error,
                page: context.page,
                loadPage: context.loadPage,
                list: context.list,
                setCrudQueryParams,
                removeCrudQueryParams,
                setCrudFilters,
                removeCrudFilters,
                onSortField,
                crudRequest,
              }}
            >
              {props.children}
            </CrudListDataLoaderContext.Provider>
          );
        }}
      </PaginatedDataLoaderContext.Consumer>
    </PaginatedDataLoader>
  );
};

export default CrudListDataLoader;
