import { Dict, MetaData } from '../models';
import { DataTableFieldProps, DataRecordDetails } from './data-table';

const _findNestedObjectValue = (
  obj: Dict | undefined,
  keyChain: string[]
): any => {
  if (!obj || keyChain.length === 0) return;
  const key = keyChain.shift();
  if (!key) {
    return;
  }
  const keyValue = obj[key];
  if (!keyChain.length) return keyValue;

  return _findNestedObjectValue(keyValue, keyChain);
};

const findNestedObjectValue = (record?: Dict, key?: string) => {
  if (!record || !key) {
    return record;
  }
  return _findNestedObjectValue(record, key.split('.'));
};

const _setNestedObjectValue = (
  obj: Dict | undefined,
  keyChain: string[],
  value: any
): any => {
  if (!obj || keyChain.length === 0) return;
  const key = keyChain.shift();
  if (!key) {
    return;
  }
  const keyValue = obj[key];
  if (!keyChain.length) {
    obj[key] = value;
  }

  return _setNestedObjectValue(keyValue, keyChain, value);
};

const setNestedObjectValue = (record?: Dict, key?: string, value?: any) => {
  if (!record || !key || !value) {
    return record;
  }
  return _setNestedObjectValue(record, key.split('.'), value);
};

const resolveFieldValue = (
  recordDetails?: DataRecordDetails,
  field?: DataTableFieldProps,
  metaData?: MetaData
) => {
  if (!field) {
    return;
  }
  if (field.value) {
    return field.value;
  }

  if (!recordDetails || !recordDetails.record || !field.source) {
    return undefined;
  }
  if (field.show && !field.show(recordDetails, metaData)) {
    return undefined;
  }
  return findNestedObjectValue(recordDetails.record, field.source);
};

export { findNestedObjectValue, resolveFieldValue, setNestedObjectValue };
