import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import validator from 'validator';
import { CompanyDetails } from '../../../api/models';
import { Button } from '../../../components/button';
import { TextInput } from '../../../components/form-group';
import 'react-phone-input-2/lib/style.css';
import { ValidateEmail, ValidatePhoneNumber } from '../../../utils/validation';

const CompanyDetailsForm: React.FunctionComponent<{
  onSubmit: (data: CompanyDetails) => void;
  default?: CompanyDetails;
  errorMessage?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const { handleSubmit, errors, control, watch, setValue } = useForm<
    CompanyDetails
  >({
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: props.default,
  });

  const onSubmit = (data: CompanyDetails) => {
    props.onSubmit({
      ...data,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="companyName"
                rules={{ required: 'CompanyName is Required' }}
                defaultValue={props.default?.companyName}
                render={({ onChange, value, name }) => (
                  <TextInput
                    label="CompanyName"
                    placeholder="CompanyName"
                    error={errors.companyName?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="address"
                rules={{ required: 'Address is Required' }}
                defaultValue={props.default?.address}
                render={({ onChange, value, name }) => (
                  <TextInput
                    label="Address"
                    placeholder="Address"
                    error={errors.address?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="city"
                rules={{ required: 'City is Required' }}
                defaultValue={props.default?.city}
                render={({ onChange, value, name }) => (
                  <TextInput
                    label="City"
                    placeholder="City"
                    error={errors.city?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="state"
                rules={{ required: 'State is Required' }}
                defaultValue={props.default?.state}
                render={({ onChange, value, name }) => (
                  <TextInput
                    label="State"
                    placeholder="State"
                    error={errors.state?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="country"
                // rules={{ required: 'Zip is Required' }}
                defaultValue={props.default?.country}
                render={({ onChange, value, name }) => (
                  <TextInput
                    label="Country"
                    placeholder="Country"
                    error={errors.country?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="zip"
                // rules={{ required: 'Zip is Required' }}
                defaultValue={props.default?.zip}
                render={({ onChange, value, name }) => (
                  <TextInput
                    label="Zip"
                    placeholder="Zip"
                    error={errors.zip?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="email"
                defaultValue={props.default?.email}
                rules={{
                  validate: (value) => ValidateEmail(value) || 'Invalid Email',
                }}
                render={({ onChange, value, name }) => (
                  <TextInput
                    placeholder="Email"
                    error={errors.email?.message}
                    onChange={onChange}
                    name={name}
                    default={value}
                    gap="no"
                  />
                )}
              ></Controller>
            </div>
          </div>

          <div className="col-xl-5 col-sm-6 col-12">
            <div className="textField">
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: 'Phone is required',
                  validate: (value) => {
                    return ValidatePhoneNumber(value);
                  },
                }}
                defaultValue={props.default?.phone}
                render={({ onChange, value, name }) => (
                  <PhoneInput
                    autoFormat={false}
                    country={'bs'}
                    preferredCountries={['bs', 'in']}
                    enableSearch={false}
                    placeholder={'Phone Number'}
                    inputClass={`textField__input ${
                      errors.phone ? 'error' : ''
                    }`}
                    containerClass={'textField'}
                    dropdownStyle={{ borderColor: '#e5e6e7' }}
                    disableCountryCode={false}
                    countryCodeEditable={true}
                    inputStyle={{
                      width: '100%',
                      borderRadius: '0px',
                      borderColor: '#e5e6e7',
                    }}
                    disableSearchIcon={true}
                    onChange={(val: any) => {
                      onChange(val);
                    }}
                    value={value}
                  />
                )}
              ></Controller>
              {errors.phone && (
                <span className="Input__error">
                  {errors.phone?.type === 'required'
                    ? 'Phone Number is required'
                    : 'Invalid Phone Number'}
                </span>
              )}
            </div>
          </div>

          <div className="col-xl-5 col-sm-6 col-12">
            <div className="mb-2 mt-2">
              {props.errorMessage && (
                <span className="message_validation">{props.errorMessage}</span>
              )}
            </div>

            <div className="button-outer">
              <Button type="submit" disabled={props.isSubmitting}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CompanyDetailsForm;
