import React from 'react';

interface TableProps {
  bordered?: boolean;
  className?: string;
  id?: string;
}

export const Table: React.SFC<TableProps> = (props) => {
  return (
    <table
      id={props.id || ''}
      className={`DataTable ${props.bordered ? 'DataTable--bordered' : ''} ${
        props.className || ''
      }`}
    >
      {props.children}
    </table>
  );
};
