import React from 'react';
import { PermissiosAccordion } from './permission-accordion';
import PermissionCheckbox from './PermissionCheckbox';
import Checkbox from '../form-group/checkbox';
import { ApiPermissions } from '../../api/models';

interface EntityPermissionsProps {
  entity: {
    entity: string;
    permissions: ApiPermissions[];
  };
  onPermissionSelection?: (
    permission: string | string[],
    selected: boolean
  ) => void;
  disabled?: boolean;
}

const EntityPermissions: React.SFC<EntityPermissionsProps> = (props) => {
  const { entity, onPermissionSelection } = props;
  if (!entity) {
    return <></>;
  }
  const { permissions } = entity;
  const allSelected = !permissions.find((per) => !per.selected);
  const permissionCount = permissions.filter((per) => per.selected).length;

  const subHeading = allSelected
    ? 'All permissions granded'
    : `${permissionCount || 'No'} permission${
        permissionCount === 1 ? '' : 's'
      } granded`;

  const header = (
    <Checkbox
      gap="sm"
      id={`selec_all_${entity.entity}_permissions`}
      label=""
      selected={allSelected}
      customStyles={{ bottom: '7px', right: '-6px' }}
      onChange={(val) => {
        if (onPermissionSelection) {
          onPermissionSelection(
            permissions.map((per) => per.permission),
            val
          );
        }
      }}
      disabled={props.disabled}
    />
  );
  return (
    <PermissiosAccordion
      header={header}
      heading={`${entity.entity.replace(/_/g, ' ')}`}
      subHeading={subHeading}
      headingLevel="sm"
    >
      {permissions &&
        permissions.map((permission) => {
          return (
            <PermissionCheckbox
              permission={permission}
              onSelection={onPermissionSelection}
              disabled={props.disabled}
            />
          );
        })}
    </PermissiosAccordion>
  );
};

export default EntityPermissions;
