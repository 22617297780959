import React from 'react';
import { Button } from '../button';
import { useDispatch } from 'react-redux';

import { actions as userActions } from '../../store/reducers/authUser';
import { actions as commonActions } from '../../store/reducers/common';
import { useHistory } from 'react-router-dom';
import { RouteKeys as AuthRouteKeys } from '../../containers/auth/route-keys';
import { RouteKeys } from '../../containers/routes/route-keys';
import Routes from '../../containers/routes/Routes';

interface ChangePasswodButtonProps {
  className?: string;
  noIcon?: boolean;
}

const ChangePasswordButton: React.SFC<ChangePasswodButtonProps> = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const changePassword = () => {
    history.replace(RouteKeys.ChangePassword);
  };

  return (
    <Button
      className={`${props.className}`}
      type="button"
      onClick={changePassword}
      btnLink
    >
      {!props.noIcon && <i className="icon-user"></i>}
      {' Change Password'}
    </Button>
  );
};

export default ChangePasswordButton;
