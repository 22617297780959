import React from 'react';

import Logo from '../../components/logo/Logo';
import './AuthCanvas.scss';
import WhiteBox from '../../layout/WhiteBox';

interface AuthCanvasProps {
  head: string;
}

const AuthCanvas: React.SFC<AuthCanvasProps> = (props) => {
  const { head } = props;
  return (
    <div className="form login">
      <div className="form__inner login__inner">
        <div className="d-block text-center">
          <div className="logo">
            <Logo />
          </div>
        </div>

        <WhiteBox>
          <h6>{head}</h6>
          {props.children}
        </WhiteBox>
      </div>
    </div>
  );
};

export default AuthCanvas;
