import React, { useContext } from 'react';
import { DataTableFieldProps } from '../data-table';
import { DateView } from '../view-fields';
import { MetaDataContext, DataRecordContext } from '../../contexts';
import { resolveFieldValue } from '../utils';

interface DateDataFieldProps extends DataTableFieldProps<string | Date> {
  value?: string | Date;
  bold?: boolean;
  dateFormat?: string;
  nullValue?: string;
}

const DateDataField: React.SFC<DateDataFieldProps> = (props) => {
  const recordDetails = useContext(DataRecordContext);
  const metaData = useContext(MetaDataContext);
  const value = resolveFieldValue(recordDetails, props, metaData);

  return <DateView {...props} value={value}></DateView>;
};

export default DateDataField;
