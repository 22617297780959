import React from 'react';
import { ProductDetails } from '../../../api/models/catalog';
import {
  ArrayDataField,
  DateDataField,
  TextDataField,
} from '../../../components/data-fields';
import { DataTable } from '../../../components/data-table';

interface ListViewProps {
  products: ProductDetails[];
  onClick?: () => void;
}

const renderProductsTable = (props: ListViewProps) => {
  return (
    <>
      <div className="col-12">
        <div className="textField">
          {props.products && props.products.length ? (
            <DataTable list={props.products} recordIdKey="_id">
              <TextDataField label="Name" source="title"></TextDataField>
              <TextDataField label="Price ($)" source="price"></TextDataField>
              <TextDataField
                label="SoldPrice ($)"
                source="sellingPrice"
              ></TextDataField>
              <TextDataField
                label="PurchasedBy"
                source="purchasedBy.name"
              ></TextDataField>
              <TextDataField
                label="Commission (%)"
                source="category.commission"
              ></TextDataField>
              <DateDataField
                source="soldOn"
                label="Sold Date"
                dateFormat="DD-MMM-YYYY hh:mm A"
              />
              <DateDataField
                label="Delivered Date"
                source="deliveredOn"
                dateFormat="DD-MMM-YYYY hh:mm A"
              />
            </DataTable>
          ) : (
            <span className="text-center p-3 d-block">No data found</span>
          )}
        </div>
      </div>
    </>
  );
};

const PayoutProducListView: React.FunctionComponent<ListViewProps> = (
  props
) => {
  return <>{renderProductsTable(props)}</>;
};

export default PayoutProducListView;
