import * as http from './http';
import ApiService from './service';

export default class UserService extends ApiService {
  public async login(credentials: { username: string; password: string }) {
    const url = `${this.apiDomain}/auth/login`;
    const response = await http.post(url, { ...credentials, isAdmin: true });
    return response.data;
  }
  public async updatePassword(id: string, password: string) {
    const url = `${this.apiDomain}/admin/update-password/${id}`;
    const response = await http.post(url, { password }, this.token);
    return response.data.data;
  }
}
