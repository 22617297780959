import React from 'react';
import useHasAnyOnePermission from '../../hooks/useHasAnyOnePermission';
import NoPermissionView from './NoPermissionView';

interface AnyOnePermissionCheckViewProps {
  permissions?: (string | undefined)[];
  showNoPermissionView?: boolean;
}

const AnyOnePermissionCheckView: React.SFC<AnyOnePermissionCheckViewProps> = (
  props
) => {
  const hasPermission = useHasAnyOnePermission(props.permissions);

  if (hasPermission) {
    return <>{props.children}</>;
  }
  if (props.showNoPermissionView) {
    return (
      <NoPermissionView permissions={props.permissions}></NoPermissionView>
    );
  }
  return <></>;
};

export default AnyOnePermissionCheckView;
