import { Dict } from '../../models';
import { Brand } from './brand';
import { Category, SubCategory } from './category';
import { MediaFile } from './media';
import { ActiveSubscription } from './subscription';
import { ProductType, SubscriptionPlan } from './subscription-plan';
import { CreatedBy } from './user';

export interface Product {
  _id: string;
  slug: string;
  title: string;
  price: number;
  currency: string;
  image: any;
  images?: any[];
  featured?: boolean;
  isFollow?: boolean;
  verified?: boolean;
  address: string;
  createdAt: Date;
  swapWith?: string;
  favorited?: boolean;
  description?: string;
  category?: Category;
  subcategory?: SubCategory;
  likeCount?: number;
  bidCount?: number;
  leadCount?: number;
  viewCount?: number;
  isSold?: boolean;
  brand?: Brand;
  createdBy: CreatedBy;
  location: {
    lat?: number;
    lng?: number;
    address: string;
    location: {
      type: string;
      coordinates: number[];
    };
    latLong?: LatLng;
  };
  defaultImage?: MediaFile;
  validTill?: Date;
  isFeatured?: boolean;
  orderStatus: OrderStatus;
  contactedUsers?: string[];
  commission?: number;
  sellingPrice?: number;
  invoiceNumber?: string;
  subscriptionPlan?: SubscriptionPlan;
  productType: ProductType;
  auctionDetails?: Auction;
  soldOn?: Date;
  deliveredOn?: Date;
}

export interface Auction {
  startDate: Date;
  endDate: Date;
  startPrice: number;
  buyNowPrice: number;
  bidIncrementAmount: number;
}

export interface LatLng {
  lat: number;
  lng: number;
}
export interface ProductDetails extends Product {
  images: any[];
  likeCount?: number;
  highlightedFields?: HighlightedFieldsMeta[];
  keyFields?: FieldsMeta[];
  customAttributes?: Dict;
  banks?: BankInfo[];
  rating?: RatingSummary;
}

export interface RatingSummary {
  overallRating: number;
  totalRatings: number;
  totalReviews: number;
  ratingSplit: {
    '5': number;
    '4': number;
    '3': number;
    '2': number;
    '1': number;
  };
}

export interface FieldsMeta {
  field: string;
  title: string;
}

export interface HighlightedFieldsMeta extends FieldsMeta {
  icon: string;
}

export interface BankInfo {
  name: string;
  image: any;
  interestRateDescription: string;
}

export interface ProductReview {
  rating: number;
  title: string;
  comment: string;
}

export interface CreatePostFormProps {
  title: string;
  description: string;
  brand?: string;
  selectedBrand?: {
    _id: string;
    title: string;
  };
  brandId?: string;
  price?: number;
  images: MediaFile[];
  location_address?: any;
  customAttributes?: Dict;
  location?: {
    street?: string;
    suburb?: string;
    city?: string;
    state?: string;
    country?: string;
    address?: string;
    location?: {
      type: string;
      coordinates: number[];
    };
  };
  isNegotiable?: boolean;
  isExchange?: boolean;
  exchangeables?: string[];
  defaultImage?: MediaFile;
}
export interface ReportPostForm {
  reason: string;
  description: string;
  productId: string;
}
export interface ReportUserForm {
  reason: string;
  description: string;
  userId: string;
}
export enum OrderStatus {
  Pending = 'pending',
  OfferAccepted = 'offer_accepted',
  Ordered = 'ordered',
  Failed = 'failed',
  Sold = 'payment_received',
  InTransit = 'in_transit',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
  Success = 'success',
}

interface StatusExt {
  label: string;
  class: string;
}
export const OrderStatusSyllables: Dict<StatusExt> = {
  [OrderStatus.Pending]: { label: 'Pending', class: 'pending' },
  [OrderStatus.Success]: { label: 'Success', class: 'active' },
  [OrderStatus.Cancelled]: { label: 'Cancelled', class: 'cancelled' },
  [OrderStatus.Failed]: { label: 'Failed', class: 'inactive' },
  [OrderStatus.Sold]: { label: 'Sold', class: 'completed' },
};
