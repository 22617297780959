export interface Dict<T = any> {
  [key: string]: T;
}

export * from './crud-request';
export * from './auth';
export * from './api-permissions';
export * from './entity';
export * from './media';
export * from './response';
export * from './subscription-plan';
export * from './user';
export * from './subscription';
export * from './payment';
