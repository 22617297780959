import React from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  Attribute,
  NumberAttribute,
  StringAttribute,
} from '../../api/models/attribute';
import ClickAction from '../../components/actions/ClickAction';
import { Button } from '../../components/button';
import { ActionsDataField, TextDataField } from '../../components/data-fields';
import FileUploadController from '../../components/upload/FileUploadController';
import { DataTable } from '../../components/data-table';
import { TextInput } from '../../components/form-group';
import SelectInput from '../../components/form-group/SelectInput';
import SwitchInput from '../../components/form-group/switch-input';

interface Options<T = any> {
  name: string;
  value: T;
}

const AddAttributeForm: React.FunctionComponent<{
  attribute?: Attribute;
  onSubmit: (attr: Attribute) => void;
  onCancel?: () => void;
}> = (props) => {
  const { control, watch, errors, setValue, handleSubmit } = useForm<Attribute>(
    {
      defaultValues: props.attribute,
    }
  );
  const type = watch(`type`);
  const format = watch(`format`);

  const {
    fields: options,
    append: addOption,
    remove: removeOption,
  } = useFieldArray<Options>({
    control,
    name: 'options',
  });

  const constrollerClassName = 'col-lg-4 col-sm-6 col-xs-12 textField my-auto';

  const renderOptions = () => {
    const valueRules = watch('required')
      ? { required: 'Value is required' }
      : {};
    return (
      <div className="textField w-100">
        <h6>Options</h6>
        {options.map((option, index) => {
          return (
            <div className="row no-gutters">
              <div className="col-4 px-3">
                <Controller
                  name={`options[${index}].name`}
                  control={control}
                  rules={{ required: 'Name is required' }}
                  defaultValue={option.name}
                  render={({ onChange, name, value }) => {
                    return (
                      <TextInput
                        gap="sm"
                        label={'Name'}
                        size="sm"
                        name={name}
                        default={value}
                        onChange={onChange}
                        error={(errors as any)?.options?.[index]?.name?.message}
                      ></TextInput>
                    );
                  }}
                ></Controller>
              </div>
              <div className="col-4 px-3">
                <Controller
                  name={`options[${index}].value`}
                  control={control}
                  rules={valueRules}
                  defaultValue={option.value}
                  render={({ onChange, name, value }) => {
                    return (
                      <TextInput
                        gap="sm"
                        type={type === 'String' ? 'text' : 'number'}
                        label={'Value'}
                        size="sm"
                        name={name}
                        default={value}
                        onChange={onChange}
                        error={
                          (errors as any)?.options?.[index]?.value?.message
                        }
                      ></TextInput>
                    );
                  }}
                ></Controller>
              </div>
              <div className="align-self-end col-4">
                <Button btnLink noTransform  size="sm" className="mb-3 text-red" onClick={() => removeOption(index)}>
                  Remove
                </Button>
              </div>
            </div>
          );
        })}
          <Button
            btnLink
            size="sm"
            className="text-red"
            onClick={() => addOption({})}
          >
           + Add Option
          </Button> 
       
      </div>
    );
  };

  const renderTypeController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`type`}
          control={control}
          defaultValue={props.attribute?.type}
          rules={{ required: 'Attribute Type is required' }}
          render={({ onChange, name, value }) => {
            return (
              <SelectInput
                label="Attribute Type"
                optionLabel="name"
                optionValue="value"
                error={errors?.type?.message}
                data={[
                  {
                    name: 'Text',
                    value: 'String',
                  },
                  {
                    name: 'Boolean',
                    value: 'Boolean',
                  },
                  {
                    name: 'Numeric',
                    value: 'Number',
                  },
                ]}
                onChange={onChange}
                default={value}
                name={name}
              ></SelectInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderRequiredController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`required`}
          control={control}
          defaultValue={props.attribute?.required}
          render={({ onChange, name, value }) => {
            return (
              <SwitchInput
                key={`required`}
                label="Is Required"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.required?.message}
              ></SwitchInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderFileUploadController = () => {
    return (
      <div className={constrollerClassName}>

        <FileUploadController
          value={props.attribute?.image}
          control={control}
          name="image"
          setValue={setValue}
          showIcon={true}
        ></FileUploadController>
      </div>
    );
  };

  const renderIsMultiSelectController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`isMultiSelect`}
          control={control}
          defaultValue={props.attribute?.isMultiSelect}
          render={({ onChange, name, value }) => {
            return (
              <SwitchInput
                key={`isMultiSelect`}
                label="Allow Multi Select"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.isMultiSelect?.message}
              ></SwitchInput>
            );
          }}
        ></Controller>
      </div>
    );
  };


  const renderIsHighlightedController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`isHighlighted`}
          control={control}
          defaultValue={props.attribute?.isHighlighted}
          render={({ onChange, name, value }) => {
            return (
              <SwitchInput
                key={`isHighlighted`}
                label="Highlight in details"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.required?.message}
              ></SwitchInput>
            );
          }}
        ></Controller>
      </div>
    );
  };


  const renderAllowNullController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`allowNull`}
          control={control}
          defaultValue={props.attribute?.allowNull}
          render={({ onChange, name, value }) => {
            return (
              <SwitchInput
                key={`allowNull`}
                label="Allow Null"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.allowNull?.message}
              ></SwitchInput>
            );
          }}
        ></Controller>
      </div>
    );
  };
  const renderKeyController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`key`}
          control={control}
          defaultValue={props.attribute?.key}
          rules={{ required: 'Key is required', pattern: /[a-zA-Z0-9_]/gi }}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="Attribute Key"
                onChange={onChange}
                default={value}
                name={name}
                error={errors?.key?.message}
                disabled
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderLabelController = () => {
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`label`}
          control={control}
          defaultValue={props.attribute?.label}
          rules={{ required: 'Label is required' }}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="Attribute Label"
                onChange={(val) => {
                  setValue(`key`, val?.replace(/[\W]+/g, '_')?.toLowerCase());
                  onChange(val);
                }}
                default={value}
                name={name}
                error={errors?.label?.message}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderFormatController = () => {
    const errs = errors as DeepMap<StringAttribute, FieldError>;
    const attribute = props.attribute as StringAttribute | undefined;
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`format`}
          control={control}
          defaultValue={attribute?.format}
          render={({ onChange, name, value }) => {
            return (
              <SelectInput
                label="String Format"
                optionLabel="name"
                optionValue="value"
                data={[
                  {
                    name: 'Email',
                    value: 'Email',
                  },
                  {
                    name: 'Rich Text',
                    value: 'RichText',
                  },
                  {
                    name: 'Regex',
                    value: 'Regex',
                  },
                  {
                    name: 'Html',
                    value: 'Html',
                  },
                  {
                    name: 'Password',
                    value: 'Password',
                  },
                ]}
                onChange={onChange}
                default={value}
                name={name}
                error={errs?.format?.message}
              ></SelectInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderMinLengthController = () => {
    const maxLength: number = watch(`maxLength`);
    const errs = errors as DeepMap<StringAttribute, FieldError>;
    const attribute = props.attribute as StringAttribute;
    const rules = {
      validate: {
        maxLength: (value: number) =>
          !maxLength ||
          value <= maxLength ||
          `Min Length should not be greater than ${maxLength}`,
      },
    };

    return (
      <div className={constrollerClassName}>
        <Controller
          name={`minLength`}
          control={control}
          defaultValue={attribute?.minLength}
          rules={rules}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="Min Length"
                placeholder="Min Length"
                type="number"
                error={errs?.minLength?.message}
                onChange={onChange}
                default={value}
                name={name}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };
  const renderMaxLengthController = () => {
    const errs = errors as DeepMap<StringAttribute, FieldError>;
    const attribute = props.attribute as StringAttribute;
    const minLength: number | undefined = watch(`minLength`) || 0;
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`maxLength`}
          control={control}
          defaultValue={attribute?.maxLength}
          rules={{
            validate: {
              minLength: (value?: number) =>
                value === undefined ||
                value >= minLength ||
                `Max Length should not be less than ${minLength}`,
            },
          }}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="Max Length"
                placeholder="Max Length"
                type="number"
                onChange={onChange}
                default={value}
                name={name}
                error={errs?.maxLength?.message}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };
  const renderRegexController = () => {
    const errs = errors as DeepMap<StringAttribute, FieldError>;
    const attribute = props.attribute as StringAttribute | undefined;
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`regex`}
          control={control}
          defaultValue={attribute?.regex}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                key={`regex`}
                label="Regex Expression"
                onChange={onChange}
                default={value}
                name={name}
                error={errs?.regex?.message}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };
  const renderAlphaNumericController = () => {
    const errs = errors as DeepMap<StringAttribute, FieldError>;
    const attribute = props.attribute as StringAttribute | undefined;
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`alphanumeric`}
          control={control}
          defaultValue={attribute?.alphanumeric}
          render={({ onChange, name, value }) => {
            return (
              <SwitchInput
                key={`alphanumeric`}
                label="Alphanumeric Only"
                onChange={onChange}
                default={value}
                name={name}
                error={errs?.alphanumeric?.message}
              ></SwitchInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderMinValueController = () => {
    const maxValue: number = watch(`max`);
    const errs = errors as DeepMap<NumberAttribute, FieldError>;
    const attribute = props.attribute as NumberAttribute;
    const rules = {
      validate: {
        maxLength: (value: number) =>
          !maxValue ||
          value === undefined ||
          value <= maxValue ||
          `Min Value should not be greater than ${maxValue}`,
      },
    };
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`min`}
          control={control}
          defaultValue={attribute?.min}
          rules={rules}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="Min Value"
                placeholder="Min Value"
                type="number"
                error={errs?.min?.message}
                onChange={onChange}
                default={value}
                name={name}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };
  const renderMaxValueController = () => {
    const errs = errors as DeepMap<NumberAttribute, FieldError>;
    const attribute = props.attribute as NumberAttribute;
    const minValue: number = watch(`min`) || 0;
    return (
      <div className={constrollerClassName}>
        <Controller
          name={`max`}
          control={control}
          defaultValue={attribute?.max}
          rules={{
            validate: {
              minLength: (value: number) =>
                value === undefined ||
                value >= minValue ||
                `Max Value should not be less than ${minValue}`,
            },
          }}
          render={({ onChange, name, value }) => {
            return (
              <TextInput
                label="Max Value"
                placeholder="Max Value"
                type="number"
                onChange={onChange}
                default={value}
                name={name}
                error={errs?.max?.message}
              ></TextInput>
            );
          }}
        ></Controller>
      </div>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div className="col-auto">
        <Button type="submit">{props.attribute ? 'Update' : 'Add'}</Button>
      </div>
    );
  };
  const renderCancelButton = () => {
    return (
      <div className="col-auto">
        <Button type="button" className="Button__outline-primary" onClick={props.onCancel}>
          Cancel
        </Button>
      </div>
    );
  };

  return (
    <form
      className="w-100"
      onSubmit={handleSubmit(props.onSubmit)}
      style={{ overflowX: 'hidden' }}
    >
      <div className="w-100">
        <div className="row gutter-10 px-md-4 px-3 py-3">
          <h6 className="col-12 pb-4">Attribute details</h6>
          {renderLabelController()}
          {renderKeyController()}
          {renderRequiredController()}
          {renderTypeController()}
          {renderAllowNullController()}
          {renderIsHighlightedController()}
          {renderFileUploadController()}
          {type === 'Number' && renderMinValueController()}
          {type === 'Number' && renderMaxValueController()}
          {type === 'String' && renderFormatController()}
          {type === 'String' && renderIsMultiSelectController()}
          {type === 'Number' && renderIsMultiSelectController()}
          {type === 'String' && format === 'Regex' && renderRegexController()}
          {type === 'String' && renderMinLengthController()}
          {type === 'String' && renderMaxLengthController()}
          {type === 'String' && renderAlphaNumericController()}
        </div>
        {(type === 'String' || type === 'Number') && (
          <div className="row gutter-10 px-md-4 px-3 py-3 ">{renderOptions()}</div>
        )}
        <div className="row gutter-8 px-md-4 px-3 py-3">
          {renderSubmitButton()}
          {renderCancelButton()}
        </div>
      </div>
    </form>
  );
};

export default AddAttributeForm;
