import React from 'react';
import './DataTable.scss';

import {
  Table,
  TableHead,
  TableHeadItem,
  TableBody,
  TableBodyRow,
  TableBodyItem,
} from '.';
import { Dict } from '../../models';
import { DataRecordContext } from '../../contexts';

interface DataTableProps<T = any> {
  onSort?: (fieldName: string, sortAsc?: boolean) => void;
  recordIdKey: string;
  list: T[];
  className?: string;
  id?: string;
}

const DataTable: React.SFC<DataTableProps> = (props) => {
  const { recordIdKey = '_id' } = props;

  const onSort = (fieldKey?: string, sortAsc?: boolean) => {
    if (fieldKey && props.onSort) {
      props.onSort(fieldKey, sortAsc);
    }
  };

  const renderHeadList = () => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return (
          <TableHeadItem
            key={child.props.source}
            onSort={(sortAsc) =>
              child.props.sortable && onSort(child.props.source, sortAsc)
            }
            showSortIcon={!!props.onSort && child.props.sortable}
            sortAsc={!child.props.defaultSortDesc}
            actionsColumn={child.props.actionsColumn}
          >
            {child.props.label}
            {child.props.checkbox && child.props.checkbox}
          </TableHeadItem>
        );
      }
    });
  };

  const renderListRow = (record: Dict, index: number) => {
    if (!record) {
      return <></>;
    }

    const recordId = record[recordIdKey];

    return (
      <TableBodyRow key={recordId}>
        <DataRecordContext.Provider value={{ record, recordId, index }}>
          {React.Children.map(props.children, (child) => {
            if (React.isValidElement(child)) {
              return (
                <TableBodyItem
                  dataTitle={child.props.label}
                  key={`${recordId}-${child.props.label}`}
                  actionsColumn={child.props.actionsColumn}
                >
                  {child}
                </TableBodyItem>
              );
            }
          })}
        </DataRecordContext.Provider>
      </TableBodyRow>
    );
  };

  return (
    <Table className={props.className} id={props.id || ''}>
      <TableHead>{renderHeadList()}</TableHead>
      <TableBody>{props.list.map(renderListRow)}</TableBody>
    </Table>
  );
};

export default DataTable;
