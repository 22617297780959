import { format } from 'date-fns';
import React from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../../../api';
import { OrderStatus, Product } from '../../../../api/models/catalog';
import { ContainerLoader } from '../../../../components/loader';
import { TabCount } from '../../DetailsView';
import ProductStatus from './ProductStatus';

const MyAds: React.FunctionComponent<TabCount> = (props) => {
  const { data: products, loading, loaded } = useDataLoader<Product[]>(() =>
    api.catalog.getMyAds(props.userId)
  );
  if (loaded && !loading) {
    if (props.myAdsCount) {
      props.myAdsCount(products.length);
    }
  }
  const renderProducts = () => {
    const newDate = new Date();
    return products.map((product: Product) => {
      return (
        <div className="ads-item bg-white shadow px-4 pt-lg-4 pb-2 pb-lg-0 position-relative mb-3">
          <div className="top-date d-lg-none d-block">
            <span className="date d-block font-xsmall py-2">
              <span className="text-muted">From:</span>{' '}
              {format(new Date(product.createdAt), 'dd MMM yyyy')}
            </span>
          </div>
          {/* <div className="dropdown filter position-absolute d-none d-lg-block">
            <span
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="icon-ellipse-v text-muted font-large"></span>
            </span>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              <a className="dropdown-item" href="#">
                Action
              </a>
              <a className="dropdown-item" href="#">
                Another action
              </a>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-6 brd-mob pb-3 pb-lg-0 d-lg-flex align-items-center">
              <figure className="mb-0 float-left overflow-hidden">
                <img className="img-fluid" src={product.defaultImage?.path} />
              </figure>
              <figcaption className="float-left pl-3 pt-lg-0">
                <span className="date d-none font-sm d-lg-block font-xsmall">
                  <span className="text-muted">From:</span>{' '}
                  {format(new Date(product.createdAt), 'dd MMM yyyy')}
                </span>
                <span className="title font-base pt-lg-2 pt-lg-0 d-block">
                  {product.title}
                </span>
                <span className="price font-large text-dark font-weight-bold pt-1 d-block d-lg-none">
                  $ {product.price.toFixed(2)}
                </span>
                <div className="row d-lg-none d-flex views-mob pt-2">
                  <div className="col-9">
                    <span className="font-sm text-muted mr-2">
                      <i className="icon-visible font-large"></i>{' '}
                      {product.viewCount || 0} views
                    </span>
                    <span className="font-sm text-muted mr-2">
                      <i className="icon-heart-filled font-base"></i>{' '}
                      {product.likeCount || 0}
                      likes
                    </span>
                  </div>
                  <div className="col-3 text-right">
                    <img
                      className="img-fluid"
                      src="images/my-account/qr-code.jpg"
                    />
                  </div>
                </div>
              </figcaption>
            </div>
            <div className="col-lg-2 col-6 pl-0 d-none d-lg-flex align-items-center">
              <span className="price font-lg text-dark font-weight-bold  d-block">
                $ {product.price.toFixed(2)}
              </span>
            </div>
            <div className="col-lg-2 col-6 px-0  pt-3 pt-lg-0 pb-2 pb-lg-2 d-flex align-items-center flex-wrap">
              <ProductStatus status={product.orderStatus}></ProductStatus>
              <div className="text-muted font-xsmall pt-2 ">
                {product.validTill &&
                  new Date(product.validTill) > newDate &&
                  product.orderStatus === OrderStatus.Pending && (
                    <>
                      <span>This Ad is currently live</span>
                    </>
                  )}
                {product.validTill && new Date(product.validTill) < newDate && (
                  <>
                    <span> This Ad is expired</span>{' '}
                  </>
                )}
                {product.orderStatus === OrderStatus.Sold && (
                  <>
                    <span>This Ad is Sold</span>
                  </>
                )}
              </div>
            </div>
            {/* <div className="col-lg-2 col-6 px-0 pt-3 pt-lg-4 pb-2 pb-lg-2 d-flex d-lg-none align-items-center">
              <span className="font-xs d-block text-lg-right text-primary font-weight-semibold py-2 py-lg-0">
                Receive Payment Request
              </span>
            </div> */}
            <div className="col-lg-2 text-lg-center pt-4 d-none d-lg-none">
              <img className="img-fluid" src="images/my-account/qr-code.jpg" />
            </div>
          </div>
          <div className="views-block border-top mt-3 pt-3 py-lg-2 pb-2 d-none d-lg-block">
            <div className="row">
              <div className="col-lg-6">
                <span className="font-xsmall text-muted mr-4">
                  <i className="icon-visible font-base"></i>{' '}
                  {product.viewCount || 0} views
                </span>
                <span className="font-xsmall text-muted mr-4">
                  <i className="icon-heart-filled font-base"></i>{' '}
                  {product.likeCount || 0} likes
                </span>
              </div>
              {/* <div className="col-lg-6">
                <span className="font-xsmall d-block text-lg-right text-primary font-weight-semibold py-2 py-lg-0">
                  Receive Payment Request
                </span>
              </div> */}
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div
      className="tab-pane fade show active"
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
    >
      {/* <div className="d-flex justify-content-end">
        <select className="form-control w-auto bg-white mb-3">
          <option>All</option>
          <option>Saab</option>
          <option>Mercedes</option>
          <option>Audi</option>
        </select>
      </div> */}
      {loaded && !loading && renderProducts()}
      {(!loaded || loading) && <ContainerLoader height={500}></ContainerLoader>}
    </div>
  );
};
export default MyAds;
