import { format } from 'date-fns';
import React from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../../../api';
import { OrderStatus, Product } from '../../../../api/models/catalog';
import {
  DateDataField,
  LinkField,
  TextDataField,
} from '../../../../components/data-fields';
import { DataTable } from '../../../../components/data-table';
import { ContainerLoader } from '../../../../components/loader';
import { TextView } from '../../../../components/view-fields';
import { capitalizeFirstLetter } from '../../../../utils/text-utils';
import { TabCount } from '../../DetailsView';
import ProductStatus from './ProductStatus';

const MyPurchase: React.FunctionComponent<TabCount> = (props) => {
  const { data: payments, loading, loaded } = useDataLoader<any>(() =>
    api.payment.getMyPayments(props.userId)
  );
  const renderPayments = () => {
    return (
      <DataTable list={payments.data} recordIdKey="_id">
        <LinkField source="invoiceNumber" label="Invoice">
          <TextView />
        </LinkField>
        <TextDataField source="createdBy.name" label="User"></TextDataField>
        <TextDataField
          source="status"
          label="Status"
          formatContent={(val: string) => capitalizeFirstLetter(val)}
        ></TextDataField>
        <TextDataField
          source="paymentType"
          label="Payment Type"
          formatContent={(val: string) => capitalizeFirstLetter(val)}
        ></TextDataField>
        <DateDataField
          source="createdAt"
          label="Created At"
          dateFormat="DD-MMM-YYYY hh:mm A"
        />
        <TextDataField
          source="amountPaid"
          label="Amount"
          fontLarge
        ></TextDataField>
      </DataTable>
    );
  };
  return (
    <div
      className="tab-pane fade show active"
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
    >
      {/* <div className="d-flex justify-content-end">
        <select className="form-control w-auto bg-white mb-3">
          <option>All</option>
          <option>Saab</option>
          <option>Mercedes</option>
          <option>Audi</option>
        </select>
      </div> */}
      {loaded && !loading && renderPayments()}
      {(!loaded || loading) && <ContainerLoader height={500}></ContainerLoader>}
    </div>
  );
};
export default MyPurchase;
