import React from 'react';
import { PermissiosAccordion } from './permission-accordion';
import Checkbox from '../form-group/checkbox';
import { ApiPermissions } from '../../api/models';
import EntityPermissions from './EntityPermissions';
import { Dict } from '../../models';

interface PermissionSelectionViewProps {
  heading: React.ReactNode;
  permissions: ApiPermissions[];
  selectedPermissions?: string[];
  onPermissionSelection?: (
    permission: string | string[],
    selected: boolean
  ) => void;
  disabled?: boolean;
}

const PermissionSelectionView: React.SFC<PermissionSelectionViewProps> = (
  props
) => {
  const {
    permissions,
    selectedPermissions,
    onPermissionSelection,
    heading,
  } = props;

  if (!permissions || !permissions.length) {
    return <div className="main__label">No permissions found</div>;
  }
  if (selectedPermissions) {
    permissions.forEach((per) => {
      // eslint-disable-next-line no-param-reassign
      per.selected = selectedPermissions.includes(per.permission);
    });
  }

  const allSelected = !permissions.find((per) => !per.selected);
  const permissionCount = permissions.filter((per) => per.selected).length;

  const subHeading = allSelected
    ? 'All permissions granded'
    : `${permissionCount || 'No'} permission${
        permissionCount === 1 ? '' : 's'
      } granded`;

  const formatPermissions = () => {
    const servicePermissionsDict: Dict = {};
    permissions.forEach((per) => {
      if (!servicePermissionsDict[per.entity || '']) {
        servicePermissionsDict[per.entity || ''] = [];
      }
      servicePermissionsDict[per.entity || ''].push(per);
    });
    return Object.keys(servicePermissionsDict).map((entity) => {
      return {
        entity,
        permissions: servicePermissionsDict[entity] || [],
      };
    });
  };
  const header = (
    <Checkbox
      gap="sm"
      id="all_permissions"
      label=""
      selected={allSelected}
      labelClassName="Accordion__title--level-lg"
      onChange={(val) => {
        if (onPermissionSelection) {
          onPermissionSelection(
            permissions.map((per) => per.permission),
            val
          );
        }
      }}
      disabled={props.disabled}
    />
  );
  const servicePermissions = formatPermissions();
  return (
    <PermissiosAccordion
      header={header}
      heading={heading || 'Permissions'}
      subHeading={subHeading}
      defaultOpen
    >
      {servicePermissions.map((servicePermission) => {
        return (
          <EntityPermissions
            entity={servicePermission}
            onPermissionSelection={onPermissionSelection}
            disabled={props.disabled}
          />
        );
      })}
    </PermissiosAccordion>
  );
};

export default PermissionSelectionView;
