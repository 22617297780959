import { Table } from './Table';
import { TableHead, TableHeadItem } from './TableHead';
import { TableBody, TableBodyItem, TableBodyRow } from './TableBody';
import DataTable from './DataTable';

export * from './interfaces';

export {
  Table,
  TableHead,
  TableHeadItem,
  TableBody,
  TableBodyRow,
  TableBodyItem,
  DataTable,
};
