import React, { useState } from 'react';

export const TableHead: React.SFC = (props) => {
  return (
    <thead>
      <tr>{props.children}</tr>
    </thead>
  );
};

interface TableHeadItemProps {
  onSort: (sortAsc: boolean) => void;
  showSortIcon?: boolean;
  sortAsc?: boolean;
  actionsColumn?: boolean;
}

export const TableHeadItem: React.SFC<TableHeadItemProps> = (props) => {
  const [sortAsc, setSortAsc] = useState<boolean>(!!props.sortAsc);

  const toggleSortOrder = () => {
    setSortAsc(!sortAsc);
    if (props.onSort) {
      props.onSort(!sortAsc);
    }
  };

  const classes = [];
  if (props.actionsColumn) {
    classes.push('DataTable__item--actions');
  }

  return (
    <th
      // role="presentation"
      className={classes.join(' ')}
      onClick={toggleSortOrder}
    >
      {props.showSortIcon && (
        <i className={sortAsc ? 'icon-up-arrow' : 'icon-down-arrow'} />
      )}
      {props.children}
    </th>
  );
};
