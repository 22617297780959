import React from 'react';
import ClickLink from '../../components/click-link';
import PlainLayout from '../../layout/PlainLayout';
import { metaData } from './metaData';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';
import CrudTabularDetailsView from '../../layout/crud/CrudTabularDetailsView';
import {
  CreateActionButton,
  DeleteActionButton,
  EditActionButton,
} from '../../components/actions/action-buttons';
import { ArrayDataField, TextDataField } from '../../components/data-fields';
import './DetailsView.scss';
import {
  OrderStatusSyllables,
  Product,
  ProductDetails,
} from '../../api/models/catalog';
import ProductChart from '../dashboard/ProductsChart';
import { AvatarView } from '../../components/avatar';
import format from 'date-fns/format';
import { Payment, PaymentType } from '../../api/models';
const EditView: React.FunctionComponent = (props) => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data, loading, loaded } = useDataLoader<{ data: Payment }>(() =>
    api.crud.get({
      resource: metaData.resource,
      resourceId: params.id,
    })
  );
  return (
    <PlainLayout>
      <h5 className="ItemSummary__item">Payment Details</h5>
      {loaded && !loading && (
        <>
          <div className="col-xl-auto col-12 text-right d-xl-block d-none">
            <ClickLink link={metaData.route}>
              <i className="icon-arrow-left mr-2"></i>Back
            </ClickLink>
          </div>
          <div className="py-4">
            <div className="pt-4 pb-3 border-bottom">
              <div className="row">
                <div className="col-6 col-md-auto mb-3 ">
                  <span className="text-muted font-small">Price</span>
                  <h5 className="h6 mb-0">
                    {data.data.currency || '$'}
                    {data.data.amountPaid}
                  </h5>
                </div>
                <div className="col-6 col-md-auto mb-3 ">
                  <span className="text-muted font-small">InvoiceNumber </span>
                  <h5 className="font-medium mb-0">
                    {data.data.invoiceNumber}
                  </h5>
                </div>
                <div className="col-6 col-md-auto mb-3 ">
                  <span className="text-muted font-small">Date</span>
                  <h5 className="font-medium mb-0">
                    {' '}
                    {format(new Date(data.data.createdAt), 'dd/MMM/yyyy')}
                  </h5>
                </div>
              </div>
            </div>
            <div className="pt-4 pb-3  border-bottom">
              <h6 className="font-base mb-3 ">User Details</h6>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Name
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.createdBy?.name}
                </div>
              </div>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Email
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.createdBy?.email}
                </div>
              </div>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Phone
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.createdBy?.phone}
                </div>
              </div>
            </div>
            {data.data.paymentType === PaymentType.Subscription && (
              <div className="pt-4 pb-3  border-bottom">
                <h6 className="font-base mb-3 ">Plan Details</h6>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Name
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.subscriptionPlan?.name}
                  </div>
                </div>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    AccountType
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.subscriptionPlan?.accountType}
                  </div>
                </div>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Amount
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.subscriptionPlan?.amount}
                  </div>
                </div>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    No. of Posts
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.subscriptionPlan?.noOfPosts}
                  </div>
                </div>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Period
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.subscriptionPlan?.period}
                  </div>
                </div>
              </div>
            )}{' '}
            {data.data.paymentType === PaymentType.Purchase && (
              <div className="pt-4 pb-3  border-bottom">
                <h6 className="font-base mb-3 ">Product Details</h6>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Name
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.productDetails?.title}
                  </div>
                </div>
                <div className="row font-small">
                  <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                    Price
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                    {data.data.productDetails?.price}
                  </div>
                </div>
              </div>
            )}{' '}
            <div className="pt-4 pb-3  border-bottom">
              <h6 className="font-base mb-3 ">Payment Details</h6>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  TransactionID
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.stripePaymentDetails?.id}
                </div>
              </div>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Capture Method
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.stripePaymentDetails?.capture_method}
                </div>
              </div>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Payment Method Type
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.stripePaymentDetails?.payment_method_types[0]}
                </div>
              </div>
              <div className="row font-small">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                  Payment Method
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                  {data.data.stripePaymentDetails?.payment_method}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </PlainLayout>
  );
};

export default EditView;
